Unit JSON;

{
  The TMS implementation of JSON is undocumented, hard to understand and has
  bugs. The thing is: JSON *IS* already the native structure of javascript
  values and the existing implementations of JSValue/TJSObject/TJSArray already
  deliver everything needed to deal with JSON. So basically, there is no need
  for a special JSON class, but for Delphi programmers, it is quite hard to understand
  the interfaces of the beforementioned classes. So what follows is more or less
  a wrapper to expose the particular JSON related funtionality of these classes
  for Delphi programmers who are unfamiliar with the structure of native javascript
  objects.

  Background information:
   - The class JSValue represents a "native" javascript variable. It can be
     best compared with the Delphi data type "Variant". A variable of type
     JSValue can have any of the data types listed in TJSValueType of unit JS.
     This also includes TJSObject and TJSArray;

   - The actual data type of a JSValue can be checked using GetValueType() or
     the many "isXYZ()" functions in unit JS.

   - A JSValue can be assigned any allowed Delphi data type without any conversions
       Var J : JSValue; J := 'Hey'; J := 1.14
     To copy the content of a JSValue into a Delphi variable, simple conversions
     have to be made
       Var S : String; S := String(J)
       Var D : Double; D := Double(J)

   - The class TJSObject is an associative array of key-value pairs, where the
     values are of type JSValue. Nesting of objects is possible and this is
     what makes up the JSON analogy. Import and export from/to a JSON string
     is already natively possible. No need to write extra code for this!

   - The class TJSArray is a simple "Array of JSValue"

   - A JSValue has no constructor. TJSObject and TJSArray have the constructor
     "new" (not "Create" as normally in Delphi). As javascript offers garbage
     collection, there is no need to "Free" any of these objects.

  Simple Access that is already natively possible without any additional
  helpers or wrappers:

   Var
    JO : TJSObject;
    JA : TJSArray;
    JO2: TJSObject;
    JV : JSValue;

   JO := TJSObject.new;
   JO['PI'] := PI;
   JO['Chicken'] := 'Why did the chicken cross the road?';
   JO['Solve'] := 42;
   JO['IsNull'] := Nil;
   JO['IsOK'] := True;

   JA := TJSArray.new;
   // No need to fill the array in sequence
   JA[0] := 'Hallo';
   JA[2] := PI;
   JS[3] := JO.DeepClone; // Full copy of JO, not JO itself

   // Add array to object
   JO['List'] := JA;

   // A *copy* of the value with key "PI", not a reference!
   JV := JO['PI'];

   // Square the content of JV. This DOES NOT change the value of JO['PI']
   JV := Double(JV)*Double(JV);

   // *THIS* changes the value of JO['PI']
   JO['PI'] := JV;

   // Deep access, native
   TJSObject(TJSArray(JSO1['List'])[3])['PI'] := JV;

   // Deep access using breadcrumb walker
   JV := JO.WalkToValue(['List',3,'PI']);
}

Interface

Uses
 Types, JS, Web, SysUtils, DateUtils;

Type
 // JSON specific excpetions thrown by this unit
 EJSONKeyNotFound = Class(Exception);
 EJSONValueIsNull = Class(Exception);
 EJSONConvertError = Class(Exception);
 EJSONWalkerError = Class(Exception);

Const
 // A set of TJSValueType comprising all simple data types
 JSONSimpleDataTypes : Set of TJSValueType =
  [jvtNull,jvtBoolean,jvtInteger,jvtFloat,jvtString];

Type
 // TJSON is a helper class only!
 TJSON = Class helper for TJSObject
  Public
   Function PropertyExists(Const Key : String) : Boolean;
   { Returns True if a property with given Key exists, else False. Returns True
     also for properties whose value is null. }

   Function ValueIsNull(Const Key : String) : Boolean;
   { Returns True if a property with given Key exists and its value is null,
     else False (this also includes a non existing Key) }

   Function TypeOfValue(Const Key : String) : TJSValueType;
   { Returns the data type of the value stored in the property with given Key.
     Currently, value types are:
       jvtNull,jvtBoolean,jvtInteger,
       jvtFloat,jvtString,jvtObject,jvtArray
     When the property does not exist, jvtNull will be returnes as well }

   Procedure GetProperty(Const Key : String;
                         Var Value : JSValue;
                  Var KeyIsMissing,
                       ValueIsNull : Boolean;
                     FailIfMissing : Boolean = True;
                        FailIfNull : Boolean = False);
   { Safe read access to the property with given Key.
     Parameters:
      - Key           : The key of the property to access
      - Value         : Returns the value of the property, if it exists
      - KeyIsMissing  : Returns True if a property with given Key does not exist
                        and FailIfMissing is False.
      - ValueIsNull   : Returns True if the value of the property is null and
                        FailIfNull is False
      - FailIfMissing : If True (default) and the property does not exist, an
                        EJSONKeyNotFound exception is raised. If False and the
                        property does not exist, a property with given Key is
                        created and its value is set to null. The returned Value
                        will be Nil in this case as well.
      - FailIfNull    : If True and the value of the property is null, an
                        EJSONValueIsNull exception is raised. If False
                        (default) and the value of the property is null,
                        the returned Value is Nil. }

   Procedure SetProperty(const Key: String; Const Value: JSValue; FailIfMissing : Boolean = True);
   { Safe write access to the property with given Key.
     Parameters:
      - Key           : The key of the property to access
      - Value         : The value to write into the property
      - FailIfMissing : If True (default) and the property does not exist, an
                        EJSONKeyNotFound exception is raised. If False and the
                        property does not exist, a property with given Key is
                        created and its value is set to the given Value. }

   Function DeepClone : TJSObject;
   { DeepClone creates and returns a new TJSObject and copies all content
     of (this) source object into the new object. }

   Function ToJSONString(BeautifyTabs : Integer = 0) : String;
   { Converts the object into a JSON compatible string. If BeautifyTabs is 0
     (default), then the resulting string will be one consecutive string. If
     >0, then the resulting string will be beautified for better human
     readability by adding line breaks and indentations with the given
     number of tabs. }

   Function Count : Integer;
   { Returns the number of properties in the object }

   Function KeyList : TStringDynArray;
   { Returns an Array of String holding the keys of all properties }

   Procedure Delete(Const Key : String);
   { Deletes the property with the given Key. Does not fail if Key does not
     exist. If the property to delete is itself a nested property, all
     data of the property as well as all sub-properties get deleted! }

   Procedure Clear(Const Key : String; FailIfMissing : Boolean = True);
   { Sets the value of the property with the given Key to null. }

   { The following Getters and Setters provide safe and data type specific
     access to the properties.
      Getter-Parameters
       - Key           : See GetProperty()
       - ValueIsNull   : See GetProperty()
       - FailIfMissing : See GetProperty()
       - FailIfNull    : See GetProperty()
       - NullValue     : Type specific value that is returned if the current
                         value of the property is null and FailIfNull is False
      Setter-Parameters
       - Key           : See SetProperty()
       - Value         : See SetProperty(), but type specific
       - FailIfMissing : See SetProperty() }

   Function GetAsString(Const Key : String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; Const NullValue : String = '') : String;
   Procedure SetAsString(Const Key : String; Const Value : String; FailIfMissing : Boolean = True);

   Function GetAsInteger(Const Key : String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; Const NullValue : Integer = 0) : Integer;
   Procedure SetAsInteger(Const Key : String; Const Value : Integer; FailIfMissing : Boolean = True);

   Function GetAsFloat(Const Key : String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; Const NullValue : Double = 0) : Double;
   Procedure SetAsFloat(Const Key : String; Const Value : Double; FailIfMissing : Boolean = True);

   Function GetAsBoolean(Const Key : String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; Const NullValue : Boolean = False) : Boolean;
   Procedure SetAsBoolean(Const Key : String; Const Value : Boolean; FailIfMissing : Boolean = True);

   Function GetAsDatetime(Const Key: String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; Const NullValue: TDatetime = 0): TDatetime;
   Procedure SetAsDatetime(Const Key: String; Const Value: TDatetime; FailIfMissing: Boolean = True);
   { The JSON standard does not specify a binary format for date and time
     values. Therefore date and time values have to be stored as strings.
     Most REST applications use the string format specified in RFC3339.
     An example of an RFC3339 formatted DateTime is "2020-10-13T02:19:51.237Z".
     GetAsDatetime() and SetAsDatetime() both use and expect RFC3339 formatted
     Date ("2020-10-13"), Time ("02:19:51.237Z") and DateTime values. The
     "Z" in the time string indicates that all times are created and expected
     to be UTC (ZULU/GMT) based. }

   Function GetAsObject(Const Key: String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; EmptyObjectAsNullValue : Boolean = True): TJSObject;
   { Special Parameter:
      - EmptyObjectAsNullValue: If True (default) and the current value of the
                                property is null, an empty TJSObject will be
                                created and assigned to the property. If False,
                                Nil will be returned. }

   Procedure SetAsObject(Const Key: String; Const Value: TJSObject; FailIfMissing: Boolean = True);

   Function GetAsArray(Const Key: String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; EmptyArrayAsNullValue : Boolean = True): TJSArray;
   { Special Parameter:
      - EmptyArrayAsNullValue: If True (default) and the current value of the
                               property is null, an empty TJSArray will be
                               created and assigned to the property. If False,
                                Nil will be returned. }
   Procedure SetAsArray(Const Key: String; Const Value: TJSArray; FailIfMissing: Boolean = True);

   Function WalkToValue(Const BreadCrumbs : Array of Const): JSValue;
   { WalkToValue provides easy access to elements from deeply nested objects. The
     "address" of the element is defined by the provided BreadCrumbs. A
     BreadCrumbs array element is either a string defining the key of a property
     or an integer defining the index of an array. An index may also be given as
     a string convertible to an integer.
     Example:
       WalkToValue(['List',3,'Cars',5,'Why'])
     This represents a 5 levels deep access. The actual series of
     accesses is this:
      1. In the base object, search for an array with name "List"
      2. Within that array, access the 3rd element, which is an object
      3. In that object, search for an array with name "Cars"
      4. Within that array, access the 5th element, which is an object
      5. In that object, search for a property with key "Why". This is the
         endpoint. The endpoint property may be of any data type
         (value, object or array)
      If the access fails, an EJSONWalkerError is raised with the specifics as
      to why and at which position the acces failed. }

   Procedure AppendObject(Obj : TJSObject);

 End;

Function CreateJSONObjectFromString(Const JSONString : String) : JSValue;
(*
  This reads the provided JSON formatted data string and either creates a
  TJSObject in case the data represents a JSON object or a TJSArray in case
  the data represents a JSON array. The caller has to check the data type
  returned and type cast the result accordingly, like this:

   Var
    JV : JSValue;
    JO : TJSObject;
    JA : TJSArray;

   JV := CreateJSONObjectFromString(...);
   If isArray(JV) then JA := TJSArray(JV)
   Else if isObject(JV) then JO := TJSObject(JV);

   Be sure to do isArray(JV) first because isObject(JV) is True for arrays
   as well!

   This creates an array : '[{ "foo": "bar"},{ "Müll": "Eimer"}]'
   This creates an object: '{"List": [{ "foo": "bar"},{ "Müll": "Eimer"}], "PI": 3.14 }'
*)

{##############################################################################}

Implementation

Const
 JSONKeyNotFoundErrorMessage = 'Value with key "%s" not found';
 JSONValueIsNullErrorMessage = 'Value with key "%s" is Null';
 JSONValueNotConvertable = 'Value with key "%s" cannot be converted into "%s"';

Function TJSON.PropertyExists(const Key: String): Boolean;
Var JV : JSValue;
Begin
 Result := hasOwnProperty(Key);
 If not Result then exit;
 JV := Properties[Key];
 Result := (not isUndefined(JV)) or isNull(JV);
End;

{---------------------------------------}

Function TJSON.ValueIsNull(Const Key : String) : Boolean;
Begin
 Result := isNull(Properties[Key]);
End;

{---------------------------------------}

Function TJSON.TypeOfValue(Const Key : String) : TJSValueType;
Begin
 Result := GetValueType(Properties[Key]);
End;

{---------------------------------------}

Procedure TJSON.GetProperty(Const Key : String;
                            Var Value : JSValue;
                     Var KeyIsMissing,
                          ValueIsNull : Boolean;
                        FailIfMissing : Boolean = True;
                           FailIfNull : Boolean = False);
Var JV : JSValue;
Begin
 KeyIsMissing := False;

 JV := Properties[Key];
 If isUndefined(JV) then
  Begin
    // isUndefined also returns true if value is null,
    // but then again, it's NOT undefined...
    If not isNull(JV) then
     Begin
      // Ok, it's in fact not defined
      KeyIsMissing := True;

      // Fail if caller so wishes
      If FailIfMissing then
       Raise EJSONKeyNotFound.CreateFmt(
        JSONKeyNotFoundErrorMessage,[Key]);

      // Otherwise create the value as null
      Properties[Key] := Nil;
      JV := TJSObject(Properties[Key]);
     End;
  End;

 // Fail if value is null and caller wishes to fail on null value
 ValueIsNull := isNull(JV);
 If ValueIsNull and FailIfNull then
  Raise EJSONValueIsNull.CreateFmt(
   JSONValueIsNullErrorMessage,[Key]);

 Value := JV;
End;

{---------------------------------------}

Procedure TJSON.SetProperty(Const Key : String; Const Value : JSValue; FailIfMissing : Boolean = True);
Var JV : JSValue;
Begin
 JV := Properties[Key];
 If isUndefined(JV) and (not isNull(JV)) then
  Begin
   // Key not found
   // Fail if caller so wishes
   If FailIfMissing then
    Raise EJSONKeyNotFound.CreateFmt(
     JSONKeyNotFoundErrorMessage,[Key]);
  End;
 Properties[Key] := Value;
End;

{---------------------------------------}

Function TJSON.Count : Integer;
Begin
 Result := Length(TJSObject.keys(self));
End;

{---------------------------------------}

Function TJSON.KeyList: TStringDynArray;
Begin
 Result := TJSObject.keys(self);
End;

{---------------------------------------}

Procedure TJSON.Delete(Const Key: String);
Begin
 {$IFNDEF WIN32}
 ASM
  delete this[Key];
 END;
 {$ENDIF}
End;

{---------------------------------------}

Procedure TJSON.Clear(Const Key: String; FailIfMissing: Boolean);
Begin
 SetProperty(Key,Nil,FailIfMissing);
End;

{---------------------------------------}

Function TJSON.GetAsString(Const Key : String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; Const NullValue : String = '') : String;
Var
 JV    : JSValue;
 IsMis : Boolean;
Begin
 GetProperty(Key,JV,IsMis,ValueIsNull,FailIfMissing,FailIfNull);
 If ValueIsNull then Result := NullValue
 Else
  Try
   If not (GetValueType(JV) in JSONSimpleDataTypes) then Abort;
   Result := String(JV);
  Except
   Raise EJSONConvertError.CreateFmt(
    JSONValueNotConvertable,[Key,'String']);
  End;
End;

{---------------------------------------}

Procedure TJSON.SetAsString(Const Key : String; Const Value : String; FailIfMissing : Boolean = True);
Begin
 SetProperty(Key,Value,FailIfMissing);
End;

{---------------------------------------}


Function TJSON.GetAsInteger(Const Key : String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; Const NullValue : Integer = 0) : Integer;
Var
 JV    : JSValue;
 IsMis : Boolean;
Begin
 GetProperty(Key,JV,IsMis,ValueIsNull,FailIfMissing,FailIfNull);
 If ValueIsNull then Result := NullValue
 Else
  Try
   If not (GetValueType(JV) in JSONSimpleDataTypes) then Abort;
   Result := Integer(JV);
   If jsIsNaN(Result) then Abort;
  Except
   Raise EJSONConvertError.CreateFmt(
    JSONValueNotConvertable,[Key,'Integer']);
  End;
End;

{---------------------------------------}

Procedure TJSON.SetAsInteger(Const Key : String; Const Value : Integer; FailIfMissing : Boolean = True);
Begin
 SetProperty(Key,Value,FailIfMissing);
End;

{---------------------------------------}

Function TJSON.GetAsFloat(Const Key : String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; Const NullValue : Double = 0) : Double;
Var
 JV    : JSValue;
 IsMis : Boolean;
Begin
 GetProperty(Key,JV,IsMis,ValueIsNull,FailIfMissing,FailIfNull);
 If ValueIsNull then Result := NullValue
 Else
  Try
   If not (GetValueType(JV) in JSONSimpleDataTypes) then Abort;
   Result := Double(JV);
   If jsIsNaN(Result) then Abort;
  Except
   Raise EJSONConvertError.CreateFmt(
    JSONValueNotConvertable,[Key,'Float']);
  End;
End;

{---------------------------------------}

Procedure TJSON.SetAsFloat(Const Key : String; Const Value : Double; FailIfMissing : Boolean = True);
Begin
 SetProperty(Key,Value,FailIfMissing);
End;

{---------------------------------------}

Function TJSON.GetAsBoolean(Const Key : String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; Const NullValue : Boolean = False) : Boolean;
Var
 JV    : JSValue;
 IsMis : Boolean;
Begin
 GetProperty(Key,JV,IsMis,ValueIsNull,FailIfMissing,FailIfNull);
 If ValueIsNull then Result := NullValue
 Else
  Try
   If not (GetValueType(JV) in JSONSimpleDataTypes) then Abort;
   If isBoolean(JV) then Result := Boolean(JV)
   Else if isString(JV) then
    Begin
     If UpperCase(String(JV))='TRUE' then Result := True
     Else if UpperCase(String(JV))='FALSE' then Result := False
     Else Abort
    End
   Else if isNumber(JV) then
    Begin
     If JV=1 then Result := True
     Else if JV=0 then Result := False
     Else Abort
    End
   Else Result := Boolean(JV);
  Except
   Raise EJSONConvertError.CreateFmt(
    JSONValueNotConvertable,[Key,'Boolean']);
  End;
End;

{---------------------------------------}

Procedure TJSON.SetAsBoolean(Const Key : String; Const Value : Boolean; FailIfMissing : Boolean = True);
Begin
 SetProperty(Key,Value,FailIfMissing);
End;

{---------------------------------------}

Function TJSON.GetAsDatetime(Const Key: String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; Const NullValue: TDatetime = 0): TDatetime;
Var
 JV    : JSValue;
 IsMis : Boolean;
Begin
 GetProperty(Key,JV,IsMis,ValueIsNull,FailIfMissing,FailIfNull);
 If ValueIsNull then Result := NullValue
 Else
  Try
   If not isString(JV) then Abort;
   Result := UniversalTimeToLocal(RFC3339ToDateTime(String(JV)));
  Except
   Raise EJSONConvertError.CreateFmt(
    JSONValueNotConvertable,[Key,'DateTime']);
  End;
End;

{---------------------------------------}

Procedure TJSON.SetAsDatetime(Const Key: String; Const Value: TDatetime; FailIfMissing: Boolean = True);
Begin
 SetProperty(Key,DateTimeToRFC3339(LocalTimeToUniversal(Value)),FailIfMissing);
End;

{---------------------------------------}

Function TJSON.GetAsObject(Const Key: String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; EmptyObjectAsNullValue : Boolean = True): TJSObject;
Var
 JV    : JSValue;
 IsMis : Boolean;
Begin
 GetProperty(Key,JV,IsMis,ValueIsNull,FailIfMissing,FailIfNull);
 If ValueIsNull then
  Begin
   If EmptyObjectAsNullValue then Result := TJSObject.new
   Else Result := Nil
  End
 Else
  Try
   If not isObject(JV) then Abort;
   Result := TJSObject(JV);
  Except
   Raise EJSONConvertError.CreateFmt(
    JSONValueNotConvertable,[Key,'Object']);
  End;
End;

{---------------------------------------}

Procedure TJSON.SetAsObject(Const Key: String; Const Value: TJSObject; FailIfMissing: Boolean = True);
Begin
 SetProperty(Key,Value,FailIfMissing);
End;

{---------------------------------------}

Function TJSON.GetAsArray(Const Key: String; Var ValueIsNull : Boolean; FailIfMissing : Boolean = True; FailIfNull : Boolean = False; EmptyArrayAsNullValue : Boolean = True): TJSArray;
Var
 JV    : JSValue;
 IsMis : Boolean;
Begin
 GetProperty(Key,JV,IsMis,ValueIsNull,FailIfMissing,FailIfNull);
 If ValueIsNull then
  Begin
   If EmptyArrayAsNullValue then Result := TJSArray.new
   Else Result := Nil
  End
 Else
  Try
   If not isArray(JV) then Abort;
   Result := TJSArray(JV);
  Except
   Raise EJSONConvertError.CreateFmt(
    JSONValueNotConvertable,[Key,'Array']);
  End;
End;

{---------------------------------------}

Procedure TJSON.SetAsArray(Const Key: String; Const Value: TJSArray; FailIfMissing: Boolean = True);
Begin
 SetProperty(Key,Value,FailIfMissing);
End;

{---------------------------------------}

Function TJSON.DeepClone: TJSObject;
Begin
 Result := TJSJSON.parseObject(TJSJSON.stringify(Self.valueOf));
End;

{---------------------------------------}

Function ArgToStr(Arg : TVarRec) : String;
Begin
 With Arg do
  Case VType of
    vtBoolean:      Result := BoolToStr(VBoolean);
    vtClass:        Result := VClass.ClassName;
    vtCurrency:     Result := CurrToStr(VCurrency);
    vtExtended:     Result := FloatToStr(VExtended);
    vtInteger:      Result := IntToStr(VInteger);
    vtObject:       Result := VObject.ClassName;
    vtUnicodeString:Result := UnicodeString(VUnicodeString);
    vtWideChar:     Result := VWideChar;
    Else            Result := '<unsupported data type>';
  End;
End;

{---------------------------------------}

Function TJSON.WalkToValue(Const BreadCrumbs : Array of Const): JSValue;
Var
 JO   : TJSObject;
 JA   : TJSArray;
 JV   : JSValue;
 I,Ind: Integer;
 Path,
 Crumb: String;
Begin
 Result := Nil;
 If Length(BreadCrumbs)<1 then exit;

 Path := '\';
 JO := Self;
 JA := Nil;
 For I := 0 to High(BreadCrumbs) do
  Begin
   // Can current crumb be interpreted as string?
   Crumb := ArgToStr(BreadCrumbs[I]);
   If isUndefined(Crumb) then
    Raise EJSONWalkerError.CreateFmt(
     'Invalid crumb in BreadCrumbs at position: %d, integer or string expected.',[I]);

   If JO<>Nil then
    Begin
     // Token should be string
     If BreadCrumbs[I].VType<>vtUnicodeString then
      Raise EJSONWalkerError.CreateFmt(
       'String key expected for object at path: "%s", received: "%s"',
       [Path,Crumb]);
     JV := JO.Properties[Crumb];
     If isUndefined(JV) and (not isNull(JV)) then
      Raise EJSONWalkerError.CreateFmt(
       'Key "%s" not found in object at path: "%s"',
       [Crumb,Path]);
    End
   Else { if JA<>Nil then }
    Begin
     // Token should be integer
     Try
      Ind := StrToInt(Crumb);
     Except
      Raise EJSONWalkerError.CreateFmt(
       'Integer index expected for array at path: "%s", received: "%s"',
       [Path,Crumb]);
     End;

     // Index out of bounds?
     If (Ind<0) or (Ind>=JA.Length) then
      Raise EJSONWalkerError.CreateFmt(
       'Index out of bounds [%d..%d] for array at path: "%s", received: "%d"',
       [0,JA.Length-1,Path,Ind]);

     // Array element undefined?
     JV := JA[Ind];
     If isUndefined(JV) and (not isNull(JV)) then
      Raise EJSONWalkerError.CreateFmt(
       'Element at index "%d" is undefined in array at path: "%s"',
       [Ind,Path]);
    End;
   Path := Path + Crumb + '\';

   If I<>High(BreadCrumbs) then
    Begin
     // We expect Object or Array
     If isArray(JV) then
      Begin
       // Remember array
       JO := Nil;
       JA := TJSArray(JV)
      End
     Else if isObject(JV) then
      Begin
       // Remeber object
       JO := TJSObject(JV);
       JA := Nil;
      End
     Else if isNull(JV) then
      Raise EJSONWalkerError.CreateFmt(
       'Null element encountered at path: "%s"',
       [Path])
     Else
      Raise EJSONWalkerError.CreateFmt(
       'Object or Array expected at path: "%s"',
       [Path]);
    End
   Else
    Begin
     // We arrived at the final element. This can be any data type.
     Result := JV;
    End;
  End;
End;

{---------------------------------------}

Function TJSON.ToJSONString(BeautifyTabs : Integer = 0) : String;
Begin
 Result := TJSJSON.stringify(Self.valueOf,Nil,BeautifyTabs);
End;

{---------------------------------------}

Function CreateJSONObjectFromString(Const JSONString : String) : JSValue;
Begin
 Result := TJSJSON.parse(JSONString);
End;

{---------------------------------------}

Procedure TJSON.AppendObject(Obj: TJSObject);
Begin
 If isUndefined(Obj) or (not isObject(Obj)) then exit;
 {$IFNDEF WIN32}
 ASM
  Object.assign(this,Obj);
 END;
 {$ENDIF}
End;

{---------------------------------------}

End.
