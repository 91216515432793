Unit FormCamera;

Interface

Uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DMMain, WEBLib.StdCtrls, 
WEBLib.ExtCtrls, WEBLib.WebCtrls, Tools, WEBLib.ComCtrls,
  WEBLib.Devices;

Type
  TCameraForm = class(TForm)
    Cam: TCamera;
    DivCameraArea: THTMLDiv;
    DivCropArea: THTMLDiv;
    BtnTakePic: TButton;
    ImgCam: TImageControl;
    BtnToggleCam: TButton;
    MMPCamClick: TMultimediaPlayer;
    BtnBackToCam: TButton;
    BtnCameraPic: TButton;
    CbxSalesAreas: TComboBox;
    BtnYear1: TButton;
    BtnYear2: TButton;
    BtnYear3: TButton;
    BtnYear4: TButton;
    BtnBackToDocs: TButton;
    BtnMenu3: TButton;
    EdDocTitel: TEdit;
    procedure BtnTakePicClick(Sender: TObject);
    procedure BtnToggleCamClick(Sender: TObject);
    procedure BtnBackToCamClick(Sender: TObject);
    procedure BtnCameraPicClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure BtnYear1Click(Sender: TObject);
    procedure BtnBackToDocsClick(Sender: TObject);
    procedure BtnMenu3Click(Sender: TObject);
   Private
   Public
    FFilterYear   : Integer;
    FFilterSubID  : Integer;
    Procedure FormInit;
    Procedure Enter;
    Procedure Leave;
    Procedure SetActiveButton;
  protected procedure LoadDFMValues; override; End;

Var
 CameraForm : TCameraForm;

{#############################################################################}

Implementation

{$R *.dfm}

Uses
 DateUtils, JSFuncs, WEBLib.JSON, WEBLib.WebTools, JSON, Logger, FormMain,
 FormDocList, FormUpload, FormMenu;

Procedure TCameraForm.WebFormCreate(Sender: TObject);

 Procedure PrepareBtn(Btn : TButton; Year : Integer);
 Begin
  Btn.Caption := IntToStr(Year);
  Btn.Tag := Year;
 End;

Var Yr,Mo,Dy : Word;
Begin
 Console.log(TrIn('TCameraForm.WebFormCreate'));
 Try
  DecodeDate(Now,Yr,Mo,Dy);
  PrepareBtn(BtnYear1,Yr-3);
  PrepareBtn(BtnYear2,Yr-2);
  PrepareBtn(BtnYear3,Yr-1);
  PrepareBtn(BtnYear4,Yr);
 Finally
  Console.log(TrOut('TCameraForm.WebFormCreate'));
 End;
End;

{---------------------------------------}

Procedure TCameraForm.FormInit;
Var
 e   : Exception;
 Ind : Integer;
Begin
 Console.log(TrIn('TCameraForm.FormInit'));
 Try
  Try
   SetActiveButton;
   If FFilterSubID=-1 then Ind := 0
   Else Ind := FFilterSubID;
   If CbxSalesAreas.Items.Count>0 then
    CbxSalesAreas.ItemIndex := Ind;
   DivCropArea.Visible := False;
   DivCameraArea.Visible := False;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TCameraForm.FormInit','','','Initialisierung fehlgeschlagen'));
   Abort;
  End;
 Finally
  Console.log(TrOut('TCameraForm.FormInit'));
 End;
End;

{---------------------------------------}

Procedure TCameraForm.Enter;
Var e : Exception;
Begin
 Console.log(TrIn('TCameraForm.Enter'));
 Try
  Try
   DivCropArea.Visible := False;
   DivCameraArea.Visible := True;
   Cam.Start;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TCameraForm.Enter','','','Einschalten der Kamera fehlgeschlagen'));
  End;
 Finally
  Console.log(TrOut('TCameraForm.Enter'));
 End;
End;

{---------------------------------------}

Procedure TCameraForm.Leave;
Var e : Exception;
Begin
 Console.log(TrIn('TCameraForm.Leave'));
 Try
  Try
   Cam.Stop;
   DivCropArea.Visible := False;
   DivCameraArea.Visible := False;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TCameraForm.Leave'));
  End;
 Finally
  Console.log(TrOut('TCameraForm.Leave'));
 End;
End;

{---------------------------------------}

Procedure TCameraForm.SetActiveButton;

 Procedure SetButton(Btn : TButton);
 Begin
  If Btn.Tag=FFilterYear then
   Begin
    Btn.ElementClassName := 'ButtonYearActive';
    Console.Log(Lg('TCamerForm.SetActiveButton.SetButton',['New active button:',Btn.Tag]));
   End
  Else if Btn.ElementClassName<>'ButtonYear' then
   Btn.ElementClassName := 'ButtonYear'
 End;

Begin
 Console.log(TrIn('TCameraForm.SetActiveButton'));
 Try
  SetButton(BtnYear1);
  SetButton(BtnYear2);
  SetButton(BtnYear3);
  SetButton(BtnYear4);
 Finally
  Console.log(TrOut('TCameraForm.SetActiveButton'));
 End;
End;

{---------------------------------------}

Procedure TCameraForm.BtnYear1Click(Sender: TObject);
Var ClickedYear : Integer;
Begin
 Console.log(TrIn('TCameraForm.BtnYear1Click'));
 Try
  ClickedYear := (Sender as TButton).Tag;
  FFilterYear := ClickedYear;
  SetActiveButton;
  DoclistForm.FFilterYear := FFilterYear;
  DoclistForm.SetActiveButton;
  UploadForm.FFilterYear := FFilterYear;
  UploadForm.SetActiveButton;
 Finally
  Console.log(TrOut('TCameraForm.BtnYear1Click'));
 End;
End;

{---------------------------------------}

Procedure TCameraForm.BtnTakePicClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TCameraForm.BtnTakePicClick'));
 Try
  Try
   MMPCamClick.Play;
   ImgCam.URL := Cam.SnapShotAsBase64;
   DivCameraArea.Visible := False;
   DivCropArea.Visible := True;
   Cam.Stop;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TCameraForm.BtnTakePicClick'));
  End;
 Finally
  Console.log(TrOut('TCameraForm.BtnTakePicClick'));
 End;
End;

{---------------------------------------}

Procedure TCameraForm.BtnToggleCamClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TCameraForm.BtnToggleCamClick'));
 Try
  Try
   Cam.Stop;
   If Cam.CameraType=ctFront then
    Cam.CameraType := ctRear
   Else
    Cam.CameraType := ctFront;
   Cam.Start;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TCameraForm.BtnToggleCamClick'));
  End;
 Finally
  Console.log(TrOut('TCameraForm.BtnToggleCamClick'));
 End;
End;

{---------------------------------------}

Procedure TCameraForm.BtnCameraPicClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TCameraForm.BtnCameraPicClick'));
 Try
  Try
   // Camera pic
   DivCropArea.Visible := False;
   DivCameraArea.Visible := True;
   Cam.Start;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TCameraForm.BtnCameraPicClick','','',
    'Einschalten der Kamera fehlgeschlagen'));
  End;
 Finally
  Console.log(TrOut('TCameraForm.BtnCameraPicClick'));
 End;
End;

{---------------------------------------}

Procedure TCameraForm.BtnBackToCamClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TCameraForm.BtnBackToCamClick'));
 Try
  Try
   DivCropArea.Visible := False;
   DivCameraArea.Visible := True;
   Cam.Start;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TCameraForm.BtnBackToCamClick','','',
    'Einschalten der Kamera fehlgeschlagen'));
  End;
 Finally
  Console.log(TrOut('TCameraForm.BtnBackToCamClick'));
 End;
End;

{---------------------------------------}

Procedure TCameraForm.BtnBackToDocsClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TCameraForm.BtnBackToDocsClick'));
 Try
  Try
   Cam.Stop;
   DivCropArea.Visible := False;
   DivCameraArea.Visible := False;
   DoclistForm.CbxSalesAreas.ItemIndex := Self.CbxSalesAreas.ItemIndex;
   SwitchToForm(DoclistForm);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TCameraForm.xxx'));
  End;
 Finally
  Console.log(TrOut('TCameraForm.BtnBackToDocsClick'));
 End;
End;

{---------------------------------------}

Procedure TCameraForm.BtnMenu3Click(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TCameraForm.BtnMenu3Click'));
 Try
  Try
   MenuForm.PreviousForm := ActiveForm;
   SwitchToForm(MenuForm);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TCameraForm.BtnMenu3Click'));
  End;
 Finally
  Console.log(TrOut('TCameraForm.BtnMenu3Click'));
 End;
End;

{---------------------------------------}

procedure TCameraForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DivCameraArea := THTMLDiv.Create('CameraArea2');
  Cam := TCamera.Create('Camera2');
  DivCropArea := THTMLDiv.Create('CropArea2');
  ImgCam := TImageControl.Create('ImgCam2');
  BtnTakePic := TButton.Create('BtnTakePic2');
  BtnToggleCam := TButton.Create('BtnToggleCam2');
  MMPCamClick := TMultimediaPlayer.Create('MMPCamClick2');
  BtnBackToCam := TButton.Create('BtnBackToCam2');
  BtnCameraPic := TButton.Create('BtnCameraPic2');
  CbxSalesAreas := TComboBox.Create('CbxSalesAreas3');
  BtnYear1 := TButton.Create('BtnButtonYear31');
  BtnYear2 := TButton.Create('BtnButtonYear32');
  BtnYear3 := TButton.Create('BtnButtonYear33');
  BtnYear4 := TButton.Create('BtnButtonYear34');
  BtnBackToDocs := TButton.Create('BtnBackToDocs2');
  BtnMenu3 := TButton.Create('BtnMenu3');
  EdDocTitel := TEdit.Create('EdDocTitel');

  DivCameraArea.BeforeLoadDFMValues;
  Cam.BeforeLoadDFMValues;
  DivCropArea.BeforeLoadDFMValues;
  ImgCam.BeforeLoadDFMValues;
  BtnTakePic.BeforeLoadDFMValues;
  BtnToggleCam.BeforeLoadDFMValues;
  MMPCamClick.BeforeLoadDFMValues;
  BtnBackToCam.BeforeLoadDFMValues;
  BtnCameraPic.BeforeLoadDFMValues;
  CbxSalesAreas.BeforeLoadDFMValues;
  BtnYear1.BeforeLoadDFMValues;
  BtnYear2.BeforeLoadDFMValues;
  BtnYear3.BeforeLoadDFMValues;
  BtnYear4.BeforeLoadDFMValues;
  BtnBackToDocs.BeforeLoadDFMValues;
  BtnMenu3.BeforeLoadDFMValues;
  EdDocTitel.BeforeLoadDFMValues;
  try
    Name := 'CameraForm';
    Width := 640;
    Height := 582;
    ElementClassName := 'Foo';
    Visible := True;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    DivCameraArea.SetParentComponent(Self);
    DivCameraArea.Name := 'DivCameraArea';
    DivCameraArea.Left := 21;
    DivCameraArea.Top := 173;
    DivCameraArea.Width := 300;
    DivCameraArea.Height := 263;
    DivCameraArea.HeightPercent := 100.000000000000000000;
    DivCameraArea.WidthPercent := 100.000000000000000000;
    DivCameraArea.ChildOrder := 1;
    DivCameraArea.ElementPosition := epIgnore;
    DivCameraArea.Role := '';
    DivCameraArea.Visible := False;
    Cam.SetParentComponent(DivCameraArea);
    Cam.Name := 'Cam';
    Cam.Left := 16;
    Cam.Top := 12;
    Cam.Width := 260;
    Cam.Height := 149;
    Cam.HeightPercent := 100.000000000000000000;
    Cam.WidthPercent := 100.000000000000000000;
    DivCropArea.SetParentComponent(Self);
    DivCropArea.Name := 'DivCropArea';
    DivCropArea.Left := 327;
    DivCropArea.Top := 173;
    DivCropArea.Width := 294;
    DivCropArea.Height := 263;
    DivCropArea.HeightPercent := 100.000000000000000000;
    DivCropArea.WidthPercent := 100.000000000000000000;
    DivCropArea.ChildOrder := 1;
    DivCropArea.ElementPosition := epIgnore;
    DivCropArea.Role := '';
    DivCropArea.Visible := False;
    ImgCam.SetParentComponent(DivCropArea);
    ImgCam.Name := 'ImgCam';
    ImgCam.Left := 40;
    ImgCam.Top := 35;
    ImgCam.Width := 201;
    ImgCam.Height := 116;
    ImgCam.HeightStyle := ssAuto;
    ImgCam.WidthStyle := ssAuto;
    ImgCam.HeightPercent := 100.000000000000000000;
    ImgCam.WidthPercent := 100.000000000000000000;
    ImgCam.ElementFont := efCSS;
    ImgCam.ElementPosition := epIgnore;
    BtnTakePic.SetParentComponent(Self);
    BtnTakePic.Name := 'BtnTakePic';
    BtnTakePic.Left := 36;
    BtnTakePic.Top := 395;
    BtnTakePic.Width := 96;
    BtnTakePic.Height := 25;
    BtnTakePic.Caption := 'BtnTakePic';
    BtnTakePic.ChildOrder := 2;
    BtnTakePic.ElementFont := efCSS;
    BtnTakePic.ElementPosition := epIgnore;
    BtnTakePic.HeightStyle := ssAuto;
    BtnTakePic.HeightPercent := 100.000000000000000000;
    BtnTakePic.WidthStyle := ssAuto;
    BtnTakePic.WidthPercent := 100.000000000000000000;
    SetEvent(BtnTakePic, Self, 'OnClick', 'BtnTakePicClick');
    BtnToggleCam.SetParentComponent(Self);
    BtnToggleCam.Name := 'BtnToggleCam';
    BtnToggleCam.Left := 200;
    BtnToggleCam.Top := 395;
    BtnToggleCam.Width := 96;
    BtnToggleCam.Height := 25;
    BtnToggleCam.Caption := 'BtnToggleCam';
    BtnToggleCam.ChildOrder := 2;
    BtnToggleCam.ElementFont := efCSS;
    BtnToggleCam.ElementPosition := epIgnore;
    BtnToggleCam.HeightPercent := 100.000000000000000000;
    BtnToggleCam.WidthPercent := 100.000000000000000000;
    SetEvent(BtnToggleCam, Self, 'OnClick', 'BtnToggleCamClick');
    MMPCamClick.SetParentComponent(Self);
    MMPCamClick.Name := 'MMPCamClick';
    MMPCamClick.Left := 495;
    MMPCamClick.Top := 35;
    MMPCamClick.Width := 100;
    MMPCamClick.Height := 41;
    MMPCamClick.HeightPercent := 100.000000000000000000;
    MMPCamClick.WidthPercent := 100.000000000000000000;
    MMPCamClick.MultimediaType := mtAudio;
    MMPCamClick.PlaybackRate := 1.000000000000000000;
    MMPCamClick.URL := 'res/CamClick.wav';
    MMPCamClick.Visible := False;
    BtnBackToCam.SetParentComponent(Self);
    BtnBackToCam.Name := 'BtnBackToCam';
    BtnBackToCam.Left := 351;
    BtnBackToCam.Top := 395;
    BtnBackToCam.Width := 96;
    BtnBackToCam.Height := 25;
    BtnBackToCam.Caption := 'BtnBackToCam';
    BtnBackToCam.ChildOrder := 2;
    BtnBackToCam.ElementFont := efCSS;
    BtnBackToCam.ElementPosition := epIgnore;
    BtnBackToCam.HeightPercent := 100.000000000000000000;
    BtnBackToCam.WidthPercent := 100.000000000000000000;
    SetEvent(BtnBackToCam, Self, 'OnClick', 'BtnBackToCamClick');
    BtnCameraPic.SetParentComponent(Self);
    BtnCameraPic.Name := 'BtnCameraPic';
    BtnCameraPic.Left := 515;
    BtnCameraPic.Top := 395;
    BtnCameraPic.Width := 96;
    BtnCameraPic.Height := 25;
    BtnCameraPic.Caption := 'BtnCameraPic';
    BtnCameraPic.ChildOrder := 2;
    BtnCameraPic.ElementFont := efCSS;
    BtnCameraPic.ElementPosition := epIgnore;
    BtnCameraPic.HeightPercent := 100.000000000000000000;
    BtnCameraPic.WidthPercent := 100.000000000000000000;
    SetEvent(BtnCameraPic, Self, 'OnClick', 'BtnCameraPicClick');
    CbxSalesAreas.SetParentComponent(Self);
    CbxSalesAreas.Name := 'CbxSalesAreas';
    CbxSalesAreas.Left := 21;
    CbxSalesAreas.Top := 100;
    CbxSalesAreas.Width := 426;
    CbxSalesAreas.Height := 21;
    CbxSalesAreas.ElementPosition := epIgnore;
    CbxSalesAreas.HeightStyle := ssAuto;
    CbxSalesAreas.HeightPercent := 100.000000000000000000;
    CbxSalesAreas.WidthStyle := ssPercent;
    CbxSalesAreas.WidthPercent := 50.000000000000000000;
    CbxSalesAreas.ItemIndex := -1;
    BtnYear1.SetParentComponent(Self);
    BtnYear1.Name := 'BtnYear1';
    BtnYear1.Left := 21;
    BtnYear1.Top := 60;
    BtnYear1.Width := 96;
    BtnYear1.Height := 25;
    BtnYear1.Caption := '2018';
    BtnYear1.ChildOrder := 8;
    BtnYear1.HeightPercent := 100.000000000000000000;
    BtnYear1.WidthPercent := 100.000000000000000000;
    SetEvent(BtnYear1, Self, 'OnClick', 'BtnYear1Click');
    BtnYear2.SetParentComponent(Self);
    BtnYear2.Name := 'BtnYear2';
    BtnYear2.Left := 131;
    BtnYear2.Top := 60;
    BtnYear2.Width := 96;
    BtnYear2.Height := 25;
    BtnYear2.Caption := '2019';
    BtnYear2.ChildOrder := 1;
    BtnYear2.HeightPercent := 100.000000000000000000;
    BtnYear2.WidthPercent := 100.000000000000000000;
    SetEvent(BtnYear2, Self, 'OnClick', 'BtnYear1Click');
    BtnYear3.SetParentComponent(Self);
    BtnYear3.Name := 'BtnYear3';
    BtnYear3.Left := 239;
    BtnYear3.Top := 60;
    BtnYear3.Width := 96;
    BtnYear3.Height := 25;
    BtnYear3.Caption := '2020';
    BtnYear3.ChildOrder := 2;
    BtnYear3.HeightPercent := 100.000000000000000000;
    BtnYear3.WidthPercent := 100.000000000000000000;
    SetEvent(BtnYear3, Self, 'OnClick', 'BtnYear1Click');
    BtnYear4.SetParentComponent(Self);
    BtnYear4.Name := 'BtnYear4';
    BtnYear4.Left := 351;
    BtnYear4.Top := 60;
    BtnYear4.Width := 96;
    BtnYear4.Height := 25;
    BtnYear4.Caption := '2021';
    BtnYear4.ChildOrder := 3;
    BtnYear4.HeightPercent := 100.000000000000000000;
    BtnYear4.WidthPercent := 100.000000000000000000;
    SetEvent(BtnYear4, Self, 'OnClick', 'BtnYear1Click');
    BtnBackToDocs.SetParentComponent(Self);
    BtnBackToDocs.Name := 'BtnBackToDocs';
    BtnBackToDocs.Left := 241;
    BtnBackToDocs.Top := 15;
    BtnBackToDocs.Width := 96;
    BtnBackToDocs.Height := 25;
    BtnBackToDocs.Caption := 'BtnBackToDocs';
    BtnBackToDocs.ChildOrder := 6;
    BtnBackToDocs.ElementFont := efCSS;
    BtnBackToDocs.ElementPosition := epIgnore;
    BtnBackToDocs.HeightPercent := 100.000000000000000000;
    BtnBackToDocs.WidthPercent := 100.000000000000000000;
    SetEvent(BtnBackToDocs, Self, 'OnClick', 'BtnBackToDocsClick');
    BtnMenu3.SetParentComponent(Self);
    BtnMenu3.Name := 'BtnMenu3';
    BtnMenu3.Left := 351;
    BtnMenu3.Top := 15;
    BtnMenu3.Width := 96;
    BtnMenu3.Height := 25;
    BtnMenu3.Caption := 'BtnMenu3';
    BtnMenu3.ChildOrder := 6;
    BtnMenu3.ElementFont := efCSS;
    BtnMenu3.ElementPosition := epIgnore;
    BtnMenu3.HeightPercent := 100.000000000000000000;
    BtnMenu3.WidthPercent := 100.000000000000000000;
    SetEvent(BtnMenu3, Self, 'OnClick', 'BtnMenu3Click');
    EdDocTitel.SetParentComponent(Self);
    EdDocTitel.Name := 'EdDocTitel';
    EdDocTitel.Left := 20;
    EdDocTitel.Top := 135;
    EdDocTitel.Width := 427;
    EdDocTitel.Height := 22;
    EdDocTitel.ChildOrder := 5;
    EdDocTitel.ElementFont := efCSS;
    EdDocTitel.ElementPosition := epIgnore;
    EdDocTitel.HeightPercent := 100.000000000000000000;
    EdDocTitel.WidthPercent := 100.000000000000000000;
  finally
    DivCameraArea.AfterLoadDFMValues;
    Cam.AfterLoadDFMValues;
    DivCropArea.AfterLoadDFMValues;
    ImgCam.AfterLoadDFMValues;
    BtnTakePic.AfterLoadDFMValues;
    BtnToggleCam.AfterLoadDFMValues;
    MMPCamClick.AfterLoadDFMValues;
    BtnBackToCam.AfterLoadDFMValues;
    BtnCameraPic.AfterLoadDFMValues;
    CbxSalesAreas.AfterLoadDFMValues;
    BtnYear1.AfterLoadDFMValues;
    BtnYear2.AfterLoadDFMValues;
    BtnYear3.AfterLoadDFMValues;
    BtnYear4.AfterLoadDFMValues;
    BtnBackToDocs.AfterLoadDFMValues;
    BtnMenu3.AfterLoadDFMValues;
    EdDocTitel.AfterLoadDFMValues;
  end;
end;

End.
