Unit Web.FileUpload;

Interface

Uses
  SysUtils, Classes, JS, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.WebCtrls, WEBLib.StdCtrls,
  WEBLib.ComCtrls, Web;


Type
  TFileUpload = Class;
  TFileUploadCompleteEvent = Procedure(Sender: TFileUpload; Failed, Aborted, TimedOut : Boolean; ResponseStatus : Integer; Const ResponseText : String) of object;
  TFileUploadProgressEvent = Procedure(Sender: TFileUpload; Uploaded, TotalSize: NativeInt) of object;
  TFileUpload = Class
   Private
    FReq        : TJSXMLHttpRequest;
    FOnComplete : TFileUploadCompleteEvent;
    FOnProgress : TFileUploadProgressEvent;
    FParams     : TStringList;
    FAborted,
    FFailed,
    FTimedOut   : Boolean;
    FFile       : TFile;
    FFileObj    : TJSHTMLFile;
    FURL        : String;
    FRespState  : Integer;
    FRespMsg    : String;
    FErrStatus  : Integer;
    Function HandleAbort(Event : TJSEvent) : Boolean;
    Function HandleLoad(Event : TJSEvent) : Boolean;
    Function HandleLoadEnd(Event : TJSEvent) : Boolean;
    Function HandleTimeout(Event : TJSEvent) : Boolean;
    Function HandleError(Event : TJSEvent) : Boolean;
    Function HandleProgress(Event : TJSEvent) : Boolean;
    Function GetParam(Key: String): String;
    Procedure SetParam(Key: String; const Value: String);
    Procedure SetFileToUpload(const Value: TFile);
    procedure SetFileObject(const Value: TJSHTMLFile);
   Public
    Constructor Create;
    Property OnComplete : TFileUploadCompleteEvent read FOnComplete write FOnComplete;
    Property OnProgress : TFileUploadProgressEvent read FOnProgress write FOnProgress;
    Property HTTPRequest: TJSXMLHttpRequest        read FReq;
    Property Params[Key : String] : String read GetParam write SetParam;
    Property AFile : TFile read FFile write SetFileToUpload;
    Property FileObject : TJSHTMLFile read FFileObj write SetFileObject;
    Property ErrState : Integer read FErrStatus;
    Property URL : String read FURL write FURL;
    Procedure Upload;
  End;

{###########################################################################}

Implementation

{ TWebFileUpload }

Constructor TFileUpload.Create;
Begin
 FParams := TStringList.Create;
End;

{----------------------------------------}

Function TFileUpload.HandleAbort(Event: TJSEvent) : Boolean;
Begin
 Result := True;
 FAborted := True;
End;

{----------------------------------------}

Function TFileUpload.HandleLoad(Event: TJSEvent) : Boolean;
Begin
 Result := True;
 FRespState := FReq.Status;
 FRespMsg   := FReq.responseText;
End;

{----------------------------------------}

Function TFileUpload.HandleLoadEnd(Event: TJSEvent) : Boolean;
Begin
 Result := True;
 If assigned(FOnComplete) then
  FOnComplete(Self,FFailed,FAborted,FTimedOut,FRespState,FRespMsg);
End;

{----------------------------------------}

Function TFileUpload.HandleError(Event: TJSEvent) : Boolean;
Var AStatus : Integer;
Begin
 Result := True;
 FFailed := True;
 {$IFNDEF WIN32}
 ASM
  AStatus = Event.target.status;
 END;
 {$ENDIF}
 FErrStatus := AStatus;
End;

{----------------------------------------}

Function TFileUpload.HandleProgress(Event: TJSEvent) : Boolean;
Var
 Computable : Boolean;
 Uploaded,
 Total      : LongInt;
Begin
 Result := True;
 Computable := False;
 {$IFNDEF WIN32}
 ASM
  {if (Event.lengthComputable) {
     Computable = true;
     Uploaded = Event.loaded;
     Total = Event.total;
  }}
 END;
 {$ENDIF}

 If Computable and (Total > 0) and Assigned(FOnProgress) then
  FOnProgress(Self,Uploaded,Total);
End;

{----------------------------------------}

Function TFileUpload.HandleTimeout(Event: TJSEvent) : Boolean;
Begin
 Result := True;
 FTimedOut := True;
End;

{----------------------------------------}

Function TFileUpload.GetParam(Key: String): String;
Begin
 Result := FParams.Values[Key];
End;

{----------------------------------------}

Procedure TFileUpload.SetFileToUpload(const Value: TFile);
Begin
 FFile := Value;
 FFileObj := Nil;
End;

{----------------------------------------}

Procedure TFileUpload.SetFileObject(const Value: TJSHTMLFile);
Begin
 FFileObj := Value;
 FFile := Nil;
End;

{----------------------------------------}

Procedure TFileUpload.SetParam(Key: String; const Value: String);
Begin
 FParams.AddPair(Key,Value);
End;

{----------------------------------------}

{$IFDEF WIN32}
Type
 TJSFormData = Class
  Constructor new;
  Procedure append(const aName : String; aBlob : TJSBlob);
 End;

Constructor TJSFormData.new; begin end;
Procedure TJSFormData.append(const aName : String; aBlob : TJSBlob); begin end;
{$ENDIF}

Procedure TFileUpload.Upload;
Var
 FD       : TJSFormData;
 Action,
 Key,
 Value,
 Pars     : String;
 I        : Integer;
Begin
 Assert(Assigned(FFile),'No file to upload assigned');
 Assert(FURL<>'','No URL to upload to assigned');

 FD := TJSFormData.new;
 If assigned(FFile) then
  FD.Append('C9E3FEBFFD8D4269BAC03E8E6E479599',FFile.FileObject)
 Else if assigned(FFileObj) then
  FD.Append('C9E3FEBFFD8D4269BAC03E8E6E479599',FFileObj);

 Pars := '';
 For I := 0 to FParams.Count-1 do
  Begin
   Key := FParams.Names[I];
   Value := FParams.Values[Key];
   If Pars<>'' then Pars := Pars + '&';
   Pars := Pars + encodeURIComponent(Key) + '=' + encodeURIComponent(Value);
  End;
 Action := FURL;
 If Pars<>'' then Action := Action + '?' + Pars;

 FReq := TJSXMLHttpRequest.new;
 FReq.addEventListener('load', @HandleLoad);
 FReq.addEventListener('loadend', @HandleLoadEnd);
 FReq.addEventListener('error', @HandleError);
 FReq.addEventListener('abort', @HandleAbort);
 FReq.addEventListener('timeout', @HandleTimeout);
 FReq.upload.addEventListener('progress',@HandleProgress);

 FReq.Open('POST',Action,true);
 FReq.Send(FD);
End;

{----------------------------------------}

End.
