Unit FormMenu;

Interface

Uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DMMain, WEBLib.StdCtrls, 
WEBLib.ExtCtrls, WEBLib.WebCtrls, Tools;

Type
  TMenuForm = class(TForm)
    BtnMenuReset: TButton;
    BtnMenuToggleLogging: TButton;
    BtnBackFromMenu: TButton;
    BtnMenuCheckForUpdate: TButton;
    procedure BtnMenuResetClick(Sender: TObject);
    procedure BtnBackFromMenuClick(Sender: TObject);
    procedure BtnMenuToggleLoggingClick(Sender: TObject);
    procedure BtnMenuCheckForUpdateClick(Sender: TObject);
    Procedure OnGetVersionNumberResponse(JV : JSValue = Nil);
   Private
   Public
    PreviousForm : TForm;
  protected procedure LoadDFMValues; override; End;

Var
 MenuForm : TMenuForm;

{#############################################################################}

Implementation

{$R *.dfm}

Uses DateUtils, JSFuncs, WEBLib.JSON, JSON, Logger, FormMain, FormDoclist;

Procedure TMenuForm.BtnMenuResetClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TMenuForm.BtnMenuResetClick'));
 Try
  Try
   MainForm.ResetApp;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMenuForm.BtnMenuResetClick'));
  End;
 Finally
  Console.log(TrOut('TMenuForm.BtnMenuResetClick'));
 End;
End;

{---------------------------------------}

Procedure TMenuForm.BtnMenuToggleLoggingClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TMenuForm.BtnMenuToggleLoggingClick'));
 Try
  Try
   DBLoggingOn := not DBLoggingOn;
   DM.TimerDBLog.Enabled := DBLoggingOn;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMenuForm.BtnMenuToggleLoggingClick'));
  End;
 Finally
  Console.log(TrOut('TMenuForm.BtnMenuToggleLoggingClick'));
 End;
End;

{---------------------------------------}

Procedure TMenuForm.BtnBackFromMenuClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TMenuForm.BtnBackFromMenuClick'));
 Try
  Try
   SwitchToForm(PreviousForm);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMenuForm.BtnBackFromMenuClick'));
  End;
 Finally
  Console.log(TrOut('TMenuForm.BtnBackFromMenuClick'));
 End;
End;

{---------------------------------------}

Procedure TMenuForm.BtnMenuCheckForUpdateClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TMenuForm.BtnMenuCheckForUpdateClick'));
 Try
  Try
   DM.RESTGetClientVersionNumber(@OnGetVersionNumberResponse);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMenuForm.BtnMenuCheckForUpdateClick'));
  End;
 Finally
  Console.log(TrOut('TMenuForm.BtnMenuCheckForUpdateClick'));
 End;
End;

{---------------------------------------}

Procedure TMenuForm.OnGetVersionNumberResponse(JV : JSValue);
Var
 e : Exception;
 VersionNr : Integer;
Begin
 Console.log(TrIn('TMenuForm.OnGetVersionNumberResponse'));
 Try
  Try
   VersionNr := Integer(JV);
   If VersionNr<>StrToInt(CurrentProgramVersion) then
    Begin
     Console.Log(Lg('TMenuForm.OnGetVersionNumberResponse',
      ['Client version mismatch, local : ',
      CurrentProgramVersion,', Server: ',VersionNr]));

     MessageDlgEx(AppCaption,
      'Es liegt ein Update vor! Jetzt installieren?',
      mtConfirmation,['Ja','Später'],
       Procedure(AValue: TModalResult)
        Begin
         If AValue=0 then
          DM.UpdateApp;
        End).Show;
    End
   Else
    MessageDlgEx(AppCaption,
     'Es liegt kein Update vor!',mtInformation).Show;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMenuForm.OnGetVersionNumberResponse'));
  End;
 Finally
  Console.log(TrOut('TMenuForm.OnGetVersionNumberResponse'));
 End;
End;

{---------------------------------------}

procedure TMenuForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BtnMenuReset := TButton.Create('BtnMenuReset');
  BtnMenuToggleLogging := TButton.Create('BtnMenuToggleLogging');
  BtnBackFromMenu := TButton.Create('BtnBackFromMenu');
  BtnMenuCheckForUpdate := TButton.Create('BtnMenuCheckForUpdate');

  BtnMenuReset.BeforeLoadDFMValues;
  BtnMenuToggleLogging.BeforeLoadDFMValues;
  BtnBackFromMenu.BeforeLoadDFMValues;
  BtnMenuCheckForUpdate.BeforeLoadDFMValues;
  try
    Name := 'MenuForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'Foo';
    Visible := True;
    BtnMenuReset.SetParentComponent(Self);
    BtnMenuReset.Name := 'BtnMenuReset';
    BtnMenuReset.Left := 16;
    BtnMenuReset.Top := 25;
    BtnMenuReset.Width := 130;
    BtnMenuReset.Height := 25;
    BtnMenuReset.Caption := 'BtnMenuReset';
    BtnMenuReset.ChildOrder := 2;
    BtnMenuReset.ElementFont := efCSS;
    BtnMenuReset.ElementPosition := epIgnore;
    BtnMenuReset.HeightPercent := 100.000000000000000000;
    BtnMenuReset.WidthPercent := 100.000000000000000000;
    SetEvent(BtnMenuReset, Self, 'OnClick', 'BtnMenuResetClick');
    BtnMenuToggleLogging.SetParentComponent(Self);
    BtnMenuToggleLogging.Name := 'BtnMenuToggleLogging';
    BtnMenuToggleLogging.Left := 16;
    BtnMenuToggleLogging.Top := 56;
    BtnMenuToggleLogging.Width := 130;
    BtnMenuToggleLogging.Height := 25;
    BtnMenuToggleLogging.Caption := 'BtnMenuToggleLogging';
    BtnMenuToggleLogging.ChildOrder := 2;
    BtnMenuToggleLogging.ElementFont := efCSS;
    BtnMenuToggleLogging.ElementPosition := epIgnore;
    BtnMenuToggleLogging.HeightPercent := 100.000000000000000000;
    BtnMenuToggleLogging.Visible := False;
    BtnMenuToggleLogging.WidthPercent := 100.000000000000000000;
    SetEvent(BtnMenuToggleLogging, Self, 'OnClick', 'BtnMenuToggleLoggingClick');
    BtnBackFromMenu.SetParentComponent(Self);
    BtnBackFromMenu.Name := 'BtnBackFromMenu';
    BtnBackFromMenu.Left := 16;
    BtnBackFromMenu.Top := 167;
    BtnBackFromMenu.Width := 130;
    BtnBackFromMenu.Height := 25;
    BtnBackFromMenu.Caption := 'BtnBackFromMenu';
    BtnBackFromMenu.ChildOrder := 2;
    BtnBackFromMenu.ElementFont := efCSS;
    BtnBackFromMenu.ElementPosition := epIgnore;
    BtnBackFromMenu.HeightPercent := 100.000000000000000000;
    BtnBackFromMenu.WidthPercent := 100.000000000000000000;
    SetEvent(BtnBackFromMenu, Self, 'OnClick', 'BtnBackFromMenuClick');
    BtnMenuCheckForUpdate.SetParentComponent(Self);
    BtnMenuCheckForUpdate.Name := 'BtnMenuCheckForUpdate';
    BtnMenuCheckForUpdate.Left := 16;
    BtnMenuCheckForUpdate.Top := 87;
    BtnMenuCheckForUpdate.Width := 130;
    BtnMenuCheckForUpdate.Height := 25;
    BtnMenuCheckForUpdate.Caption := 'BtnMenuCheckForUpdate';
    BtnMenuCheckForUpdate.ChildOrder := 2;
    BtnMenuCheckForUpdate.ElementFont := efCSS;
    BtnMenuCheckForUpdate.ElementPosition := epIgnore;
    BtnMenuCheckForUpdate.HeightPercent := 100.000000000000000000;
    BtnMenuCheckForUpdate.WidthPercent := 100.000000000000000000;
    SetEvent(BtnMenuCheckForUpdate, Self, 'OnClick', 'BtnMenuCheckForUpdateClick');
  finally
    BtnMenuReset.AfterLoadDFMValues;
    BtnMenuToggleLogging.AfterLoadDFMValues;
    BtnBackFromMenu.AfterLoadDFMValues;
    BtnMenuCheckForUpdate.AfterLoadDFMValues;
  end;
end;

End.
