Unit Tools;

Interface

Uses
 JSDelphiSystem, Classes, Web, JS, SysUtils, WEBLib.JSON, WebLib.Forms, WebLib.Dialogs;

Const
 OneMinute      : Double = 1/(24*60);
 OneSecond      : Double = 1/(24*60*60);
 OneMillisecond : Double = 1/(24*60*60*1000);

Function MessageDlgEx(Const Caption : String;
                     Const &Message : String;
                         DialogType : TMSgDlgType;
                     ButtonCaptions : Array of String = [];
                            OnClick : TDialogResultProc = Nil) : TMessageDlg;
{ Creates an instance of TWebMessageDlg and initializes with given parameters.
  In particular, buttons are created based on the captions provided in
  ButtonCaptions. The function returns immediately with the created dialog.
  The dialog object can then be further configured and, when ready, made
  visible calling .Show, which is async and returns immediately. Once a
  button is clicked, OnClick() will be called with the parameter representing
  the index of the clicked button. If e.g. ButtonCaptions defines 4 buttons,
  then the parameter of OnClick() can be in the range 0..3.
  Example:
    MessageDlgEx('The Caption','Why did the chicken...',
     mtInformation,[Yes','No','Must Rethink'],@Self.OnMsgClick).Show;
  This creates the dialog and immediately shows it without any further
  configuration.}

Type
 TThenProc = Reference to Procedure (JV : JSValue = Nil);

Function ConstArrayArgToStr(Arg : TVarRec) : String;
{ Konvertiert ein einzelnes Argument einer Parameterliste vom Typ "Array of Const"
  in einen String, soweit sinnvoll. }

Function ConstArrayToSepStr(SepChars : String; Const Args: Array of Const): String;
{ Fügt die übergebenen Konstanten in ihrer jeweiligen typabhängigen String-
  repräsentanz, getrennt durch das übergebene Trennzeichen, zu einem Gesamt-
  string zusammen.
   Beispiel : ConstArrayToSepStr(';',['test', 100, ' ', True, 3.14159, TForm]);
   Ergibt   : 'test;100; ;T;3.14159;TForm' }

Type EncDecThenProc = Reference to Procedure(Const Result : String; Failed : Boolean);
Procedure Decrypt(Const Key, Encrypted : String; AndThen : EncDecThenProc);
Procedure Encrypt(Const Key, Decrypted : String; AndThen : EncDecThenProc);
{ Async encrypt and decrypt. After enc/dec is completed, the function in AndThen
  will be called with the Result and an error flag Failed. If Failed is True,
  then Result holds the error message. A call to Decrypt/Encrypt always returns
  immediately. }

Function GetPreventExtJSObjectPropertyDescriptor : String;
{ Gets the prevention for the ExtJS Object property descriptor...
  Nah, it's just an obfuscated access to a secret encryption key! }

Function TrimStringMaxLen(Const S : String; MaxLen : Integer) : String;

Var GlobEnc : String;

{##############################################################################}

Implementation

Uses DateUtils, JSFuncs, CP.Func.AES, Bcl.Utils;

{---------------------------------------}

Function ConstArrayArgToStr(Arg : TVarRec) : String;

{ Konvertiert ein einzelnes Argument einer Parameterliste vom Typ "Array of Const"
  in einen String, soweit sinnvoll. }
Begin
 With Arg do
  Case VType of
    vtBoolean:      Result := BoolToStr(VBoolean);
    vtClass:        Result := VClass.ClassName;
    vtCurrency:     Result := CurrToStr(VCurrency);
    vtExtended:     Result := FloatToStr(VExtended);
    vtInteger:      Result := IntToStr(VInteger);
    vtObject:       Result := VObject.ClassName;
    vtUnicodeString:Result := UnicodeString(VUnicodeString);
    vtWideChar:     Result := VWideChar;
    Else            Result := '<unsupported data type>';
  End;
End;

{---------------------------------------}

Function ConstArrayToSepStr(SepChars : String; Const Args: Array of Const): String;

{ Fügt die übergebenen Konstanten in ihrer jeweiligen typabhängigen String-
  repräsentanz, getrennt durch das übergebene Trennzeichen, zu einem Gesamt-
  string zusammen.
   Beispiel : ConstArrayToSepStr(';',['test', 100, ' ', True, 3.14159, TForm]);
   Ergibt   : 'test;100; ;T;3.14159;TForm' }

Var
 I     : Integer;
 SS    : String;
Begin
 Result := '';
 If Length(Args)<1 then exit;
 SS := '';
 For I := 0 to High(Args) do
  Begin
   If I<>0 then SS := SS + SepChars;
   SS := SS + ConstArrayArgToStr(Args[I]);
  End;
 Result := SS;
End;


{---------------------------------------}

Procedure Decrypt(Const Key, Encrypted : String; AndThen : EncDecThenProc);
Begin
 If (not assigned(Key)) or (not assigned(Encrypted)) or
    (Key='') or (Encrypted='') then AndThen('Arg missing',True)
 Else
  Begin
   {$IfDef USECRYPTO}
   TAESFunc.Decrypt(Key, TBclUtils.DecodeBase64Url(Encrypted),
    Procedure(const AEncrypted: string)
     Begin
      AndThen(AEncrypted,False);
     End,
    Procedure(Const AError: string)
     Begin
      AndThen(AError,True);
     End);
   {$Else}
    AndThen(Encrypted,False);
   {$EndIf}
  End;
End;

{---------------------------------------}

Procedure Encrypt(Const Key, Decrypted : String; AndThen : EncDecThenProc);
Begin
 If (not assigned(Key)) or (not assigned(Decrypted)) or
    (Key='') or (Decrypted='') then AndThen('Arg missing',True)
 Else
  Begin
   {$IfDef USECRYPTO}
    TAESFunc.Encrypt(Key, Decrypted,
     Procedure(const ABytes: TBytes)
      Begin
       AndThen(TBclUtils.EncodeBase64Url(ABytes),False);
      End,
     Procedure(Const AError: string)
      Begin
       AndThen(AError,True);
      End);
   {$Else}
    AndThen(Decrypted,False);
   {$EndIf}
  End;
 End;

{---------------------------------------}

Function GetPreventExtJSObjectPropertyDescriptor : String;
Begin
 Result := 'isExtensible(const obj: TJSObject): boolean;'
End;

{---------------------------------------}

{ TExtendedWebMessageDlg }

Type
 TExtendedWebMessageDlg = Class(TMessageDlg)
  Public
   FOnClick : TDialogResultProc;
   Procedure OnCloseDummy(Sender : TObject);
 End;

{----------------}

Procedure TExtendedWebMessageDlg.OnCloseDummy(Sender: TObject);
Var Dlg : TExtendedWebMessageDlg;
Begin
 If not (Sender is TExtendedWebMessageDlg) then exit;
 Dlg := TExtendedWebMessageDlg(Sender);
 If assigned(Dlg.FOnClick) then
  Dlg.FOnClick(Dlg.DialogResult-100);
End;

{----------------}

Function MessageDlgEx(Const Caption : String;
                     Const &Message : String;
                         DialogType : TMSgDlgType;
                     ButtonCaptions : Array of String;
                            OnClick : TDialogResultProc) : TMessageDlg;
Var I : Integer;
Begin
 Result := TExtendedWebMessageDlg.Create(Application.MainForm);
 Result.CreateInitialize;
 Result.Message := &Message;
 Result.DialogType := DialogType;
 If Caption<>'' then
  Result.DialogText[Ord(DialogType)] := Caption;
 Result.Opacity := 0.2;
 Result.CustomButtons.Clear;
 If Length(ButtonCaptions)>0 then
  For I := 0 to Length(ButtonCaptions)-1 do
   Result.CustomButtons.Add.Caption := ButtonCaptions[I]
 Else
  Result.CustomButtons.Add.Caption := 'OK';
 Result.OnClose := TExtendedWebMessageDlg(Result).OnCloseDummy;
 Result.ElementButtonClassName := 'ConfirmDlgButton';
 Result.ElementDialogClassName := 'ConfirmDlgDialog';
 Result.ElementTitleClassName := 'ConfirmDlgTitle';
 Result.ElementContentClassName := 'ConfirmDlgContent';
 TExtendedWebMessageDlg(Result).FOnClick := OnClick;
End;

{---------------------------------------}

Function TrimStringMaxLen(Const S : String; MaxLen : Integer) : String;
Var L,L1,L2 : Integer;
Begin
 If MaxLen<10 then raise Exception.Create('MaxLen must be >= 10');
 Result := Trim(S);
 L := Length(Result);
 If L>(MaxLen-7) then
  Begin
    L1 := (MaxLen-7) div 2;
    L2 := (MaxLen-7) - L1;
    Result := Copy(Result,1,L1) + ' [...] ' + Copy(Result,L-L1,L2);
  End;
End;

{---------------------------------------}

End.


