Unit FormShowPDF;

Interface

Uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DMMain, WEBLib.StdCtrls, 
WEBLib.ExtCtrls, WEBLib.WebCtrls, Tools;

Type
  TShowPDFForm = class(TForm)
    BtnBackFromShowPDF: TButton;
    Browser: TBrowserControl;
    procedure BtnBackFromShowPDFClick(Sender: TObject);
   Private
    FPreviousForm : TForm;
   Public
    Procedure ShowPDF(Const FileName : String);
  protected procedure LoadDFMValues; override; End;

Var
 ShowPDFForm : TShowPDFForm;

{#############################################################################}

Implementation

{$R *.dfm}

Uses DateUtils, JSFuncs, WEBLib.JSON, JSON, Logger, FormMain;

Procedure TShowPDFForm.BtnBackFromShowPDFClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TShowPDFForm.BtnBackFromShowPDFClick'));
 Try
  Try
   // DivPDF.HTML.Text := ' ';
   SwitchToForm(FPreviousForm);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TShowPDFForm.BtnBackFromShowPDFClick'));
  End;
 Finally
  Console.log(TrOut('TShowPDFForm.BtnBackFromShowPDFClick'));
 End;
End;

{---------------------------------------}

// Const
//  HTMLPdfEmbed = '<embed src="@URL" width="@WIDTH" height="@HEIGHT" type="application/pdf">';

Procedure TShowPDFForm.ShowPDF(const FileName: String);
Var
 e : Exception;
 URL   : String;
 VW,VH : Integer;
Begin
 Console.log(TrIn('TShowPDFForm.ShowPDF'));
 Try
  Try
   URL := REST_API_PDF_OUTPUT_PATH + encodeURIComponent(FileName);
   Console.log(Lg('TShowPDFForm.ShowPDF',['File-URL:',URL]));
   If Application.IsMobile then
    Begin
     Console.log(Lg('TShowPDFForm.ShowPDF',['Mobile platform detected, using navigate']));
     Application.Navigate(URL);
     Console.log(Lg('TShowPDFForm.ShowPDF',['After navigate']));
    End
   Else
    Begin
     FPreviousForm := ActiveForm;
     SwitchToForm(Self);
     {$IFNDEF WIN32}
     ASM
      VW = window.innerWidth;
      VH = window.innerHeight;
     END;
     {$ENDIF}
     VH := VH - 150;
     VW := VW - 20;

     Browser.ElementHandle.style.setProperty('margin-left','10px');
     Browser.ElementHandle.style.setProperty('margin-bottom','10px');
     Browser.ElementHandle.style.setProperty('float','left');
     Browser.ElementHandle.style.setProperty('width',IntToStr(VW)+'px');
     Browser.ElementHandle.style.setProperty('height',IntToStr(VH)+'px');
     Browser.Navigate(URL);

     {
     URL := REST_API_PDF_OUTPUT_PATH+'12345.pdf';
     HS := StringReplace(HTMLPdfEmbed,'@URL',URL,[rfReplaceAll]);
     HS := StringReplace(HS,'@WIDTH',IntToStr(VW),[rfReplaceAll]);
     HS := StringReplace(HS,'@HEIGHT',IntToStr(VH),[rfReplaceAll]);
     DivPDF.HTML.Text := HS;
     }
    End;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TShowPDFForm.ShowPDF','','',
    'Das Dokument konnte nicht angezeigt werden. Ursache: @RMSG'));
  End;
 Finally
  Console.log(TrOut('TShowPDFForm.ShowPDF'));
 End;
End;

{---------------------------------------}


procedure TShowPDFForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BtnBackFromShowPDF := TButton.Create('BtnBackFromShowPDF');
  Browser := TBrowserControl.Create('DivPDF');

  BtnBackFromShowPDF.BeforeLoadDFMValues;
  Browser.BeforeLoadDFMValues;
  try
    Name := 'ShowPDFForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'Foo';
    Visible := True;
    BtnBackFromShowPDF.SetParentComponent(Self);
    BtnBackFromShowPDF.Name := 'BtnBackFromShowPDF';
    BtnBackFromShowPDF.Left := 256;
    BtnBackFromShowPDF.Top := 411;
    BtnBackFromShowPDF.Width := 130;
    BtnBackFromShowPDF.Height := 25;
    BtnBackFromShowPDF.Caption := 'BtnBackFromShowPDF';
    BtnBackFromShowPDF.ChildOrder := 2;
    BtnBackFromShowPDF.ElementFont := efCSS;
    BtnBackFromShowPDF.ElementPosition := epIgnore;
    BtnBackFromShowPDF.HeightPercent := 100.000000000000000000;
    BtnBackFromShowPDF.WidthPercent := 100.000000000000000000;
    SetEvent(BtnBackFromShowPDF, Self, 'OnClick', 'BtnBackFromShowPDFClick');
    Browser.SetParentComponent(Self);
    Browser.Name := 'Browser';
    Browser.Left := 90;
    Browser.Top := 20;
    Browser.Width := 480;
    Browser.Height := 360;
    Browser.HeightPercent := 100.000000000000000000;
    Browser.WidthPercent := 100.000000000000000000;
    Browser.ChildOrder := 1;
    Browser.Sandbox := [];
  finally
    BtnBackFromShowPDF.AfterLoadDFMValues;
    Browser.AfterLoadDFMValues;
  end;
end;

End.
