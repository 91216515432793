Unit FormImprint;

Interface

Uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DMMain, WEBLib.StdCtrls, 
WEBLib.ExtCtrls, WEBLib.WebCtrls, Tools;

Type
  TImprintForm = class(TForm)
    BtnBackFromImprint: TButton;
    EmailJC: THTMLAnchor;
    EmailService: THTMLAnchor;
    BtnBackFromImprint2: TButton;
    procedure BtnBackFromImprintClick(Sender: TObject);
    procedure EmailJCClick(Sender: TObject);
    procedure EmailServiceClick(Sender: TObject);
   Private
   Public
    PreviousForm : TForm;
  protected procedure LoadDFMValues; override; End;

Var
 ImprintForm : TImprintForm;

{#############################################################################}

Implementation

{$R *.dfm}

Uses DateUtils, JSFuncs, WEBLib.JSON, JSON, Logger, FormMain;

Procedure TImprintForm.BtnBackFromImprintClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TImprintForm.BtnBackFromImprintClick'));
 Try
  Try
   SwitchToForm(PreviousForm);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TImprintForm.BtnBackFromImprintClick'));
  End;
 Finally
  Console.log(TrOut('TImprintForm.BtnBackFromImprintClick'));
 End;
End;

{---------------------------------------}

Procedure TImprintForm.EmailJCClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TImprintForm.EmailJCClick'));
 Try
  Try
   {$IfDef USECRYPTO}
    Decrypt(
     GetPreventExtJSObjectPropertyDescriptor,
     // mailto:joerg.colloseus@deubner-verlag.de
     'R0pLkfnVBdziGZWh0mR77nmljW2x_Z2PQFRRqBEz3TIWejzicGj0OOmh6QBNaMkSU-zhCA7exseXXIMDLnTDkw',
     Procedure (Const Result : String; Failed : Boolean)
     Begin
      If not Failed then
       Application.Navigate(Result);
     End);
   {$Else}
    Application.Navigate('mailto:joerg.colloseus@deubner-verlag.de');
   {$EndIf}
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TImprintForm.EmailJCClick'));
  End;
 Finally
  Console.log(TrOut('TImprintForm.EmailJCClick'));
 End;
End;

{---------------------------------------}

Procedure TImprintForm.EmailServiceClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TImprintForm.EmailServiceClick'));
 Try
  Try
   {$IfDef USECRYPTO}
    Decrypt(
     GetPreventExtJSObjectPropertyDescriptor,
     // mailto:kundenservice@deubner-verlag.de
     'O3kfR0MZOwz7asWy2C5cSOZ_05ANVIwdg1tTYVt2nPtgPdaiGR7Fsmab-fgcaymiI0saLV6CHBxHORmaZqyZhA',
     Procedure (Const Result : String; Failed : Boolean)
     Begin
      If not Failed then
       Application.Navigate(Result);
     End);
   {$Else}
    Application.Navigate('mailto:kundenservice@deubner-verlag.de');
   {$EndIf}
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TImprintForm.EmailServiceClick'));
  End;
 Finally
  Console.log(TrOut('TImprintForm.EmailServiceClick'));
 End;
End;

{---------------------------------------}

procedure TImprintForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BtnBackFromImprint := TButton.Create('BtnBackFromImprint1');
  EmailJC := THTMLAnchor.Create('EmailJC');
  EmailService := THTMLAnchor.Create('EmailService');
  BtnBackFromImprint2 := TButton.Create('BtnBackFromImprint2');

  BtnBackFromImprint.BeforeLoadDFMValues;
  EmailJC.BeforeLoadDFMValues;
  EmailService.BeforeLoadDFMValues;
  BtnBackFromImprint2.BeforeLoadDFMValues;
  try
    Name := 'ImprintForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'Foo';
    Visible := True;
    BtnBackFromImprint.SetParentComponent(Self);
    BtnBackFromImprint.Name := 'BtnBackFromImprint';
    BtnBackFromImprint.Left := 8;
    BtnBackFromImprint.Top := 110;
    BtnBackFromImprint.Width := 130;
    BtnBackFromImprint.Height := 25;
    BtnBackFromImprint.Caption := 'BtnBackFromImprint';
    BtnBackFromImprint.ChildOrder := 2;
    BtnBackFromImprint.ElementFont := efCSS;
    BtnBackFromImprint.ElementPosition := epIgnore;
    BtnBackFromImprint.HeightPercent := 100.000000000000000000;
    BtnBackFromImprint.WidthPercent := 100.000000000000000000;
    SetEvent(BtnBackFromImprint, Self, 'OnClick', 'BtnBackFromImprintClick');
    EmailJC.SetParentComponent(Self);
    EmailJC.Name := 'EmailJC';
    EmailJC.Left := 16;
    EmailJC.Top := 65;
    EmailJC.Width := 96;
    EmailJC.Height := 24;
    EmailJC.HeightPercent := 100.000000000000000000;
    EmailJC.WidthPercent := 100.000000000000000000;
    EmailJC.Caption := 'E-Mail an J'#246'rg Colloseus';
    EmailJC.ChildOrder := 1;
    SetEvent(EmailJC, Self, 'OnClick', 'EmailJCClick');
    EmailService.SetParentComponent(Self);
    EmailService.Name := 'EmailService';
    EmailService.Left := 16;
    EmailService.Top := 20;
    EmailService.Width := 96;
    EmailService.Height := 24;
    EmailService.HeightPercent := 100.000000000000000000;
    EmailService.WidthPercent := 100.000000000000000000;
    EmailService.Caption := 'E-Mail an den Kundenservice';
    EmailService.ChildOrder := 1;
    SetEvent(EmailService, Self, 'OnClick', 'EmailServiceClick');
    BtnBackFromImprint2.SetParentComponent(Self);
    BtnBackFromImprint2.Name := 'BtnBackFromImprint2';
    BtnBackFromImprint2.Left := 8;
    BtnBackFromImprint2.Top := 141;
    BtnBackFromImprint2.Width := 130;
    BtnBackFromImprint2.Height := 25;
    BtnBackFromImprint2.Caption := 'BtnBackFromImprint';
    BtnBackFromImprint2.ChildOrder := 2;
    BtnBackFromImprint2.ElementFont := efCSS;
    BtnBackFromImprint2.ElementPosition := epIgnore;
    BtnBackFromImprint2.HeightPercent := 100.000000000000000000;
    BtnBackFromImprint2.WidthPercent := 100.000000000000000000;
    SetEvent(BtnBackFromImprint2, Self, 'OnClick', 'BtnBackFromImprintClick');
  finally
    BtnBackFromImprint.AfterLoadDFMValues;
    EmailJC.AfterLoadDFMValues;
    EmailService.AfterLoadDFMValues;
    BtnBackFromImprint2.AfterLoadDFMValues;
  end;
end;

End.
