Unit DMMain;

Interface

Uses
  JSDelphiSystem, SysUtils, Classes, Web, WEBLib.Utils,
  WEBLib.Dialogs, WEBLib.Controls, WEBLib.Modules, WEBLib.ExtCtrls, WEBLib.REST,
  Tools, LocalStorage, JS, JSON, JSFuncs;

// del /q $(OUTPUTDIR)\*&for /d %x in ($(OUTPUTDIR)\*) do @rd /s /q "%x"

Const
 // Will be patched with current build number in JS file after build process
 CurrentProgramVersion = '---';

 LandingPageURL = 'https://www.steuercheckliste.de';

 {$If defined(DEBUG)}
  REST_API_HOST = 'http://localhost/Deubner';
 {$ElseIf defined(RELEASE)}
  REST_API_HOST = 'https://www.steuercheckliste.de/app';
 {$ElseIf defined(VILLAB)}
  REST_API_HOST = 'https://villabeethoven.de/Deubner';
 {$Else}
  {$message FATAL 'Neither DEBUG nor RELEASE nor VILLAB mode active'}
  REST_API_HOST = '<keep compiler happy>';
 {$EndIf}
 REST_API_URL = REST_API_HOST + '/WWYPHP/REST/API.php';
 REST_API_PDF_OUTPUT_PATH = REST_API_HOST + '/WWYPHP/Data/PDFDownloads/';
 REST_API_CLIENTICONS_OUTPUT_PATH = REST_API_HOST + '/WWYPHP/Data/ClientIcons/';

Type
  TRESTErrorType = (reUnknown,reTimeout,reAbort,reError,reServerError);

  TRESTErrorEvent = Reference to  Procedure (
                Sender : THttpRequest;
             ErrorType : TRESTErrorType;
             ErrorCode : Integer;
    Const ErrorMessage : String;
           Var Handled : Boolean);

  TRESTResponseEvent = Reference to Procedure (
              Sender : THttpRequest;
        ResponseJSON : TJSObject;
               _Then : TThenProc = Nil);

  TRESTRequestDef = Record
   RequestProc   : TThenProc;
   OnResponse    : TRESTResponseEvent;
   OnError       : TRESTErrorEvent;
   AfterSuccess  : TThenProc;
   AccTokUsed    : Boolean;
   Data          : JSValue;
   Procedure Clear;
  End;

Type
  TDM = Class(TDataModule)
    HR: THttpRequest;
    TimerContinueWith: TTimer;
    LogHR: THttpRequest;
    TimerDBLog: TTimer;
    TimerStartUpdate: TTimer;
    procedure HRAbort(Sender: TObject);
    procedure HRError(Sender: TObject; ARequest: TJSXMLHttpRequestRecord;
      Event: TJSEventRecord; var Handled: Boolean);
    procedure HRRequestResponse(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure HRTimeout(Sender: TObject);
    procedure WebDataModuleCreate(Sender: TObject);
    procedure TimerContinueWithTimer(Sender: TObject);
    procedure LogHRAbort(Sender: TObject);
    procedure LogHRError(Sender: TObject; ARequest: TJSXMLHttpRequestRecord;
      Event: TJSEventRecord; var Handled: Boolean);
    procedure LogHRRequestResponse(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure LogHRResponse(Sender: TObject; AResponse: string);
    procedure LogHRTimeout(Sender: TObject);
    procedure TimerDBLogTimer(Sender: TObject);
    procedure TimerStartUpdateTimer(Sender: TObject);
   Private
    FReq     : TRESTRequestDef;
    FOrigReq : TRESTRequestDef;
    FContProc: TThenProc;
    FContData: JSValue;

    Procedure CheckOnline;
    Procedure CheckLoggedIn;

    Procedure StartRESTRequest;
    Procedure DefaultRESTError(Sender : THttpRequest;
                            ErrorType : TRESTErrorType;
                            ErrorCode : Integer;
                   Const ErrorMessage : String;
                          Var handled : Boolean);
    Procedure HRDispatchError(Sender: THttpRequest;
      ErrorType: TRESTErrorType; ErrorCode: Integer;
      const ErrorMessage: String);

      // Login
    Procedure OnLoginReponse(Sender : THttpRequest; JO : TJSObject; AndThen : TThenProc= Nil);
    Procedure OnLoginError(Sender : THttpRequest; ErrorType : TRESTErrorType;
      ErrorCode : Integer; Const ErrorMessage : String; Var Handled : Boolean);
    Procedure RefreshAccessToken(JV: JSValue);
    Procedure AfterLogin(JV: JSValue);

    // List of temporary stored docs
    Procedure ExecLoadDocs(JV : JSValue);
    Procedure OnLoadDocsError(Sender: THttpRequest;
      ErrorType: TRESTErrorType; ErrorCode: Integer; const ErrorMessage: String;
      var Handled: Boolean);
    Procedure OnLoadDocsReponse(Sender: THttpRequest; JO: TJSObject; AndThen : TThenProc = Nil);

    // Load list of subject areas
    Procedure ExecLoadSubjectAreas(JV : JSValue);
    Procedure OnLoadSubjectAreasError(Sender: THttpRequest;
      ErrorType: TRESTErrorType; ErrorCode: Integer; const ErrorMessage: String;
      var Handled: Boolean);
    Procedure OnLoadSubjectAreasReponse(Sender: THttpRequest; JO: TJSObject; AndThen : TThenProc = Nil);

    // Reset DB-Log
    procedure ExecResetDBLog(JV: JSValue);
    procedure OnResetDBLogError(Sender: THttpRequest;
      ErrorType: TRESTErrorType; ErrorCode: Integer; const ErrorMessage: String;
      var Handled: Boolean);
    procedure OnResetDBLogReponse(Sender: THttpRequest; JO: TJSObject;
      AndThen: TThenProc);

    // Request PDF file download
    procedure ExecRequestPDFDownload(JV: JSValue);
    procedure OnRequestPDFDownloadError(Sender: THttpRequest;
      ErrorType: TRESTErrorType; ErrorCode: Integer; const ErrorMessage: String;
      var Handled: Boolean);
    procedure OnRequestPDFDownloadReponse(Sender: THttpRequest;
      JO: TJSObject; AndThen: TThenProc);

    // Delete a file
    procedure ExecDeleteTempFile(JV: JSValue);
    procedure OnDeleteTempFileError(Sender: THttpRequest;
      ErrorType: TRESTErrorType; ErrorCode: Integer; const ErrorMessage: String;
      var Handled: Boolean);
    procedure OnDeleteTempFileReponse(Sender: THttpRequest;
      JO: TJSObject; AndThen: TThenProc);

    // Get client version number (explicit)
    procedure OnGetClientVersionNumberError(Sender: THttpRequest;
      ErrorType: TRESTErrorType; ErrorCode: Integer; const ErrorMessage: String;
      var Handled: Boolean);
    procedure OnGetClientVersionNumberReponse(Sender: THttpRequest;
      JO: TJSObject; AndThen: TThenProc);

   Public
    Procedure Init;

    Procedure ContinueWith(ContinueProc : TThenProc; Data : JSValue = NIL; Delay : Integer = 0);
    { This async calls the given procedure using a timer. The function starts
      a timer of 1 ms and when the timer fires, ContinueProc will be executed.
      The call to ContinueProc returns immediately. Using this pattern is
      usefull to exit deeply nested function calls and to unwind the
      call stack. }

    Procedure RESTLogin(Const UserName, Password : String; AndThen : TThenProc);
    { Async login into the REST server. AndThen is called after successfull
      login. }

    Procedure RESTLoadDocs(Params : TJSObject; AndThen : TThenProc);
    { Async loading of the docs of the current user. AndThen is called after
      successfull loading the trips. }

    Procedure RESTLoadSubjectAreas(AndThen: TThenProc);
    { Async loading of the global list of subject areas. AndThen is called after
      successfull loading the trips. }

    Procedure RESTResetDBLog(AndThen: TThenProc);
    Procedure RESTWriteDBLog(Const Text : String);

    Procedure RESTRequestPDFDownload(Const TempFileID : Integer; AndThen: TThenProc);

    Procedure RESTDeleteTempFile(Const TempFileID : Integer; AndThen: TThenProc);

    Procedure RESTGetClientVersionNumber(AndThen: TThenProc);

    Procedure UpdateApp;
    { This does some caching magic and PWA servieworker voodoo and finally
      reloads the application. }
  protected procedure LoadDFMValues; override; End;

Var
 DM   : TDM;
 LDat : TLocalData;

 // The following will be available after TDM.WebDataModuleCreate
 BrowserName    : String;
 BrowserVersion : Integer;
 BrowserType    : TBrowserType;

 // window.navigator object
 // See https://developer.mozilla.org/en-US/docs/Web/API/Navigator
 Navigator      : TJSObject;

{##############################################################################}

Implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}

Uses
 UITypes,WebLib.Forms,ServerErrors,StrUtils,Logger,
 FormMain,DateUtils,FormMenu;

Const
 GetClientVersionPostData = '{"Function":"GetClientVersion"}';


{---------------------------------------}

Procedure TDM.WebDataModuleCreate(Sender: TObject);
Begin
 Console.log(TrIn('TDM.WebDataModuleCreate'));
 Try
  GetBrowserSpecs(BrowserName,BrowserVersion,BrowserType,Navigator);
  Application.InitFormatSettings('de');
 Finally
  Console.log(TrOut('TDM.WebDataModuleCreate'));
 End;
End;

{---------------------------------------}

Procedure TDM.Init;
Begin
 Console.log(TrIn('TDM.Init'));
 Try
  HR.URL := REST_API_URL;
  LogHR.URL := REST_API_URL;
  Console.Log(Lg('TDM.Init',['BrowserName: ',BrowserName,', Version: ',BrowserVersion,
   ', Type: ',TBrowserTypeToStr(BrowserType)]));
 Finally
  Console.log(TrOut('TDM.Init'));
 End;
End;

{---------------------------------------}
{---------------------------------------}
{---------------------------------------}

Type
 ERESTRequestError = Class(Exception)
  ErrorType: TRESTErrorType;
  ErrorCode: Integer;
  UIMessage: String;
  Constructor Create(AErrorType: TRESTErrorType;
                     AErrorCode: Integer;
             Const ErrorMessage: String;
               Const AUIMessage: String = '');
 End;

{ ERESTRequestError }

Constructor ERESTRequestError.Create(AErrorType: TRESTErrorType;
                                     AErrorCode: Integer;
                             const ErrorMessage: String;
                               Const AUIMessage: String = '');
Begin
 ErrorType := AErrorType;
 ErrorCode := AErrorCode;
 UIMessage := AUIMessage;
 Inherited Create(ErrorMessage);
End;

{---------------------------------------}
{---------------------------------------}
{---------------------------------------}

{ TRESTRequestDef }

Procedure TRESTRequestDef.Clear;
Begin
 RequestProc   := Nil;
 OnResponse    := Nil;
 OnError       := Nil;
 AfterSuccess  := Nil;
 Data          := Nil;
 AccTokUsed    := True;
End;

{---------------------------------------}
{---------------------------------------}
{---------------------------------------}

Procedure TDM.ContinueWith(ContinueProc : TThenProc; Data : JSValue = NIL; Delay : Integer = 0);
Var FullName,FuncName,ClassName,ModuleName : String;
Begin
 FuncName   := '<func>';
 ClassName  := '<class>';
 ModuleName := '<module>';
  {$IFNDEF WIN32}
  ASM
   {
   if (ContinueProc.fn!==undefined) FuncName = ContinueProc.fn;
   if (ContinueProc.scope!==undefined) {
    if (ContinueProc.scope.$classname!==undefined) ClassName = ContinueProc.scope.$classname;
    if (ContinueProc.scope.$module.$name!==undefined) ModuleName = ContinueProc.scope.$module.$name;
   }
   }
  END;
  {$ENDIF}
 If ClassName='' then ClassName := 'GLOBAL';
 FullName := ModuleName+'.'+ClassName+'.'+FuncName;
 Console.Log(Lg('TDM.ContinueWith',[FullName]));
 FContProc := ContinueProc;
 FContData := Data;
 If Delay>0 then
  TimerContinueWith.Interval := Delay
 Else
  TimerContinueWith.Interval := 1;
 TimerContinueWith.Enabled := True;
End;

{---------------------------------------}

Procedure TDM.TimerContinueWithTimer(Sender: TObject);
Begin
 TimerContinueWith.Enabled := False;
 If assigned(FContProc) then
  FContProc(FContData);
End;

{---------------------------------------}

Procedure TDM.StartRESTRequest;
Begin
 Console.log(TrIn('TDM.StartRESTRequest'));
 Try
  Console.Log(Lg('TDM.StartRESTRequest',['Request JSON: ',HR.PostData]));
  MainForm.ShowThrobber(DefaultThrobberTimerInterval);
  HR.Execute;
 Finally
  Console.log(TrOut('TDM.StartRESTRequest'));
 End;
End;

{---------------------------------------}

Procedure TDM.DefaultRESTError(Sender: THttpRequest;
                            ErrorType: TRESTErrorType;
                            ErrorCode: Integer;
                   Const ErrorMessage: String;
                          Var handled: Boolean);

  Procedure OnModalResult(AValue : TModalResult);
  Begin
   // MainForm.ResetApp;
  End;

Var RMsg : String;

Begin
 Console.log(TrIn('TDM.DefaultRESTError'));
 Try
  Handled := True;
  MainForm.TmrThrobber.Enabled := False;
  MainForm.Throbber.Hide;
  Case ErrorType of
       reUnknown: RMsg := 'Unbekannter ';
       reTimeout: RMsg := 'Timeout-';
         reAbort: RMsg := 'Abbruch-';
         reError: RMsg := 'Anfrage-';
   reServerError: RMsg := 'Server-';
             else RMsg := 'Unbekannter ';
  End;
  RMsg := RMsg + Format(
   'Fehler [%d]'#13#10+
   'Ursache: %s',[ErrorCode,ErrorMessage]);
  MessageDlg(RMsg,mtError,[mbOk],@OnModalResult);
 Finally
  Console.log(TrOut('TDM.DefaultRESTError'));
 End;
End;

{---------------------------------------}

Procedure TDM.HRDispatchError(Sender: THttpRequest;
                            ErrorType: TRESTErrorType;
                            ErrorCode: Integer;
                   Const ErrorMessage: String);
Var Handled : Boolean;
Begin
 Handled := False;

 If FReq.AccTokUsed and (ErrorType=reServerError) and
  (ErrorCode in [1020,1070,2010..2060]) then
  Begin
   // Invalid or timed out access token!

   // Delete current user information
   LDat.Clear;

   // Login and get fresh access token
   ContinueWith(@RefreshAccessToken);
   Exit;
  End;

 If assigned(FReq.OnError) then
  Begin
   FReq.OnError(Sender as THttpRequest,ErrorType,ErrorCode,ErrorMessage,Handled);
   If not Handled then
    DefaultRESTError(Sender as THttpRequest,ErrorType,ErrorCode,ErrorMessage,Handled);
  End
 Else
  DefaultRESTError(Sender as THttpRequest,ErrorType,ErrorCode,ErrorMessage,Handled);
End;

{---------------------------------------}

Procedure TDM.HRAbort(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TDM.HRAbort'));
 Try
  Try
   // 444 - NO RESPONSE
   MainForm.HideThrobber;

   // Report error (to server)
   Try
    Raise ERESTRequestError.Create(reAbort,444,'Request aborted');
   Except
    {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
    Console.error(Err(e,'TDM.HRAbort'));
   End;

   HRDispatchError(Sender as THttpRequest,reAbort,444,'Anfrage-Abbruch');

  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.HRAbort','','','Server-Anfrageabbruch'));
  End;
 Finally
  Console.log(TrOut('TDM.HRAbort'));
 End;
End;

{---------------------------------------}

Procedure TDM.HRTimeout(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TDM.HRTimeout'));
 Try
  Try
   // 408 - REQUEST TIMEOUT
   MainForm.HideThrobber;

   // Report error (to server)
   Try
    Raise ERESTRequestError.Create(reTimeout,408,'Request Timeout');
   Except
    {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
    Console.error(Err(e,'TDM.HRTimeout'));
   End;

   HRDispatchError(Sender as THttpRequest,reTimeout,408,'Anfrage-Timeout');
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.HRTimeout','','','Server-Anfragetimeout'));
  End;
 Finally
  Console.log(TrOut('TDM.HRTimeout'));
 End;
End;

{---------------------------------------}

Procedure TDM.HRError(Sender: TObject; ARequest: TJSXMLHttpRequestRecord;
  Event: TJSEventRecord; var Handled: Boolean);
Var
 ErrorCode : Integer;
 e : Exception;
Begin
 Console.log(TrIn('TDM.HRError'));
 Try
  Try
   // ShowMessage('Request failed, Status:'+IntToStr(ARequest.req.Status));
   MainForm.HideThrobber;
   Handled := True;

   ErrorCode := ARequest.req.Status;
   If ErrorCode=0 then ErrorCode := 444; // 444 - NO RESPONSE

   // Report error (to server)
   Try
    Raise ERESTRequestError.Create(reError,ErrorCode,'Request probably rejected (CORS?)');
   Except
    {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
    Console.error(Err(e,'TDM.HRError'));
   End;

   HRDispatchError(Sender as THttpRequest,reError,ErrorCode,'Anfrage-Abweisung');
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.HRError','','','Server-Anfragefehler'));
  End;
 Finally
  Console.log(TrOut('TDM.HRError'));
 End;
End;

{---------------------------------------}

Procedure TDM.HRRequestResponse(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; AResponse: string);

Var
 JV           : JSValue;
 JO           : TJSObject;
 IsNull       : Boolean;
 HTTPStatus,
 RespStatus,
 RespLen,
 VersionNr,
 RCode,RLogID : Integer;
 Resp,UIMsg,
 RClass,RMsg  : String;
 e            : Exception;

Begin
 Console.log(TrIn('TDM.HRRequestResponse'));
 Try
  Try
   MainForm.HideThrobber;

   // HTTP protocol status
   HTTPStatus := ARequest.req.Status;
   Console.Log(Lg('TDM.HRRequestResponse',['Request-Status: '+IntToStr(HTTPStatus)]));

   // Log (abbreviated) raw response
   RespLen := Length(AResponse);
   Resp := AResponse;
   If HTTPStatus=200 then
    Begin
     If RespLen=0 then Resp := ''
     Else Resp := TrimStringMaxLen(AResponse,200);
    End;
   Console.Log(Lg('TDM.HRRequestResponse',['Response: ',Resp]));

   // Response empty?
   If RespLen=0 then
    Raise ERESTRequestError.Create(reError,444,
     'Empty response received.','Keine Daten erhalten');

   // Can response string be interpreted as JSON object?
   Try
    JV := CreateJSONObjectFromString(AResponse);
   Except
    Raise ERESTRequestError.Create(reError,445,
     'JSON parser error.','Ungültige Daten erhalten');
   End;

   If isObject(JV) then JO := TJSObject(JV)
   Else raise ERESTRequestError.Create(reError,446,
    'JSON data received is not a JSON object','Ungültige Daten erhalten');

   // Status field is mandatory and should match HTTP response status
   Try
    RespStatus := JO.GetAsInteger('Status',IsNull,True,True);
   Except
    {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
    Raise ERESTRequestError.Create(reError,447,
     'Error accessing JSON property "Sttaus": '+e.Message,'Ungültige Daten erhalten');
   End;

   // Status should match HTP-Status
   {

   WWY 2.2.2021: Changed that on server side such that the server always returns
   HTTP status 200, even on internal errors. This was necessary because some web server
   return an empty result when the HTTP status of the response is not equesl 200

   If RespStatus<>HTTPStatus then
    Raise ERESTRequestError.Create(reError,444,
     Format('Response status [%d] does not match HTTP status [%d]',[RespStatus,HTTPStatus]),
     'Ungültige Daten erhalten');
   }

   // When status is not "200 - Success" then server responds with a
   // standard error record in which the error code uniquely refers
   // to the error reason
   // If HTTPStatus<>200 then
   If RespStatus<>200 then
    Begin
     RCode  := JO.GetAsInteger('ErrorCode'   ,IsNull,False,False,   -1);
     RLogID := JO.GetAsInteger('ErrorLogID'  ,IsNull,False,False,   -1);
     RClass := JO.GetAsString ('ErrorClass'  ,IsNull,False,False,'<?>');
     RMsg   := JO.GetAsString ('ErrorMessage',IsNull,False,False,'<?>');
     UIMsg  := TranslateServerError(RCode);
     If UIMsg='' then UIMsg := RMsg;
     RMsg   := Format(
      'Server error! Code: %d, LogID: %d, Class: "%s", Reason: "%s"',
      [RCode,RLogID,RClass,RMsg]);
     Raise ERESTRequestError.Create(reServerError,RCode,RMsg,UIMsg);
    End;

   // Check client version number returned from server on each request
   If not TimerStartUpdate.Enabled then
    Begin
     VersionNr := JO.GetAsInteger('ClientVersion',IsNull);
     If VersionNr<>StrToInt(CurrentProgramVersion) then
      Begin
       Console.Log(Lg('TDM.HRRequestResponse',['Client version mismatch, local : ',
        CurrentProgramVersion,', Server: ',VersionNr]));
       If HR.PostData<>GetClientVersionPostData then
        // Don't kick update timer if this is an explicit version number request
        TimerStartUpdate.Enabled := True;
      End;
    End;

   // Dispatch the response
   If Assigned(FReq.OnResponse) then
     FReq.OnResponse(Sender as THttpRequest,JO,FReq.AfterSuccess);
  Except
   On EX:ERESTRequestError do
    Begin
     Console.error(Err(EX,'TDM.HRRequestResponse'));
     HRDispatchError(Sender as THttpRequest,EX.ErrorType,EX.ErrorCode,EX.UIMessage);
    End;
   Else
    Begin
     {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
     Console.error(Err(e,'TDM.HRRequestResponse','','','Antwort-Behandlungsfehler'));
    End;
  End;
 Finally
  Console.log(TrOut('TDM.HRRequestResponse'));
 End;
End;

{---------------------------------------}

Procedure TDM.CheckOnline;
Begin
 Console.log(TrIn('TDM.CheckOnline'));
 Try
  If not Application.IsOnline then
   Begin
    Console.Log(Lg('TDM.CheckOnline',['System is offline! Aborting...']));
    MessageDlg(
     'Für diese Abfrage wird eine Internet-Verbindung benötigt. '+
     'Zurzeit scheint keine Verbindungs ins Internet zu bestehen.'
    ,mtInformation,[mbOk]);
    MainForm.ResetApp;
    Abort;
   End;
 Finally
  Console.log(TrOut('TDM.CheckOnline'));
 End;
End;

{---------------------------------------}

Procedure TDM.CheckLoggedIn;
Begin
 Console.log(TrIn('TDM.CheckLoggedIn'));
 Try
  CheckOnline;

  If not LDat.PropExists('AccessToken') then
   Begin
    Console.Log(Lg('TDM.CheckLoggedIn',['No stored access token available! Starting login process.']));
    RefreshAccessToken(Nil);

    Console.Log(Lg('TDM.CheckLoggedIn',['Aborting...']));
    Abort;
   End;
 Finally
  Console.log(TrOut('TDM.CheckLoggedIn'));
 End;
End;

{---------------------------------------}
{---------------------------------------}

Procedure TDM.RESTLogin(const UserName, Password: String; AndThen : TThenProc);
Var
 JO : TJSObject;
 VW : Integer;
Begin
 Console.log(TrIn('TDM.RESTLogin'));
 Try
  LDat.Clear;
  LDat.UserName := UserName;

  CheckOnline;
  FReq.Clear;
  FReq.OnResponse := Self.OnLoginReponse;
  FReq.OnError := Self.OnLoginError;
  FReq.AfterSuccess := AndThen;

  // Only this function does not use an Access Token
  FReq.AccTokUsed := False;

  // WebHttpRequest1.Headers.AddPair('Authorization','Basic YXBpdXNlcjp0cmFsbGFsYQ==');
  JO := TJSObject.new;
  JO['Function'] := 'Login';
  JO['UserName'] := UserName;
  JO['Password'] := Password;

  // As long as TMS has problems resizing the cutomer logo, provide the size
  // of the canvas to have the server create a resized logo
  {$IFNDEF WIN32}
  ASM
   VW = window.innerWidth;
  END;
  {$ENDIF}
  JO['MaxIconWidth'] := VW;
  JO['MaxIconHeight'] := 73;

  HR.PostData := JO.ToJSONString;

  StartRESTRequest;
 Finally
  Console.log(TrOut('TDM.RESTLogin'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnLoginError(Sender: THttpRequest; ErrorType: TRESTErrorType;
  ErrorCode: Integer; const ErrorMessage: String; var Handled: Boolean);
Begin
 Console.log(TrIn('TDM.OnLoginError'));
 Try
  Console.Log(Lg('TDM.OnLoginError',['ErrorCode: ',ErrorCode,', ErrorMessage: ',ErrorMessage]));
  If ErrorCode in [1030,1040,2010,2020,2030,2040,2050,2055] then
   Begin
    MainForm.ResetApp;
    Handled := True;
    MessageDlg(ErrorMessage,mtInformation,[mbOk]);
   End;
 Finally
  Console.log(TrOut('TDM.OnLoginError'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnLoginReponse(Sender: THttpRequest; JO: TJSObject; AndThen : TThenProc= Nil);
Var
 IsNull : Boolean;
 e : Exception;
Begin
 Console.log(TrIn('TDM.OnLoginReponse'));
 Try
  Try
   LDat.BeginUpdate;
   Try
    LDat.AccessToken        := JO.GetAsString ('AccessToken'   ,IsNull,True,True);
    LDat.UserID             := JO.GetAsInteger('UserID'        ,IsNull,True,True);
    LDat.LastName           := JO.GetAsString ('LastName'      ,IsNull);
    LDat.MiddleName         := JO.GetAsString ('MiddleName'    ,IsNull);
    LDat.FirstName          := JO.GetAsString ('FirstName'     ,IsNull);
    LDat.Salutation         := JO.GetAsString ('Salutation'    ,IsNull,True,False,'Hr./Fr.');
    LDat.MandantID          := JO.GetAsString ('MandantID'     ,IsNull,True,True);
    LDat.MandantHasIcon     := JO.GetAsBoolean('HasIcon'       ,IsNull,True,True);
    LDat.IconFileName       := JO.GetAsString ('IconFile'      ,IsNull,True,LDat.MandantHasIcon);
   Finally
    LDat.EndUpdate;
   End;

   Logger.SetCurrentUser(LDat.UserName,LDat.UserID);
   Console.Log(Lg('TDM.OnLoginReponse',[#13#10'Received user details:'+#13#10+
   '  AccessToken        : '+LDat.AccessToken+#13#10+
   '  UserID             : '+IntToStr(LDat.UserID)+#13#10+
   '  LastName           : '+LDat.LastName+#13#10+
   '  MiddleName         : '+LDat.MiddleName+#13#10+
   '  FirstName          : '+LDat.FirstName+#13#10+
   '  Salutation         : '+LDat.Salutation+#13#10+
   '  MandantID          : '+LDat.MandantID+#13#10+
   '  MandantHasIcon     : '+BoolToStr(LDat.MandantHasIcon,True)+#13#10+
   '  IconFileName       : '+LDat.IconFileName
   ]));

   // Has SuperUSer logged in?
   IsSuperUser := (LDat.UserID=SuperUserID);
   MenuForm.BtnMenuToggleLogging.Visible := IsSuperUser;

   If assigned(AndThen) then AndThen(True);

  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.OnLoginReponse','','','Server-Datenfehler'));
   LDat.Clear;
   MainForm.ResetApp;
  End;
 Finally
  Console.log(TrOut('TDM.OnLoginReponse'));
 End;
End;

{---------------------------------------}

Procedure TDM.RefreshAccessToken(JV : JSValue);
Begin
 Console.log(TrIn('TDM.RefreshAccessToken'));
 Try
  FOrigReq := FReq;
  MainForm.GetLoginCredentials(@AfterLogin);
 Finally
  Console.log(TrOut('TDM.RefreshAccessToken'));
 End;
End;

{---------------------------------------}

Procedure TDM.AfterLogin(JV : JSValue);
Begin
 Console.log(TrIn('TDM.AfterLogin'));
 Try
  If Boolean(JV) then
   Begin
    Console.Log(Lg('TDM.AfterLogin',['New access token retrieved.']));
    // Now continue with the action that initiated the access token refresh
    FReq := FOrigReq;
    If assigned(FReq.RequestProc) then
     ContinueWith(FReq.RequestProc);
   End
  Else
   Begin
    Console.Log(Lg('TDM.AfterLogin',['User login aborted.']));
   End;
 Finally
  Console.log(TrOut('TDM.AfterLogin'));
 End;
End;

{---------------------------------------}
{---------------------------------------}

Procedure TDM.RESTLoadDocs(Params : TJSObject; AndThen : TThenProc);
Begin
 Console.log(TrIn('TDM.RESTLoadDocs'));
 Try
  FReq.Clear;
  FReq.RequestProc  := ExecLoadDocs;
  FReq.OnResponse   := Self.OnLoadDocsReponse;
  FReq.OnError      := Self.OnLoadDocsError;
  FReq.AfterSuccess := AndThen;
  FReq.Data         := Params;
  CheckLoggedIn;
  ExecLoadDocs(Nil);
 Finally
  Console.log(TrOut('TDM.RESTLoadDocs'));
 End;
End;

{---------------------------------------}

Procedure TDM.ExecLoadDocs(JV: JSValue);
Var JO : TJSObject;
Begin
 Console.log(TrIn('TDM.ExecLoadDocs'));
 Try
  JO := TJSObject.new;
  JO['Function'] := 'GetUplTempFiles';
  JO['AccessToken'] := LDat.AccessToken;
  JO.AppendObject(TJSObject(FReq.Data));
  HR.PostData := JO.ToJSONString;
  StartRESTRequest;
 Finally
  Console.log(TrOut('TDM.ExecLoadDocs'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnLoadDocsError(Sender: THttpRequest; ErrorType: TRESTErrorType;
  ErrorCode: Integer; const ErrorMessage: String; var Handled: Boolean);
Begin
 Console.log(TrIn('TDM.OnLoadDocsError'));
 Try
  Console.Log(Lg('TDM.OnLoadDocsError',['ErrorCode:',ErrorCode,', ErrorMessage:',ErrorMessage]));
  Handled := True;
  MessageDlgEx (AppCaption,Format('Fehler %d beim Laden der Dokumentenliste, Ursache: %s',[ErrorCode,ErrorMessage]),mtError).Show;
 Finally
  Console.log(TrOut('TDM.OnLoadDocsError'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnLoadDocsReponse(Sender: THttpRequest; JO: TJSObject; AndThen : TThenProc= Nil);
Var
 IsNull : Boolean;
 NrRows : Integer;
 Rows   : TJSArray;
 e      : Exception;
Begin
 Console.log(TrIn('TDM.OnLoadDocsReponse'));
 Try
  Try
   NrRows := JO.GetAsInteger('NrRows',IsNull,True,True);
   Console.Log(Lg('TDM.OnLoadDocsReponse',['Nr rows received: '+IntToStr(NrRows)]));
   Rows := JO.GetAsArray('Rows',IsNull,True,True);
   LDat.DocList := Rows;
   If assigned(AndThen) then AndThen(Nil);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.OnLoadDocsReponse','','','Server-Datenfehler'));
   LDat.Clear;
   MainForm.ResetApp;
  End;
 Finally
  Console.log(TrOut('TDM.OnLoadDocsReponse'));
 End;
End;

{---------------------------------------}
{---------------------------------------}

Procedure TDM.RESTLoadSubjectAreas(AndThen: TThenProc);
Begin
 Console.log(TrIn('TDM.RESTLoadSubjectAreas'));
 Try
  FReq.Clear;
  FReq.RequestProc  := Self.ExecLoadSubjectAreas;
  FReq.OnResponse   := Self.OnLoadSubjectAreasReponse;
  FReq.OnError      := Self.OnLoadSubjectAreasError;
  FReq.AfterSuccess := AndThen;
  FReq.Data         := Nil;
  CheckLoggedIn;
  ExecLoadSubjectAreas(Nil);
 Finally
  Console.log(TrOut('TDM.RESTLoadSubjectAreas'));
 End;
End;

{---------------------------------------}

Procedure TDM.ExecLoadSubjectAreas(JV : JSValue);
Var JO : TJSObject;
Begin
 Console.log(TrIn('TDM.ExecLoadSubjectAreas'));
 Try
  JO := TJSObject.new;
  JO['Function'] := 'GetSubjectAreas';
  JO['AccessToken'] := LDat.AccessToken;
  HR.PostData := JO.ToJSONString;
  StartRESTRequest;
 Finally
  Console.log(TrOut('TDM.ExecLoadSubjectAreas'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnLoadSubjectAreasError(Sender: THttpRequest;
  ErrorType: TRESTErrorType; ErrorCode: Integer; const ErrorMessage: String;
  var Handled: Boolean);
Begin
 Console.log(TrIn('TDM.OnLoadSubjectAreasError'));
 Try
  Console.Log(Lg('TDM.OnLoadSubjectAreasError',['ErrorCode:',ErrorCode,', ErrorMessage:',ErrorMessage]));
  Handled := True;
  MessageDlgEx (AppCaption,Format('Fehler %d beim Laden der Sachbereiche, Ursache: %s',[ErrorCode,ErrorMessage]),mtError).Show;
 Finally
  Console.log(TrOut('TDM.OnLoadSubjectAreasError'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnLoadSubjectAreasReponse(Sender: THttpRequest; JO: TJSObject; AndThen : TThenProc = Nil);
Var
 IsNull : Boolean;
 NrRows : Integer;
 Rows   : TJSArray;
 e      : Exception;
Begin
 Console.log(TrIn('TDM.OnLoadSubjectAreasReponse'));
 Try
  Try
   NrRows := JO.GetAsInteger('NrRows',IsNull,True,True);
   Console.Log(Lg('TDM.OnLoadSubjectAreasReponse',['Nr rows received: '+IntToStr(NrRows)]));
   Rows := JO.GetAsArray('Rows',IsNull,True,True);
   LDat.SubjAreasList := Rows;
   If assigned(AndThen) then AndThen(Nil);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.OnLoadSubjectAreasReponse','','','Server-Datenfehler'));
   LDat.Clear;
   MainForm.ResetApp;
  End;
 Finally
  Console.log(TrOut('TDM.OnLoadSubjectAreasReponse'));
 End;
End;

{---------------------------------------}
{---------------------------------------}

Procedure TDM.RESTResetDBLog(AndThen: TThenProc);
Begin
 Console.log(TrIn('TDM.RESTResetDBLog'));
 Try
  FReq.Clear;
  FReq.RequestProc  := Self.ExecResetDBLog;
  FReq.OnResponse   := Self.OnResetDBLogReponse;
  FReq.OnError      := Self.OnResetDBLogError;
  FReq.AfterSuccess := AndThen;
  FReq.Data         := Nil;
  CheckLoggedIn;
  ExecResetDBLog(Nil);
 Finally
  Console.log(TrOut('TDM.RESTResetDBLog'));
 End;
End;

{---------------------------------------}

Procedure TDM.ExecResetDBLog(JV : JSValue);
Var JO : TJSObject;
Begin
 Console.log(TrIn('TDM.ExecResetDBLog'));
 Try
  JO := TJSObject.new;
  JO['Function'] := 'ResetLog';
  JO['AccessToken'] := LDat.AccessToken;
  HR.PostData := JO.ToJSONString;
  StartRESTRequest;
 Finally
  Console.log(TrOut('TDM.ExecResetDBLog'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnResetDBLogError(Sender: THttpRequest;
  ErrorType: TRESTErrorType; ErrorCode: Integer; const ErrorMessage: String;
  var Handled: Boolean);
Begin
 Console.log(TrIn('TDM.OnResetDBLogError'));
 Try
  Console.Log(Lg('TDM.OnResetDBLogError',['ErrorCode:',ErrorCode,', ErrorMessage:',ErrorMessage]));
  Handled := True;
 Finally
  Console.log(TrOut('TDM.OnResetDBLogError'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnResetDBLogReponse(Sender: THttpRequest; JO: TJSObject; AndThen : TThenProc = Nil);
Var e : Exception;
Begin
 Console.log(TrIn('TDM.OnResetDBLogReponse'));
 Try
  Try
   If assigned(AndThen) then AndThen(Nil);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.OnResetDBLogReponse'));
  End;
 Finally
  Console.log(TrOut('TDM.OnResetDBLogReponse'));
 End;
End;

{---------------------------------------}
{---------------------------------------}

Var
 BusyLoggingToDB : Boolean = False;

Procedure TDM.RESTWriteDBLog(Const Text : String);
Var
 JO : TJSObject;
 S  : String;
Begin

 If BusyLoggingToDB then exit;
 If Text='' then
  Begin
   BusyLoggingToDB := False;
   Exit;
  End;

 Try
  JO := TJSObject.new;
  JO['Function'] := 'Log';
  JO['Text'] := Text;

  S := JO.ToJSONString;
  S := StringReplace(S,'\r\n\n','\r\n',[rfReplaceAll]);
  S := StringReplace(S,':\n','\r\n',[rfReplaceAll]);
  LogHR.PostData := S;

  BusyLoggingToDB := True;
  LogHR.Execute;
 Except
 End;
End;

{---------------------------------------}

Procedure TDM.TimerDBLogTimer(Sender: TObject);
Begin
 If BusyLoggingToDB then exit;
 RESTWriteDBLog(PopLogStack);
End;

{---------------------------------------}

Procedure TDM.LogHRAbort(Sender: TObject);
Begin
 BusyLoggingToDB := False;
 Console.Log('TDM.LogHRAbort: Write to DB error log aborted');
End;

{---------------------------------------}

Procedure TDM.LogHRError(Sender: TObject; ARequest: TJSXMLHttpRequestRecord;
  Event: TJSEventRecord; var Handled: Boolean);
Begin
 BusyLoggingToDB := False;
 Handled := True;
 Console.Log('TDM.LogHRError: ErrorCode:',ARequest.req.Status);
End;

{---------------------------------------}

Procedure TDM.LogHRRequestResponse(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; AResponse: string);
Begin
 BusyLoggingToDB := False;
End;

{---------------------------------------}

Procedure TDM.LogHRResponse(Sender: TObject; AResponse: string);
Begin
 BusyLoggingToDB := False;
End;

{---------------------------------------}

Procedure TDM.LogHRTimeout(Sender: TObject);
Begin
 BusyLoggingToDB := False;
 Console.Log('TDM.LogHRTimeout: Write to DB error log timed out');
End;

{---------------------------------------}
{---------------------------------------}

Procedure TDM.RESTRequestPDFDownload(Const TempFileID : Integer; AndThen: TThenProc);
Begin
 Console.log(TrIn('TDM.RESTRequestPDFDownload'));
 Try
  FReq.Clear;
  FReq.RequestProc  := Self.ExecRequestPDFDownload;
  FReq.OnResponse   := Self.OnRequestPDFDownloadReponse;
  FReq.OnError      := Self.OnRequestPDFDownloadError;
  FReq.AfterSuccess := AndThen;
  FReq.Data         := TempFileID;
  CheckLoggedIn;
  ExecRequestPDFDownload(Nil);
 Finally
  Console.log(TrOut('TDM.RESTRequestPDFDownload'));
 End;
End;

{---------------------------------------}

Procedure TDM.ExecRequestPDFDownload(JV : JSValue);
Var JO : TJSObject;
Begin
 Console.log(TrIn('TDM.ExecRequestPDFDownload'));
 Try
  JO := TJSObject.new;
  JO['Function']    := 'RequestDownload';
  JO['AccessToken'] := LDat.AccessToken;
  JO['TempFileID']  := Integer(FReq.Data);
  HR.PostData := JO.ToJSONString;
  StartRESTRequest;
 Finally
  Console.log(TrOut('TDM.ExecRequestPDFDownload'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnRequestPDFDownloadError(Sender: THttpRequest;
  ErrorType: TRESTErrorType; ErrorCode: Integer; const ErrorMessage: String;
  var Handled: Boolean);
Begin
 Console.log(TrIn('TDM.OnRequestPDFDownloadError'));
 Try
  Console.Log(Lg('TDM.OnRequestPDFDownloadError',['ErrorCode:',ErrorCode,', ErrorMessage:',ErrorMessage]));
  Handled := True;
  MessageDlgEx (AppCaption,Format('Fehler %d beim Download des Dokuments, Ursache: %s',[ErrorCode,ErrorMessage]),mtError).Show;
 Finally
  Console.log(TrOut('TDM.OnRequestPDFDownloadError'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnRequestPDFDownloadReponse(Sender: THttpRequest; JO: TJSObject; AndThen : TThenProc = Nil);
Var e : Exception;
Begin
 Console.log(TrIn('TDM.OnRequestPDFDownloadReponse'));
 Try
  Try
   If assigned(AndThen) then AndThen(JO);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.OnRequestPDFDownloadReponse'));
  End;
 Finally
  Console.log(TrOut('TDM.OnRequestPDFDownloadReponse'));
 End;
End;

{---------------------------------------}
{---------------------------------------}

Procedure TDM.RESTDeleteTempFile(Const TempFileID : Integer; AndThen: TThenProc);
Begin
 Console.log(TrIn('TDM.RESTDeleteTempFile'));
 Try
  FReq.Clear;
  FReq.RequestProc  := Self.ExecDeleteTempFile;
  FReq.OnResponse   := Self.OnDeleteTempFileReponse;
  FReq.OnError      := Self.OnDeleteTempFileError;
  FReq.AfterSuccess := AndThen;
  FReq.Data         := TempFileID;
  CheckLoggedIn;
  ExecDeleteTempFile(Nil);
 Finally
  Console.log(TrOut('TDM.RESTDeleteTempFile'));
 End;
End;

{---------------------------------------}

Procedure TDM.ExecDeleteTempFile(JV : JSValue);
Var JO : TJSObject;
Begin
 Console.log(TrIn('TDM.ExecDeleteTempFile'));
 Try
  JO := TJSObject.new;
  JO['Function']    := 'DeleteTempFile';
  JO['AccessToken'] := LDat.AccessToken;
  JO['TempFileID']  := Integer(FReq.Data);
  HR.PostData := JO.ToJSONString;
  StartRESTRequest;
 Finally
  Console.log(TrOut('TDM.ExecDeleteTempFile'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnDeleteTempFileError(Sender: THttpRequest;
  ErrorType: TRESTErrorType; ErrorCode: Integer; const ErrorMessage: String;
  var Handled: Boolean);
Begin
 Console.log(TrIn('TDM.OnDeleteTempFileError'));
 Try
  Console.Log(Lg('TDM.OnDeleteTempFileError',['ErrorCode:',ErrorCode,'ErrorMessage:',ErrorMessage]));
  Handled := True;
  MessageDlgEx (AppCaption,Format('Fehler %d beim Löschen des Dokuments, Ursache: %s',[ErrorCode,ErrorMessage]),mtError).Show;
 Finally
  Console.log(TrOut('TDM.OnDeleteTempFileError'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnDeleteTempFileReponse(Sender: THttpRequest; JO: TJSObject; AndThen : TThenProc = Nil);
Var e : Exception;
Begin
 Console.log(TrIn('TDM.OnDeleteTempFileReponse'));
 Try
  Try
   If assigned(AndThen) then AndThen(JO);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.OnDeleteTempFileReponse'));
  End;
 Finally
  Console.log(TrOut('TDM.OnDeleteTempFileReponse'));
 End;
End;

{---------------------------------------}
{---------------------------------------}

Procedure TDM.TimerStartUpdateTimer(Sender: TObject);
Var e : Exception;
Begin
 TimerStartUpdate.Enabled := False;
 Console.log(TrIn('TDM.TimerStartUpdateTimer'));
 Try
  Try
   MessageDlgEx(AppCaption,
    'Es liegt ein Update vor! Jetzt installieren?',
    mtConfirmation,['Ja','Später'],
    Procedure(AValue: TModalResult)
     Begin
      If AValue=0 then
       UpdateApp;
     End).Show;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.TimerStartUpdateTimer'));
  End;
 Finally
  Console.log(TrOut('TDM.TimerStartUpdateTimer'));
 End;
End;

{---------------------------------------}

Procedure TDM.UpdateApp;
Var e : Exception;
Begin
 Console.log(TrIn('TDM.UpdateApp'));
 Try
  Try
   {$IFNDEF WIN32}
   ASM
     // No idea what this actually does. Taken from here
     // https://forum.quasar-framework.org/topic/2560/solved-pwa-force-refresh-when-new-version-released/38
     if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
       for (let registration of registrations) {
        registration.update()
       }
      })
     }
     window.location.reload(true);
   END;
   {$ENDIF}
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.UpdateApp'));
  End;
 Finally
  Console.log(TrOut('TDM.UpdateApp'));
 End;
End;

{---------------------------------------}
{---------------------------------------}

Procedure TDM.RESTGetClientVersionNumber(AndThen: TThenProc);
Var e  : Exception;
Begin
 Console.log(TrIn('TDM.RESTGetClientVersionNumber'));
 Try
  Try
   CheckOnline;
   FReq.Clear;
   FReq.OnResponse := Self.OnGetClientVersionNumberReponse;
   FReq.OnError := Self.OnGetClientVersionNumberError;
   FReq.AfterSuccess := AndThen;
   FReq.AccTokUsed := False; // REST function call w/o AccessToken
   HR.PostData := GetClientVersionPostData;
   StartRESTRequest;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.RESTGetClientVersionNumber'));
  End;
 Finally
  Console.log(TrOut('TDM.RESTGetClientVersionNumber'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnGetClientVersionNumberError(Sender: THttpRequest; ErrorType: TRESTErrorType;
  ErrorCode: Integer; const ErrorMessage: String; var Handled: Boolean);
Begin
 Console.log(TrIn('TDM.OnGetClientVersionNumberError'));
 Try
  Console.Log(Lg('TDM.OnGetClientVersionNumberError',['ErrorCode: ',ErrorCode,', ErrorMessage: ',ErrorMessage]));
  MessageDlgEx(AppCaption,Format('Fehler %d, Ursache: %s',
   [ErrorCode,ErrorMessage]),mtInformation).Show;
 Finally
  Console.log(TrOut('TDM.OnGetClientVersionNumberError'));
 End;
End;

{---------------------------------------}

Procedure TDM.OnGetClientVersionNumberReponse(Sender: THttpRequest; JO: TJSObject; AndThen : TThenProc= Nil);
Var
 IsNull : Boolean;
 e : Exception;
 VersionNr : Integer;
Begin
 Console.log(TrIn('TDM.OnGetClientVersionNumberReponse'));
 Try
  Try
   VersionNr := JO.GetAsInteger('ClientVersion',IsNull);
   Console.Log(Lg('TDM.OnGetClientVersionNumberReponse',['Version Number: ',VersionNr]));
   If assigned(AndThen) then AndThen(VersionNr);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDM.OnGetClientVersionNumberReponse','','','Server-Datenfehler'));
  End;
 Finally
  Console.log(TrOut('TDM.OnGetClientVersionNumberReponse'));
 End;
End;

{---------------------------------------}
{---------------------------------------}

procedure TDM.LoadDFMValues;
begin
  inherited LoadDFMValues;

  HR := THttpRequest.Create(Self);
  TimerContinueWith := TTimer.Create(Self);
  LogHR := THttpRequest.Create(Self);
  TimerDBLog := TTimer.Create(Self);
  TimerStartUpdate := TTimer.Create(Self);

  HR.BeforeLoadDFMValues;
  TimerContinueWith.BeforeLoadDFMValues;
  LogHR.BeforeLoadDFMValues;
  TimerDBLog.BeforeLoadDFMValues;
  TimerStartUpdate.BeforeLoadDFMValues;
  try
    Name := 'DM';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    Height := 306;
    Width := 421;
    HR.SetParentComponent(Self);
    HR.Name := 'HR';
    HR.Command := httpPOST;
    SetEvent(HR, Self, 'OnAbort', 'HRAbort');
    SetEvent(HR, Self, 'OnError', 'HRError');
    SetEvent(HR, Self, 'OnRequestResponse', 'HRRequestResponse');
    SetEvent(HR, Self, 'OnTimeout', 'HRTimeout');
    HR.Left := 26;
    HR.Top := 12;
    TimerContinueWith.SetParentComponent(Self);
    TimerContinueWith.Name := 'TimerContinueWith';
    TimerContinueWith.Enabled := False;
    TimerContinueWith.Interval := 1;
    SetEvent(TimerContinueWith, Self, 'OnTimer', 'TimerContinueWithTimer');
    TimerContinueWith.Left := 95;
    TimerContinueWith.Top := 15;
    LogHR.SetParentComponent(Self);
    LogHR.Name := 'LogHR';
    LogHR.Command := httpPOST;
    SetEvent(LogHR, Self, 'OnAbort', 'LogHRAbort');
    SetEvent(LogHR, Self, 'OnError', 'LogHRError');
    SetEvent(LogHR, Self, 'OnResponse', 'LogHRResponse');
    SetEvent(LogHR, Self, 'OnRequestResponse', 'LogHRRequestResponse');
    SetEvent(LogHR, Self, 'OnTimeout', 'LogHRTimeout');
    LogHR.Left := 192;
    LogHR.Top := 15;
    TimerDBLog.SetParentComponent(Self);
    TimerDBLog.Name := 'TimerDBLog';
    TimerDBLog.Enabled := False;
    SetEvent(TimerDBLog, Self, 'OnTimer', 'TimerDBLogTimer');
    TimerDBLog.Left := 270;
    TimerDBLog.Top := 15;
    TimerStartUpdate.SetParentComponent(Self);
    TimerStartUpdate.Name := 'TimerStartUpdate';
    TimerStartUpdate.Enabled := False;
    TimerStartUpdate.Interval := 3000;
    SetEvent(TimerStartUpdate, Self, 'OnTimer', 'TimerStartUpdateTimer');
    TimerStartUpdate.Left := 30;
    TimerStartUpdate.Top := 80;
  finally
    HR.AfterLoadDFMValues;
    TimerContinueWith.AfterLoadDFMValues;
    LogHR.AfterLoadDFMValues;
    TimerDBLog.AfterLoadDFMValues;
    TimerStartUpdate.AfterLoadDFMValues;
  end;
end;

End.
