Unit FormUpload;

Interface

Uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DMMain, WEBLib.StdCtrls, 
WEBLib.ExtCtrls, WEBLib.WebCtrls, Tools, WEBLib.ComCtrls,
  WEBLib.Devices, Web.FileUpload;

Type
  TUploadForm = class(TForm)
    CbxSalesAreas: TComboBox;
    BtnYear1: TButton;
    BtnYear2: TButton;
    BtnYear3: TButton;
    BtnYear4: TButton;
    BtnUpload: TButton;
    BtnMenu2: TButton;
    EdDocTitel: TEdit;
    FPicker1: TFilePicker;
    ImgPickedFile: TImageControl;
    BtnBackToDocs: TButton;
    DivPBar: THTMLDiv;
    LabProgress: TLabel;
    PBarUpload: TProgressBar;
    FPicker2: TFilePicker;
    DivAddFileToUpload: THTMLDiv;
    LabFPicker2: THTMLDiv;
    procedure WebFormCreate(Sender: TObject);
    procedure BtnYear1Click(Sender: TObject);
    procedure BtnUploadClick(Sender: TObject);
    procedure BtnMenu2Click(Sender: TObject);
    procedure FPicker1Change(Sender: TObject);
    procedure FPicker1GetFileAsBase64(Sender: TObject; AFileIndex: Integer;
      ABase64: string);
    procedure BtnBackToDocsClick(Sender: TObject);
    procedure CbxSalesAreasChange(Sender: TObject);
    procedure FPicker2Change(Sender: TObject);
    procedure FPicker2GetFileAsBase64(Sender: TObject; AFileIndex: Integer;
      ABase64: string);
   Private
    FSelectedFiles  : TJSArray;
    FCurFileNo      : Integer;
    FUploadedSoFar  : Int64;
    FTotalUploadSize: Int64;
    FLabFPicker2Cap : String;
    procedure OnFileUploadComplete(Sender: TFileUpload; Failed, Aborted,
      TimedOut: Boolean; ResponseStatus: Integer; const ResponseText: String);
    procedure OnFileUploadProgress(Sender: TFileUpload; Uploaded,
      TotalSize: NativeInt);
    procedure RefreshAccessToken(JV: JSValue);
    procedure Clear;
    procedure SetNrFilesSelected(NrFiles: Integer);
   Public
    FFilterYear   : Integer;
    FFilterSubID  : Integer;
    Procedure FormInit;
    Procedure Enter;
    Procedure Leave;
    Procedure SetActiveButton;
  protected procedure LoadDFMValues; override; End;

Var
 UploadForm : TUploadForm;

{#############################################################################}

Implementation

{$R *.dfm}

Uses
 DateUtils, JSFuncs, WEBLib.JSON, WEBLib.WebTools, JSON, Logger, FormMain,
 FormDocList, FormCamera, FormMenu, LocalStorage, ServerErrors;

Procedure TUploadForm.WebFormCreate(Sender: TObject);

 Procedure PrepareBtn(Btn : TButton; Year : Integer);
 Begin
  Btn.Caption := IntToStr(Year);
  Btn.Tag := Year;
 End;

Var Yr,Mo,Dy : Word;
Begin
 Console.log(TrIn('TUploadForm.WebFormCreate'));
 Try
  DecodeDate(Now,Yr,Mo,Dy);
  PrepareBtn(BtnYear1,Yr-3);
  PrepareBtn(BtnYear2,Yr-2);
  PrepareBtn(BtnYear3,Yr-1);
  PrepareBtn(BtnYear4,Yr);
  FLabFPicker2Cap := GetWebControlInnerHTML(LabFPicker2);
  FSelectedFiles := TJSArray.new;
 Finally
  Console.log(TrOut('TUploadForm.WebFormCreate'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.FormInit;
Var
 e   : Exception;
 Ind : Integer;
Begin
 Console.log(TrIn('TUploadForm.FormInit'));
 Try
  Try
   SetActiveButton;
   If FFilterSubID=-1 then Ind := 0
   Else Ind := FFilterSubID;
   If CbxSalesAreas.Items.Count>0 then
    CbxSalesAreas.ItemIndex := Ind;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TUploadForm.FormInit','','','Initialisierung fehlgeschlagen'));
   Abort;
  End;
 Finally
  Console.log(TrOut('TUploadForm.FormInit'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.SetNrFilesSelected(NrFiles : Integer);
Var S : String;
Begin
 S := FLabFPicker2Cap;
 LabFPicker2.HTML.Text := StringReplace(S,'@@',IntToStr(NrFiles),[rfReplaceAll])
End;

{---------------------------------------}

Procedure TUploadForm.Clear;
Var e : Exception;
Begin
 Console.log(TrIn('TUploadForm.Clear'));
 Try
  Try
   PBarUpload.Position := 0;
   LabProgress.Caption := '--- / ---';
   ImgPickedFile.URL := '';
   ImgPickedFile.Visible := False;
   DivPBar.Visible := False;
   FPicker1.Files.Clear;
   BtnUpload.Enabled := False;
   BtnUpload.Visible := False;
   DivAddFileToUpload.Visible := False;
   SetNrFilesSelected(0);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TUploadForm.Clear'));
  End;
 Finally
  Console.log(TrOut('TUploadForm.Clear'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.Enter;
Var e : Exception;
Begin
 Console.log(TrIn('TUploadForm.Enter'));
 Try
  Try
   Clear;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TUploadForm.Enter'));
  End;
 Finally
  Console.log(TrOut('TUploadForm.Enter'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.Leave;
Var e : Exception;
Begin
 Console.log(TrIn('TUploadForm.Leave'));
 Try
  Try
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TUploadForm.Leave'));
  End;
 Finally
  Console.log(TrOut('TUploadForm.Leave'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.SetActiveButton;

 Procedure SetButton(Btn : TButton);
 Begin
  If Btn.Tag=FFilterYear then
   Begin
    Btn.ElementClassName := 'ButtonYearActive';
    Console.Log(Lg('TUploadForm.SetActiveButton.SetButton',['New active button:',Btn.Tag]));
   End
  Else if Btn.ElementClassName<>'ButtonYear' then
   Btn.ElementClassName := 'ButtonYear'
 End;

Begin
 Console.log(TrIn('TUploadForm.SetActiveButton'));
 Try
  SetButton(BtnYear1);
  SetButton(BtnYear2);
  SetButton(BtnYear3);
  SetButton(BtnYear4);
 Finally
  Console.log(TrOut('TUploadForm.SetActiveButton'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.BtnYear1Click(Sender: TObject);
Var ClickedYear : Integer;
Begin
 Console.log(TrIn('TUploadForm.BtnYear1Click'));
 Try
  ClickedYear := (Sender as TButton).Tag;
  FFilterYear := ClickedYear;
  SetActiveButton;
  DoclistForm.FFilterYear := FFilterYear;
  DoclistForm.SetActiveButton;
  CameraForm.FFilterYear := FFilterYear;
  CameraForm.SetActiveButton;
 Finally
  Console.log(TrOut('TUploadForm.BtnYear1Click'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.CbxSalesAreasChange(Sender: TObject);
Var
 e     : Exception;
 Ind,
 SubID : Integer;
 OID   : TObject;
Begin
 Console.log(TrIn('TUploadForm.CbxSalesAreasChange'));
 Try
  Try
   Ind := CbxSalesAreas.ItemIndex;
   If Ind>=0 then
    Begin
     If Ind>0 then
      Begin
       OID := CbxSalesAreas.Items.Objects[Ind];
       {$IFNDEF WIN32}
       ASM
        SubID = OID;
       END;
       {$ENDIF}
       If OID=Nil then; // Keep compiler happy
      End
     Else SubID := -1;
    End
   Else SubID := -1;
   FFilterSubID := SubID;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TUploadForm.CbxSalesAreasChange'));
  End;
 Finally
  Console.log(TrOut('TUploadForm.CbxSalesAreasChange'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.BtnBackToDocsClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TUploadForm.BtnBackToDocsClick'));
 Try
  Try
   DoclistForm.CbxSalesAreas.ItemIndex := Self.CbxSalesAreas.ItemIndex;
   SwitchToForm(DoclistForm);
   DoclistForm.UpdateDocList;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TUploadForm.BtnBackToDocsClick'));
  End;
 Finally
  Console.log(TrOut('TUploadForm.BtnBackToDocsClick'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.BtnUploadClick(Sender: TObject);
Var
 e   : Exception;
 FUp : TFileUpload;
 JV  : JSValue;
Begin
 Console.log(TrIn('TUploadForm.BtnUploadClick'));
 Try
  Try
   If FSelectedFiles.Length<1 then
    Begin
     BtnUpload.Enabled := False;
     BtnUpload.Visible := False;
     Exit;
    End;

   If EdDocTitel.Text='' then
    Begin
     MessageDlgEx(AppCaption,
      'Bitte geben Sie eine Beschreibung für das Dokument ein',
      mtWarning).Show;
     Exit;
    End;

   { Customer request as of 15.2.2021: Also allow empty "Sachgebiet"
   If FFilterSubID=-1 then
    Begin
     MessageDlgEx(AppCaption,
      'Bitte ordnen Sie dem Dokument ein Sachgebiet zu',
      mtWarning).Show;
     Exit;
    End;
   }

   FUploadedSoFar := 0;
   FTotalUploadSize := 0;
   For JV in FSelectedFiles do
    FTotalUploadSize := FTotalUploadSize + TJSHTMLFile(JV).size;

   FCurFileNo := 1;
   FUp := TFileUpload.Create;
   FUp.FileObject := TJSHTMLFile(FSelectedFiles[FCurFileNo-1]);
   FUp.URL := REST_API_URL;
   FUp.Params['Function'] := 'Upload';
   FUp.Params['AccessToken'] := LDat.AccessToken;
   FUp.Params['RefYear'] := IntToStr(FFilterYear);
   FUp.Params['Title'] := EdDocTitel.Text;
   FUp.Params['SubjArea'] := IntToStr(FFilterSubID);
   FUp.Params['FileNo'] := IntToStr(FCurFileNo);
   FUp.Params['FileCount'] := IntToStr(FSelectedFiles.Length);
   FUp.Params['FileGroup'] := '0';
   FUp.OnComplete := Self.OnFileUploadComplete;
   FUp.OnProgress := Self.OnFileUploadProgress;

   PBarUpload.Position := 0;
   LabProgress.Caption := '--- / ---';
   ImgPickedFile.Visible := False;
   DivPBar.Visible := True;

   MainForm.ShowThrobber(DefaultThrobberTimerInterval);
   FUp.Upload;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TUploadForm.BtnUploadClick'));
  End;
 Finally
  Console.log(TrOut('TUploadForm.BtnUploadClick'));
 End;
End;

{---------------------------------------}
{---------------------------------------}
{---------------------------------------}

Type
 EUploadError = Class(Exception)
  Public
   ErrorCode : Integer;
   UIMessage : String;
   Constructor Create(Const Msg : String; AErrorCode : Integer; Const AUIMessage : String);
 End;

Constructor EUploadError.Create(Const Msg : String; AErrorCode : Integer; Const AUIMessage : String);
Begin
 Inherited Create(Msg);
 ErrorCode := AErrorCode;
 UIMessage := AUIMessage;
End;

{---------------------------------------}
{---------------------------------------}
{---------------------------------------}

Procedure TUploadForm.OnFileUploadComplete(Sender: TFileUpload; Failed, Aborted,
  TimedOut: Boolean; ResponseStatus: Integer; const ResponseText: String);
Var
 e            : Exception;
 RespLen,
 RespStatus,
 RCode,RLogId,
 FileGroup    : Integer;
 Resp,RClass,
 RMsg,UIMsg   : String;
 JV           : JSValue;
 JO           : TJSObject;
 IsNull       : Boolean;
 FUp          : TFileUpload;
Begin
 Console.log(TrIn('TUploadForm.OnFileUploadComplete'));
 Try
  Try
   Console.Log(Lg('TUploadForm.OnFileUploadComplete',['Upload finished, result:'#13#10,
   'Failed        : ',BoolToStr(Failed, True),#13#10,
   'Aborted       : ',BoolToStr(Aborted, True),#13#10,
   'TimedOut      : ',BoolToStr(TimedOut, True),#13#10,
   'HTTP-Status   : ',ResponseStatus,#13#10,
   'ResponseText  : ',ResponseText]));

   If Failed or Aborted or Timedout then
    Begin
     If Failed then raise EUploadError.Create(
      'Upload failed with HTTPStatus: '+IntToStr(ResponseStatus)+
      ' and ResponseText: '+ResponseText,10,
      'Hochladen fehlgeschlagen, Status: '+IntToStr(ResponseStatus)
     );
     If Aborted then
      Begin
       MessageDlgEx(AppCaption,'Hochladen Abgebrochen',mtInformation).Show;
       Abort;
      End;
     If TimedOut then
      Begin
       MessageDlgEx(AppCaption,'Timeout-Fehler beim Hochladen',mtInformation).Show;
       Abort;
      End;
    End;

   RespLen := Length(ResponseText);
   Resp := ResponseText;

   // Response empty?
   If RespLen=0 then
    Raise EUploadError.Create('Empty response received',450,'Leere Antwort vom Server');

   // Can response string be interpreted as JSON object?
   Try
    JV := CreateJSONObjectFromString(Resp);
   Except
    Raise EUploadError.Create('JSON parser error.',451,'Ungültige JSON-Antwort erhalten');
   End;

   If isObject(JV) then JO := TJSObject(JV)
   Else raise EUploadError.Create(
    'JSON data received is not a JSON object',452,'Antwort ist kein JSON-Objekt');

   // Status field is mandatory
   Try
    RespStatus := JO.GetAsInteger('Status',IsNull,True,True);
   Except
    {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
    Raise EUploadError.Create(
     'Error accessing JSON property "Status": '+e.Message,453,'Status fehlt in Antwort');
   End;

   // When status is not "200 - Success" then server responds with a
   // standard error record in which the error code uniquely refers
   // to the error reason
   // If HTTPStatus<>200 then
   If RespStatus<>200 then
    Begin
     RCode  := JO.GetAsInteger('ErrorCode'   ,IsNull,False,False,   -1);

     If RCode in [1020,1070,2010..2060] then
      Begin
       // Invalid or timed out access token!
       Console.Log(Lg('TUploadForm.OnFileUploadComplete',
        ['Access token invalid, login required']));

       // Login and get fresh access token
       DM.ContinueWith(@RefreshAccessToken);
       Exit;
      End;

     RLogID := JO.GetAsInteger('ErrorLogID'  ,IsNull,False,False,   -1);
     RClass := JO.GetAsString ('ErrorClass'  ,IsNull,False,False,'<?>');
     RMsg   := JO.GetAsString ('ErrorMessage',IsNull,False,False,'<?>');
     UIMsg  := TranslateServerError(RCode);
     If UIMsg='' then UIMsg := RMsg;
     RMsg   := Format(
      'Server error! Code: %d, LogID: %d, Class: "%s", Reason: "%s"',
      [RCode,RLogID,RClass,RMsg]);
     Raise EUploadError.Create(RMsg,RCode,UIMsg);
    End;

   // Upload successfull
   // Are there more files to upload?
   If FCurFileNo<FSelectedFiles.Length then
    Begin
     // Yes. Upload next file

     // Retrieve returned file group id
     FileGroup := JO.GetAsInteger('FileGroup',IsNull,True,True);

     FUploadedSoFar := FUploadedSoFar + TJSHTMLFile(FSelectedFiles[FCurFileNo-1]).Size;

     Inc(FCurFileNo);
     FUp := TFileUpload.Create;
     FUp.FileObject := TJSHTMLFile((FSelectedFiles[FCurFileNo-1]));
     FUp.URL := REST_API_URL;
     FUp.Params['Function'] := 'Upload';
     FUp.Params['AccessToken'] := LDat.AccessToken;
     FUp.Params['RefYear'] := IntToStr(FFilterYear);
     FUp.Params['Title'] := EdDocTitel.Text;
     FUp.Params['SubjArea'] := IntToStr(FFilterSubID);
     FUp.Params['FileNo'] := IntToStr(FCurFileNo);
     FUp.Params['FileCount'] := IntToStr(FSelectedFiles.Length);
     FUp.Params['FileGroup'] := IntToStr(FileGroup);
     FUp.OnComplete := Self.OnFileUploadComplete;
     FUp.OnProgress := Self.OnFileUploadProgress;
     FUp.Upload;
    End
   Else
    Begin
     // No. Empty list of selected files
     MainForm.Throbber.Hide;

     PBarUpload.Position := 100;
     LabProgress.Caption :=
      FloatToStrF(FTotalUploadSize,ffNumber,12,0) + ' / ' +
      FloatToStrF(FTotalUploadSize,ffNumber,12,0);

     FSelectedFiles := TJSArray.new;
     FCurFileNo := 0;
     MessageDlgEx(AppCaption,'Hochladen erfolgreich',mtInformation,[],
      Procedure (AValue: TModalResult)
       Begin
        Clear;
       End).Show;
    End;
  Except
   On EX:EUploadError do
    Begin
     MainForm.Throbber.Hide;
     Console.error(Err(EX,'TUploadForm.OnFileUploadComplete'));
     MessageDlgEx(AppCaption,
      Format('Fehler Nr. %d, Ursache: %s',[EX.ErrorCode,EX.UIMessage]),mtError,[],
      Procedure (AValue: TModalResult)
       Begin
        Clear;
       End).Show;
    End;
   Else
    Begin
     MainForm.Throbber.Hide;
     {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
     Console.error(Err(e,'TUploadForm.OnFileUploadComplete','','','Antwort-Behandlungsfehler'));
     Clear;
    End;
  End;
 Finally
  Console.log(TrOut('TUploadForm.OnFileUploadComplete'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.RefreshAccessToken(JV : JSValue);
Begin
 Console.log(TrIn('TUploadForm.RefreshAccessToken'));
 Try
  MainForm.GetLoginCredentials(
   Procedure (JV : JSValue)
   Begin
    SwitchToForm(UploadForm);
    Clear;
   End);
 Finally
  Console.log(TrOut('TUploadForm.RefreshAccessToken'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.OnFileUploadProgress(Sender: TFileUpload; Uploaded,TotalSize: NativeInt);
Var N : Int64;
Begin
 If TotalSize>0 then
  Begin
   Formatsettings.ThousandSeparator := '.';
   N := Uploaded + FUploadedSoFar;
   If N>FTotalUploadSize then FTotalUploadSize := N;
   PBarUpload.Position := Round(N/FTotalUploadSize*100);
   LabProgress.Caption :=
     FloatToStrF(N,ffNumber,12,0) + ' / ' +
     FloatToStrF(FTotalUploadSize,ffNumber,12,0)
  End;
End;

{---------------------------------------}

Procedure TUploadForm.BtnMenu2Click(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TUploadForm.BtnMenu2Click'));
 Try
  Try
   MenuForm.PreviousForm := ActiveForm;
   SwitchToForm(MenuForm);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TUploadForm.BtnMenu2Click'));
  End;
 Finally
  Console.log(TrOut('TUploadForm.BtnMenu2Click'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.FPicker1Change(Sender: TObject);
Var
 e      : Exception;
 FN,Ext : String;
Begin
 Console.log(TrIn('TUploadForm.FPicker1Change'));
 Try
  Try
   // Clear old list of files
   FSelectedFiles := TJSArray.new;
   FCurFileNo := -1;

   LabProgress.Caption := '---/---';
   BtnUpload.Enabled := False;
   BtnUpload.Visible := False;
   DivPBar.Visible := False;
   ImgPickedFile.Visible := False;
   DivAddFileToUpload.Visible := False;

   Console.Log(Lg('TUploadForm.FPicker1Change',['Nr. files picked ',FPicker1.Files.Count]));
   If FPicker1.Files.Count<1 then
    Begin
     Console.Log(Lg('TUploadForm.FPicker1Change',['Nothing to do']));
     Exit;
    End;
   If FPicker1.Files.Count>1 then
    Begin
     MessageDlgEx(AppCaption,'Mehrfach-Dateiauswahl nicht unterstützt!',mtConfirmation).Show();
     Exit;
    End;

   FN := FPicker1.Files[0].Name;
   Console.Log(Lg('TUploadForm.FPicker1Change',['Filename is: ',FN]));

   Ext := UpperCase(ExtractFileExt(FN));
   Console.Log(Lg('TUploadForm.FPicker1Change',['Extension is: ',Ext]));

   If FPicker1.Files[0].Size>MaxUploadFileSize then
    Begin
     MessageDlgEx(AppCaption,'Datei zu groß. Max. zulässig: ' +
      FloatToStrF(MaxUploadFileSize/1024/1024,ffNumber,10,1) + ' MB',
      mtConfirmation).Show();
     Exit;
    End;

   If (Ext='.JPG') or (Ext='.JPEG') or (Ext='.PNG') or (Ext='.BMP') then
    Begin
     Console.Log(Lg('TUploadForm.FPicker1Change',['Loading graphic file into image.']));
     FPicker1.Files[0].GetFileAsBase64;
     BtnUpload.Enabled := True;
     BtnUpload.Visible := True;
    End
   Else if (Ext='.PDF') then
    Begin
     Console.Log(Lg('TUploadForm.FPicker1Change',['Showing PDF icon.']));
     ImgPickedFile.URL := 'res\PDFIcon.png';
     BtnUpload.Enabled := True;
     BtnUpload.Visible := True;
    End
   Else
    Begin
     MessageDlgEx(AppCaption,'Nicht unterstütztes Dateiformat!',mtInformation).Show();
     Console.Log(Lg('TUploadForm.FPicker1Change',['No valid extension, aborting...']));
     FPicker1.Files.Clear;
     ImgPickedFile.URL := '';
     BtnUpload.Enabled := False;
     BtnUpload.Visible := False;
     Abort;
    End;

   // Set this file as first in list
   FSelectedFiles.push(FPicker1.Files[0].FileObject);

   DivPBar.Visible := False;
   ImgPickedFile.Visible := True;

   SetNrFilesSelected(1);
   DivAddFileToUpload.Visible := True;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TUploadForm.FPicker1Change'));
   Abort;
  End;
 Finally
  Console.log(TrOut('TUploadForm.FPicker1Change'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.FPicker1GetFileAsBase64(Sender: TObject;
  AFileIndex: Integer; ABase64: string);
Begin
 Console.log(TrIn('TUploadForm.FPicker1GetFileAsBase64'));
 Try
  ImgPickedFile.URL := 'data:image/jpg;base64,'+ABase64;
  Console.Log(Lg('TUploadForm.FPicker1GetFileAsBase64',['URL set is: ',TrimStringMaxLen(ImgPickedFile.URL,200)]));
 Finally
  Console.log(TrOut('TUploadForm.FPicker1GetFileAsBase64'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.FPicker2Change(Sender: TObject);
Var
 e : Exception;
 FN,Ext : String;
Begin
 Console.log(TrIn('TUploadForm.FPicker2Change'));
 Try
  Try

   Console.Log(Lg('TUploadForm.FPicker2Change',['Nr. files picked ',FPicker2.Files.Count]));
   If FPicker2.Files.Count<1 then
    Begin
     Console.Log(Lg('TUploadForm.FPicker2Change',['Nothing to do']));
     Exit;
    End;
   If FPicker2.Files.Count>1 then
    Begin
     MessageDlgEx(AppCaption,'Mehrfach-Dateiauswahl nicht unterstützt!',mtConfirmation).Show();
     Exit;
    End;

   FN := FPicker2.Files[0].Name;
   Console.Log(Lg('TUploadForm.FPicker2Change',['Filename is: ',FN]));

   Ext := UpperCase(ExtractFileExt(FN));
   Console.Log(Lg('TUploadForm.FPicker2Change',['Extension is: ',Ext]));

   If FPicker2.Files[0].Size>MaxUploadFileSize then
    Begin
     MessageDlgEx(AppCaption,'Datei zu groß. Max. zulässig: ' +
      FloatToStrF(MaxUploadFileSize/1024/1024,ffNumber,10,1) + ' MB',
      mtConfirmation).Show();
     Exit;
    End;

   If (Ext='.JPG') or (Ext='.JPEG') or (Ext='.PNG') or (Ext='.BMP') then
    Begin
     Console.Log(Lg('TUploadForm.FPicker2Change',['Loading graphic file into image.']));
     FPicker2.Files[0].GetFileAsBase64;
     BtnUpload.Enabled := True;
     BtnUpload.Visible := True;
    End
   Else if (Ext='.PDF') then
    Begin
     Console.Log(Lg('TUploadForm.FPicker2Change',['Showing PDF icon.']));
     ImgPickedFile.URL := 'res\PDFIcon.png';
     BtnUpload.Enabled := True;
     BtnUpload.Visible := True;
    End
   Else
    Begin
     MessageDlgEx(AppCaption,'Nicht unterstütztes Dateiformat!',mtInformation).Show();
     Console.Log(Lg('TUploadForm.FPicker2Change',['No valid extension, aborting...']));
     FPicker2.Files.Clear;
     ImgPickedFile.URL := '';
     BtnUpload.Enabled := False;
     BtnUpload.Visible := False;
     Abort;
    End;

   // Add file to list
   FSelectedFiles.push(FPicker2.Files[0].FileObject);
   SetNrFilesSelected(FSelectedFiles.Length);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TUploadForm.FPicker2Change'));
   Abort;
  End;
 Finally
  Console.log(TrOut('TUploadForm.FPicker2Change'));
 End;
End;

{---------------------------------------}

Procedure TUploadForm.FPicker2GetFileAsBase64(Sender: TObject;
  AFileIndex: Integer; ABase64: string);
Begin
 Console.log(TrIn('TUploadForm.FPicker2GetFileAsBase64'));
 Try
  ImgPickedFile.URL := 'data:image/jpg;base64,'+ABase64;
  Console.Log(Lg('TUploadForm.FPicker2GetFileAsBase64',['URL set is: ',TrimStringMaxLen(ImgPickedFile.URL,200)]));
 Finally
  Console.log(TrOut('TUploadForm.FPicker2GetFileAsBase64'));
 End;
End;

{---------------------------------------}

procedure TUploadForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ImgPickedFile := TImageControl.Create('ImgPickedFile');
  CbxSalesAreas := TComboBox.Create('CbxSalesAreas2');
  BtnYear1 := TButton.Create('BtnButtonYear21');
  BtnYear2 := TButton.Create('BtnButtonYear22');
  BtnYear3 := TButton.Create('BtnButtonYear23');
  BtnYear4 := TButton.Create('BtnButtonYear24');
  BtnUpload := TButton.Create('BtnUpload');
  BtnMenu2 := TButton.Create('BtnMenu2');
  EdDocTitel := TEdit.Create('EdDocTitel2');
  FPicker1 := TFilePicker.Create('FPicker1');
  BtnBackToDocs := TButton.Create('BtnBackToDocs');
  DivPBar := THTMLDiv.Create('DivPBar');
  LabProgress := TLabel.Create('LabProgress');
  PBarUpload := TProgressBar.Create('PBarUpload');
  DivAddFileToUpload := THTMLDiv.Create('DivAddFileToUpload');
  FPicker2 := TFilePicker.Create('FPicker2');
  LabFPicker2 := THTMLDiv.Create('LabFPicker2');

  ImgPickedFile.BeforeLoadDFMValues;
  CbxSalesAreas.BeforeLoadDFMValues;
  BtnYear1.BeforeLoadDFMValues;
  BtnYear2.BeforeLoadDFMValues;
  BtnYear3.BeforeLoadDFMValues;
  BtnYear4.BeforeLoadDFMValues;
  BtnUpload.BeforeLoadDFMValues;
  BtnMenu2.BeforeLoadDFMValues;
  EdDocTitel.BeforeLoadDFMValues;
  FPicker1.BeforeLoadDFMValues;
  BtnBackToDocs.BeforeLoadDFMValues;
  DivPBar.BeforeLoadDFMValues;
  LabProgress.BeforeLoadDFMValues;
  PBarUpload.BeforeLoadDFMValues;
  DivAddFileToUpload.BeforeLoadDFMValues;
  FPicker2.BeforeLoadDFMValues;
  LabFPicker2.BeforeLoadDFMValues;
  try
    Name := 'UploadForm';
    Width := 640;
    Height := 582;
    ElementClassName := 'Foo';
    Visible := True;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    ImgPickedFile.SetParentComponent(Self);
    ImgPickedFile.Name := 'ImgPickedFile';
    ImgPickedFile.Left := 21;
    ImgPickedFile.Top := 390;
    ImgPickedFile.Width := 426;
    ImgPickedFile.Height := 141;
    ImgPickedFile.HeightPercent := 100.000000000000000000;
    ImgPickedFile.WidthPercent := 100.000000000000000000;
    ImgPickedFile.ChildOrder := 9;
    CbxSalesAreas.SetParentComponent(Self);
    CbxSalesAreas.Name := 'CbxSalesAreas';
    CbxSalesAreas.Left := 21;
    CbxSalesAreas.Top := 100;
    CbxSalesAreas.Width := 426;
    CbxSalesAreas.Height := 21;
    CbxSalesAreas.ElementPosition := epIgnore;
    CbxSalesAreas.HeightStyle := ssAuto;
    CbxSalesAreas.HeightPercent := 100.000000000000000000;
    CbxSalesAreas.WidthStyle := ssPercent;
    CbxSalesAreas.WidthPercent := 50.000000000000000000;
    SetEvent(CbxSalesAreas, Self, 'OnChange', 'CbxSalesAreasChange');
    CbxSalesAreas.ItemIndex := -1;
    BtnYear1.SetParentComponent(Self);
    BtnYear1.Name := 'BtnYear1';
    BtnYear1.Left := 21;
    BtnYear1.Top := 60;
    BtnYear1.Width := 96;
    BtnYear1.Height := 25;
    BtnYear1.Caption := '2018';
    BtnYear1.ChildOrder := 8;
    BtnYear1.HeightPercent := 100.000000000000000000;
    BtnYear1.WidthPercent := 100.000000000000000000;
    SetEvent(BtnYear1, Self, 'OnClick', 'BtnYear1Click');
    BtnYear2.SetParentComponent(Self);
    BtnYear2.Name := 'BtnYear2';
    BtnYear2.Left := 131;
    BtnYear2.Top := 60;
    BtnYear2.Width := 96;
    BtnYear2.Height := 25;
    BtnYear2.Caption := '2019';
    BtnYear2.ChildOrder := 1;
    BtnYear2.HeightPercent := 100.000000000000000000;
    BtnYear2.WidthPercent := 100.000000000000000000;
    SetEvent(BtnYear2, Self, 'OnClick', 'BtnYear1Click');
    BtnYear3.SetParentComponent(Self);
    BtnYear3.Name := 'BtnYear3';
    BtnYear3.Left := 239;
    BtnYear3.Top := 60;
    BtnYear3.Width := 96;
    BtnYear3.Height := 25;
    BtnYear3.Caption := '2020';
    BtnYear3.ChildOrder := 2;
    BtnYear3.HeightPercent := 100.000000000000000000;
    BtnYear3.WidthPercent := 100.000000000000000000;
    SetEvent(BtnYear3, Self, 'OnClick', 'BtnYear1Click');
    BtnYear4.SetParentComponent(Self);
    BtnYear4.Name := 'BtnYear4';
    BtnYear4.Left := 351;
    BtnYear4.Top := 60;
    BtnYear4.Width := 96;
    BtnYear4.Height := 25;
    BtnYear4.Caption := '2021';
    BtnYear4.ChildOrder := 3;
    BtnYear4.HeightPercent := 100.000000000000000000;
    BtnYear4.WidthPercent := 100.000000000000000000;
    SetEvent(BtnYear4, Self, 'OnClick', 'BtnYear1Click');
    BtnUpload.SetParentComponent(Self);
    BtnUpload.Name := 'BtnUpload';
    BtnUpload.Left := 20;
    BtnUpload.Top := 550;
    BtnUpload.Width := 427;
    BtnUpload.Height := 25;
    BtnUpload.Caption := 'BtnUpload';
    BtnUpload.ChildOrder := 6;
    BtnUpload.ElementFont := efCSS;
    BtnUpload.ElementPosition := epIgnore;
    BtnUpload.HeightPercent := 100.000000000000000000;
    BtnUpload.Visible := False;
    BtnUpload.WidthPercent := 100.000000000000000000;
    SetEvent(BtnUpload, Self, 'OnClick', 'BtnUploadClick');
    BtnMenu2.SetParentComponent(Self);
    BtnMenu2.Name := 'BtnMenu2';
    BtnMenu2.Left := 351;
    BtnMenu2.Top := 15;
    BtnMenu2.Width := 96;
    BtnMenu2.Height := 25;
    BtnMenu2.Caption := 'BtnMenu2';
    BtnMenu2.ChildOrder := 6;
    BtnMenu2.ElementFont := efCSS;
    BtnMenu2.ElementPosition := epIgnore;
    BtnMenu2.HeightPercent := 100.000000000000000000;
    BtnMenu2.WidthPercent := 100.000000000000000000;
    SetEvent(BtnMenu2, Self, 'OnClick', 'BtnMenu2Click');
    EdDocTitel.SetParentComponent(Self);
    EdDocTitel.Name := 'EdDocTitel';
    EdDocTitel.Left := 20;
    EdDocTitel.Top := 135;
    EdDocTitel.Width := 427;
    EdDocTitel.Height := 22;
    EdDocTitel.ChildOrder := 5;
    EdDocTitel.ElementFont := efCSS;
    EdDocTitel.ElementPosition := epIgnore;
    EdDocTitel.HeightPercent := 100.000000000000000000;
    EdDocTitel.WidthPercent := 100.000000000000000000;
    FPicker1.SetParentComponent(Self);
    FPicker1.Name := 'FPicker1';
    FPicker1.Left := 20;
    FPicker1.Top := 175;
    FPicker1.Width := 427;
    FPicker1.Height := 26;
    FPicker1.HeightPercent := 100.000000000000000000;
    FPicker1.WidthPercent := 100.000000000000000000;
    FPicker1.Accept := '.pdf,.jpg,.png,.bmp';
    FPicker1.ChildOrder := 12;
    FPicker1.Visible := False;
    SetEvent(FPicker1, Self, 'OnChange', 'FPicker1Change');
    SetEvent(FPicker1, Self, 'OnGetFileAsBase64', 'FPicker1GetFileAsBase64');
    BtnBackToDocs.SetParentComponent(Self);
    BtnBackToDocs.Name := 'BtnBackToDocs';
    BtnBackToDocs.Left := 249;
    BtnBackToDocs.Top := 15;
    BtnBackToDocs.Width := 96;
    BtnBackToDocs.Height := 25;
    BtnBackToDocs.Caption := 'BtnBackToDocs';
    BtnBackToDocs.ChildOrder := 6;
    BtnBackToDocs.ElementFont := efCSS;
    BtnBackToDocs.ElementPosition := epIgnore;
    BtnBackToDocs.HeightPercent := 100.000000000000000000;
    BtnBackToDocs.WidthPercent := 100.000000000000000000;
    SetEvent(BtnBackToDocs, Self, 'OnClick', 'BtnBackToDocsClick');
    DivPBar.SetParentComponent(Self);
    DivPBar.Name := 'DivPBar';
    DivPBar.Left := 21;
    DivPBar.Top := 300;
    DivPBar.Width := 426;
    DivPBar.Height := 71;
    DivPBar.HeightPercent := 100.000000000000000000;
    DivPBar.WidthPercent := 100.000000000000000000;
    DivPBar.ChildOrder := 13;
    DivPBar.ElementPosition := epIgnore;
    DivPBar.Role := '';
    DivPBar.Visible := False;
    LabProgress.SetParentComponent(DivPBar);
    LabProgress.Name := 'LabProgress';
    LabProgress.Left := 20;
    LabProgress.Top := 48;
    LabProgress.Width := 90;
    LabProgress.Height := 13;
    LabProgress.Caption := '999.999 / 999.999';
    LabProgress.ElementPosition := epIgnore;
    LabProgress.HeightPercent := 100.000000000000000000;
    LabProgress.WidthPercent := 100.000000000000000000;
    PBarUpload.SetParentComponent(DivPBar);
    PBarUpload.Name := 'PBarUpload';
    PBarUpload.Left := 20;
    PBarUpload.Top := 10;
    PBarUpload.Width := 384;
    PBarUpload.Height := 24;
    PBarUpload.HeightPercent := 100.000000000000000000;
    PBarUpload.WidthPercent := 100.000000000000000000;
    PBarUpload.ChildOrder := 2;
    PBarUpload.ElementPosition := epIgnore;
    DivAddFileToUpload.SetParentComponent(Self);
    DivAddFileToUpload.Name := 'DivAddFileToUpload';
    DivAddFileToUpload.Left := 20;
    DivAddFileToUpload.Top := 207;
    DivAddFileToUpload.Width := 427;
    DivAddFileToUpload.Height := 76;
    DivAddFileToUpload.HeightPercent := 100.000000000000000000;
    DivAddFileToUpload.WidthPercent := 100.000000000000000000;
    DivAddFileToUpload.ChildOrder := 13;
    DivAddFileToUpload.Role := '';
    FPicker2.SetParentComponent(Self);
    FPicker2.Name := 'FPicker2';
    FPicker2.Left := 137;
    FPicker2.Top := 232;
    FPicker2.Width := 299;
    FPicker2.Height := 26;
    FPicker2.HeightPercent := 100.000000000000000000;
    FPicker2.WidthPercent := 100.000000000000000000;
    FPicker2.Accept := '.pdf,.jpg,.png,.bmp';
    FPicker2.ChildOrder := 12;
    FPicker2.Visible := False;
    SetEvent(FPicker2, Self, 'OnChange', 'FPicker2Change');
    SetEvent(FPicker2, Self, 'OnGetFileAsBase64', 'FPicker2GetFileAsBase64');
    LabFPicker2.SetParentComponent(Self);
    LabFPicker2.Name := 'LabFPicker2';
    LabFPicker2.Left := 31;
    LabFPicker2.Top := 232;
    LabFPicker2.Width := 100;
    LabFPicker2.Height := 26;
    LabFPicker2.HeightPercent := 100.000000000000000000;
    LabFPicker2.WidthPercent := 100.000000000000000000;
    LabFPicker2.ChildOrder := 14;
    LabFPicker2.Role := '';
  finally
    ImgPickedFile.AfterLoadDFMValues;
    CbxSalesAreas.AfterLoadDFMValues;
    BtnYear1.AfterLoadDFMValues;
    BtnYear2.AfterLoadDFMValues;
    BtnYear3.AfterLoadDFMValues;
    BtnYear4.AfterLoadDFMValues;
    BtnUpload.AfterLoadDFMValues;
    BtnMenu2.AfterLoadDFMValues;
    EdDocTitel.AfterLoadDFMValues;
    FPicker1.AfterLoadDFMValues;
    BtnBackToDocs.AfterLoadDFMValues;
    DivPBar.AfterLoadDFMValues;
    LabProgress.AfterLoadDFMValues;
    PBarUpload.AfterLoadDFMValues;
    DivAddFileToUpload.AfterLoadDFMValues;
    FPicker2.AfterLoadDFMValues;
    LabFPicker2.AfterLoadDFMValues;
  end;
end;

End.
