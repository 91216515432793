Unit ServerErrors;

Interface

Function TranslateServerError(ErrorCode : Integer) : String;

{##############################################################################}

Implementation

Function TranslateServerError(ErrorCode : Integer) : String;
Begin
 Case ErrorCode of
    110: Result := 'Datenbank-Anmeldefehler';
    120: Result := 'Interner Serverfehler. Funktion hat kein Ergebnis geliefert';

   1000: Result := 'Ungültige Anfrage! Keine oder ungültige Parameter angegeben';
   1010: Result := 'Ungültige Anfrage! Keine Funktion angegeben.';
   1020: Result := 'Ungültige Anfrage! Kein Zugriffs-Token angegeben. Bitte anmelden, um ein Zugriffs-Token zu erhalten.';
   1030: Result := 'Ungültige Anfrage! Benutzername fehlt für die Anmeldung.';
   1040: Result := 'Ungültige Anfrage! Passwort fehlt für die Anmeldung.';
   1050: Result := 'Die angeforderte Funktion ist nicht implementiert.';
   1070: Result := 'Ungültige Anfrage! Access-Token ungültig oder abgelaufen. Bitte anmelden, um ein aktualisiertes Zugriffs-Token zu erhalten.';
   1080: Result := 'Ungültige Anfrage! Funktion "GetUplTempFiles" benötigt Parameter "FilterYear", "FilterText" and "FilterSubjAreaID".';
   1090: Result := 'Ungültige Anfrage! Funktion "Upload" benötigt Parameter "RefYear", "Title" and "SubjArea".';
   1100: Result := 'Ungültige Anfrage! Funktion "RequestDownload" benötigt Parameter "TempFileID".';

   2010: Result := 'Anmeldung fehlgeschlagen! Benutzername oder Passwort falsch oder unbekannt.';
   2020: Result := 'Anmeldung fehlgeschlagen! Das Benutzerkonto ist inaktiv.';
   2030: Result := 'Anmeldung fehlgeschlagen! Das Konto ist zum Missbrauchsschutz vorübergehend gesperrt. Versuchen Sie es später noch einmal.';
   2040: Result := 'Anmeldung fehlgeschlagen! Das Konto des Benutzers wurde geschlossen.';
   2050: Result := 'Anmeldung fehlgeschlagen! Das Konto des Benutzers wurde nicht gefunden. Bitte erneut anmelden.';
   2055: Result := 'Anmeldung fehlgeschlagen! Diese App ist für Kunden gedacht, nicht für Mandanten.';
   2060: Result := 'Zugriffsschlüssel abgelaufen. Bitte erneut anmelden.';

   3000: Result := 'Keine Datei empfangen oder Datei zu groß.';
   3010: Result := 'Die empfangene Datei ist ohne Inhalt.';
   3020: Result := 'Der Upload schlug fehl.';
   3030: Result := 'Datei mit angegebener ID nicht gefunden.';
   3040: Result := 'Die angefragte Datei ist nicht im Besitz des angemeldeten Benutzers.';
   3050: Result := 'Die Daten der Datei konnten im Datenspeicher nicht gefunden werden.';
   3060: Result := 'Ausgabeverzeichnis für Download auf Server nicht gefunden.';
   3070: Result := 'Die hochgeladene Datei hat einen nicht unterstützten Dateityp.';
   3080: Result := 'Automatische Dateikonvertierung nach PDF ist fehlgeschlagen.';
   3090: Result := 'Ausgabeverzeichnis für Mandanten-Icons auf Server nicht gefunden.';

  Else Result := '';
 End;
End;


End.
