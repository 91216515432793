Unit LocalStorage;

Interface

Uses JS,Tools;

Type
 TLocalData = Record
  Private
   FProps    : TJSObject;
   FName     : String;
   FUpdating : Boolean;
   Function GetIntProp(Const PropName : String) : Integer;
   Procedure SetIntProp(Const PropName : String; Value : Integer);
   Function GetFloatProp(Const PropName : String) : Double;
   Procedure SetFloatProp(Const PropName : String; Const Value : Double);
   Function GetStringProp(Const PropName : String) : String;
   Procedure SetStringProp(Const PropName : String; Const Value : String);
   Function GetBooleanProp(Const PropName : String) : Boolean;
   Procedure SetBooleanProp(Const PropName : String; Const Value : Boolean);
   Function GetArrayProp(Const PropName : String) : TJSArray;
   Procedure SetArrayProp(Const PropName : String; Const Value : TJSArray);
   Procedure StoreProps;
  Public
   Procedure Clear;
   Function PropExists(const PropName: String): Boolean;
   Procedure BeginUpdate;
   Procedure EndUpdate;
   Property Updating : Boolean read FUpdating;
   { ---------------- }
   Property UserName          : String   index 'UserName'          read GetStringProp  write SetStringProp;
   Property LastName          : String   index 'LastName'          read GetStringProp  write SetStringProp;
   Property MiddleName        : String   index 'MiddleName'        read GetStringProp  write SetStringProp;
   Property FirstName         : String   index 'FirstName'         read GetStringProp  write SetStringProp;
   Property Salutation        : String   index 'Salutation'        read GetStringProp  write SetStringProp;
   Property UserID            : Integer  index 'UserID'            read GetIntProp     write SetIntProp;
   Property MandantID         : String   index 'MandantID'         read GetStringProp  write SetStringProp;
   Property MandantHasIcon    : Boolean  index 'MandantHasIcon'    read GetBooleanProp write SetBooleanProp;
   Property IconFileName      : String   index 'IconFileName'      read GetStringProp  write SetStringProp;
   Property AccessToken       : String   index 'AccessToken'       read GetStringProp  write SetStringProp;
   Property DocFilterYear     : Integer  index 'DocFilterYear'     read GetIntProp     write SetIntProp;
   Property DocFilterText     : String   index 'DocFilterText'     read GetStringProp  write SetStringProp;
   Property DocFilterSubID    : Integer  index 'DocFilterSubID'    read GetIntProp     write SetIntProp;
   Property DocList           : TJSArray index 'DocList'           read GetArrayProp   write SetArrayProp;
   Property SubjAreasList     : TJSArray index 'SubjAreasList'     read GetArrayProp   write SetArrayProp;
 End;

Procedure LoadLocalData(AndThen : TThenProc);
{ Reading of encrypted local storage. The data in the local storage is an
  encrypted (if enabled, browser dependent) JSON string. The string gets
  decrypted and the JSON data will be loaded into global data object LDat.
  On error, an empty LDAT record will be created. As the decryption is async,
  the procedure returns immediately and AndThen has to be provided to continue
  with linear processing. }

{##############################################################################}

Implementation

Uses
 Web,
 SysUtils,
 WEBLib.Dialogs,
 StrUtils,
 JSON,
 
Logger,
 DMMain;

{ TLocalData }

Procedure TLocalData.BeginUpdate;
Begin
 FUpdating := True;
End;

{---------------------------------------}

Procedure TLocalData.EndUpdate;
Begin
 If FUpdating then
  Begin
    FUpdating := False;
    StoreProps;
  End;
End;

{---------------------------------------}

Procedure TLocalData.Clear;
Begin
 FProps := TJSObject.new;
 StoreProps;
End;

{---------------------------------------}

Procedure TLocalData.StoreProps;
Begin
 Encrypt(GetPreventExtJSObjectPropertyDescriptor,FProps.ToJSONString,
  Procedure (Const Result : String; Failed : Boolean)
  Begin
   If Failed then ShowMessage('Error 9601')
   Else Window.localStorage.setItem(ReverseString(FName),Result);
  End)
End;

{---------------------------------------}

Function TLocalData.PropExists(Const PropName: String) : Boolean;
Begin
 If not assigned(FProps) then
  FProps := TJSObject.new;
 Result := FProps.PropertyExists(PropName);
End;

{---------------------------------------}

Function TLocalData.GetStringProp(const PropName: String): String;
Begin
 Result := String(FProps[PropName]);
End;

{---------------------------------------}

Procedure TLocalData.SetStringProp(const PropName, Value: String);
Begin
 FProps[PropName] := Value;
 If not FUpdating then
  StoreProps;
End;

{---------------------------------------}

Function TLocalData.GetBooleanProp(Const PropName: String): Boolean;
Begin
 Result := StrToBool(GetStringProp(PropName));
End;

{---------------------------------------}

Procedure TLocalData.SetBooleanProp(Const PropName: String; const Value: Boolean);
Begin
 SetStringProp(Propname,BoolToStr(Value,True));
End;

{---------------------------------------}

Function TLocalData.GetFloatProp(Const PropName : String) : Double;
Begin
 Result := StrToFloat(GetStringProp(PropName));
End;

{---------------------------------------}

Procedure TLocalData.SetFloatProp(Const PropName : String; Const Value : Double);
Begin
 SetStringProp(Propname,FloatToStr(Value));
End;

{---------------------------------------}

Function TLocalData.GetIntProp(Const PropName : String) : Integer;
Begin
 Result := StrToInt(GetStringProp(PropName));
End;

{---------------------------------------}

Procedure TLocalData.SetIntProp(Const PropName : String; Value : Integer);
Begin
 SetStringProp(Propname,IntToStr(Value));
End;

{---------------------------------------}

Function TLocalData.GetArrayProp(Const PropName : String) : TJSArray;
Begin
 Result := TJSArray(FProps[PropName]);
End;

{---------------------------------------}

Procedure TLocalData.SetArrayProp(Const PropName : String; Const Value : TJSArray);
Begin
 FProps[PropName] := Value;
 If not FUpdating then
  StoreProps;
End;

{---------------------------------------}

Procedure LoadLocalData(AndThen : TThenProc);
Begin
 If not assigned(AndThen) then raise Exception.Create('AndThen missing');
 Decrypt(GetPreventExtJSObjectPropertyDescriptor,
  Window.localStorage.getItem(ReverseString('982M743B2E2C16A9')),
  Procedure (Const Result : String; Failed : Boolean)
   Begin
    If Failed then LDat.FProps := TJSObject.new
    Else LDat.FProps := TJSObject(CreateJSONObjectFromString(Result));
    LDat.FName := '982M743B2E2C16A9';
    AndThen;
   End);
End;

End.
