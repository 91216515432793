Unit FormLogin;

Interface

Uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, 
WEBLib.ExtCtrls, Tools;

Type
  TLoginForm = class(TForm)
    EdUserName: TEdit;
    EdPassword: TEdit;
    BtnLogin: TButton;
    Procedure BtnLoginClick(Sender: TObject);
   Private
   Public
    OnAfterLogin : TThenProc;
  protected procedure LoadDFMValues; override; End;

Var
 LoginForm: TLoginForm;

{##############################################################################}

Implementation

{$R *.dfm}

Uses DateUtils, JSFuncs, Logger, DMMain, FormMain, FormDoclist;

{
<input type="TEXT" id="EdUser" zindex="0" tabindex="1" role="textbox" autocomplete="on" name="username" class="formfeld_l">
<input type="PASSWORD" id="EdPassword" zindex="0" tabindex="1" role="textbox" autocomplete="on" name="current-password" class="formfeld_l">
}

Procedure TLoginForm.BtnLoginClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TLoginForm.BtnLoginClick'));
 Try
  Try
   If EdUserName.Text='' then
    Begin
     MessageDlg(
     'Bitte geben Sie Ihren Benutzernamen ein',
     mtConfirmation,[mbOk]);
     Exit;
    End;

   If EdPassword.Text='' then
    Begin
     MessageDlg(
     'Bitte geben Sie Ihr Passwort ein',
     mtConfirmation,[mbOk]);
     Exit;
    End;

   LDat.UserName := EdUserName.Text;
   Try
    DM.RESTLogin(EdUserName.Text,EdPassword.Text,Procedure (JV : JSValue)
     Begin
      Self.Visible := False;
      If assigned(OnAfterLogin) then
       OnAfterLogin(Nil);
     End);
   Except
    {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
    Console.error(Err(e,'TLoginForm.BtnLoginClick','','','Anmeldefehler'));
   End;

  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TLoginForm.BtnLoginClick'));
  End;
 Finally
  Console.log(TrOut('TLoginForm.BtnLoginClick'));
 End;
End;

{---------------------------------------}

procedure TLoginForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BtnLogin := TButton.Create('BtnLogin');
  EdPassword := TEdit.Create('EdPassword');
  EdUserName := TEdit.Create('EdUser');

  BtnLogin.BeforeLoadDFMValues;
  EdPassword.BeforeLoadDFMValues;
  EdUserName.BeforeLoadDFMValues;
  try
    Name := 'LoginForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'Foo';
    Visible := True;
    BtnLogin.SetParentComponent(Self);
    BtnLogin.Name := 'BtnLogin';
    BtnLogin.Left := 17;
    BtnLogin.Top := 90;
    BtnLogin.Width := 96;
    BtnLogin.Height := 25;
    BtnLogin.Caption := 'BtnLogin';
    BtnLogin.ChildOrder := 2;
    BtnLogin.ElementFont := efCSS;
    BtnLogin.ElementPosition := epIgnore;
    BtnLogin.HeightPercent := 100.000000000000000000;
    BtnLogin.WidthPercent := 100.000000000000000000;
    SetEvent(BtnLogin, Self, 'OnClick', 'BtnLoginClick');
    EdPassword.SetParentComponent(Self);
    EdPassword.Name := 'EdPassword';
    EdPassword.Left := 17;
    EdPassword.Top := 45;
    EdPassword.Width := 96;
    EdPassword.Height := 25;
    EdPassword.AutoCompletion := acCurrentPassword;
    EdPassword.ChildOrder := 1;
    EdPassword.ElementPosition := epIgnore;
    EdPassword.HeightStyle := ssAuto;
    EdPassword.HeightPercent := 100.000000000000000000;
    EdPassword.PasswordChar := 'x';
    EdPassword.WidthStyle := ssAuto;
    EdPassword.WidthPercent := 100.000000000000000000;
    EdUserName.SetParentComponent(Self);
    EdUserName.Name := 'EdUserName';
    EdUserName.Left := 17;
    EdUserName.Top := 8;
    EdUserName.Width := 96;
    EdUserName.Height := 25;
    EdUserName.AutoCompletion := acUserName;
    EdUserName.ElementPosition := epIgnore;
    EdUserName.HeightStyle := ssAuto;
    EdUserName.HeightPercent := 100.000000000000000000;
    EdUserName.WidthStyle := ssAuto;
    EdUserName.WidthPercent := 100.000000000000000000;
  finally
    BtnLogin.AfterLoadDFMValues;
    EdPassword.AfterLoadDFMValues;
    EdUserName.AfterLoadDFMValues;
  end;
end;

End.
