Unit FormTerms;

Interface

Uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DMMain, WEBLib.StdCtrls, 
WEBLib.ExtCtrls, WEBLib.WebCtrls, Tools;

Type
  TTermsForm = class(TForm)
    BtnBackFromTerms: TButton;
    BtnBackFromTerms2: TButton;
    procedure BtnBackFromTermsClick(Sender: TObject);
   Private
   Public
    PreviousForm : TForm;
  protected procedure LoadDFMValues; override; End;

Var
 TermsForm : TTermsForm;

{#############################################################################}

Implementation

{$R *.dfm}

Uses DateUtils, JSFuncs, WEBLib.JSON, JSON, Logger, FormMain;

Procedure TTermsForm.BtnBackFromTermsClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TTermsForm.BtnBackFromTermsClick'));
 Try
  Try
   SwitchToForm(PreviousForm);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TTermsForm.BtnBackFromTermsClick'));
  End;
 Finally
  Console.log(TrOut('TTermsForm.BtnBackFromTermsClick'));
 End;
End;

{---------------------------------------}


procedure TTermsForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BtnBackFromTerms := TButton.Create('BtnBackFromTerms1');
  BtnBackFromTerms2 := TButton.Create('BtnBackFromTerms2');

  BtnBackFromTerms.BeforeLoadDFMValues;
  BtnBackFromTerms2.BeforeLoadDFMValues;
  try
    Name := 'TermsForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'Foo';
    Visible := True;
    BtnBackFromTerms.SetParentComponent(Self);
    BtnBackFromTerms.Name := 'BtnBackFromTerms';
    BtnBackFromTerms.Left := 16;
    BtnBackFromTerms.Top := 25;
    BtnBackFromTerms.Width := 130;
    BtnBackFromTerms.Height := 25;
    BtnBackFromTerms.Caption := 'BtnBackFromTerms';
    BtnBackFromTerms.ChildOrder := 2;
    BtnBackFromTerms.ElementFont := efCSS;
    BtnBackFromTerms.ElementPosition := epIgnore;
    BtnBackFromTerms.HeightPercent := 100.000000000000000000;
    BtnBackFromTerms.WidthPercent := 100.000000000000000000;
    SetEvent(BtnBackFromTerms, Self, 'OnClick', 'BtnBackFromTermsClick');
    BtnBackFromTerms2.SetParentComponent(Self);
    BtnBackFromTerms2.Name := 'BtnBackFromTerms2';
    BtnBackFromTerms2.Left := 16;
    BtnBackFromTerms2.Top := 56;
    BtnBackFromTerms2.Width := 130;
    BtnBackFromTerms2.Height := 25;
    BtnBackFromTerms2.Caption := 'BtnBackFromTerms';
    BtnBackFromTerms2.ChildOrder := 2;
    BtnBackFromTerms2.ElementFont := efCSS;
    BtnBackFromTerms2.ElementPosition := epIgnore;
    BtnBackFromTerms2.HeightPercent := 100.000000000000000000;
    BtnBackFromTerms2.WidthPercent := 100.000000000000000000;
    SetEvent(BtnBackFromTerms2, Self, 'OnClick', 'BtnBackFromTermsClick');
  finally
    BtnBackFromTerms.AfterLoadDFMValues;
    BtnBackFromTerms2.AfterLoadDFMValues;
  end;
end;

End.
