Program Steuercheckliste;

{$R *.dres}

uses
  WEBLib.Forms,
  
FormMain in 'FormMain.pas'{*.html},
  DMMain in 'DMMain.pas',
  ServerErrors in 'ServerErrors.pas',
  LocalStorage in 'LocalStorage.pas',
  JSFuncs in '..\..\..\Lib10.2\TMSWeb\JSFuncs.pas',
  JSON in '..\..\..\Lib10.2\TMSWeb\JSON.pas',
  Logger in '..\..\..\Lib10.2\TMSWeb\Logger.pas',
  Tools in '..\..\..\Lib10.2\TMSWeb\Tools.pas',
  Web.FileUpload in '..\..\..\Lib10.2\TMSWeb\Web.FileUpload.pas',
  FormLogin in 'FormLogin.pas'{*.html},
  FormDoclist in 'FormDoclist.pas'{*.html},
  FormUpload in 'FormUpload.pas'{*.html},
  FormImprint in 'FormImprint.pas'{*.html},
  FormTerms in 'FormTerms.pas'{*.html},
  FormDataProt in 'FormDataProt.pas'{*.html},
  FormShowPDF in 'FormShowPDF.pas'{*.html},
  FormCamera in 'FormCamera.pas'{*.html},
  FormMenu in 'FormMenu.pas'{*.html},
  CP.Func.AES in '..\..\..\Lib10.2\TMSWeb\CP.Func.AES.pas',
  Bcl.Utils in 'C:\Users\wweyers\Documents\tmssoftware\TMS WEB Core RSXE13\Core Source\XData\Bcl.Utils.pas';

{$R *.res}

Begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.ErrorType := aeSilent;
  Application.OnError := AppErrorDummy.OnAppError;
  Application.CreateForm(TMainForm, MainForm);
  Application.CreateForm(TDM, DM);
  Application.CreateForm(TLoginForm, LoginForm);
  Application.CreateForm(TDoclistForm, DoclistForm);
  Application.CreateForm(TUploadForm, UploadForm);
  Application.CreateForm(TImprintForm, ImprintForm);
  Application.CreateForm(TTermsForm, TermsForm);
  Application.CreateForm(TDataProtForm, DataProtForm);
  Application.CreateForm(TShowPDFForm, ShowPDFForm);
  Application.CreateForm(TCameraForm, CameraForm);
  Application.CreateForm(TMenuForm, MenuForm);
  Application.Run;
End.
