Unit Logger;

Interface

Uses
 JSDelphiSystem, SysUtils, Classes, Web, WEBLib.Dialogs;

Procedure SetCurrentUser(Const UserName : String; UserID : Integer);
{ For logging purposes, use SetCurrentUser() as soon as the user is known. }

Function Lg(Const Caller : String; Const Parms: Array of JSValue) : String;
Function TrIn(Const Caller : String; Const Parms : Array of JSValue = []) : String;
Function TrOut(Const Caller : String; Const Parms : Array of JSValue = []) : String;
{ These tracing and logging functions return formatted strings that usually
  are output to the console. Typical usage:

   Procedure FormMain.OnBtnLoginClick(Sender :TObject);
   Begin
    Console.log(TrIn(self));
    Try
     ...
     Console.log(Lg(['The value of pi is',PI]));
     ...
    Finally
     Console.log(TrOut(self));
    End
   End;

  From "self", the name of the calling object is retrieved. If not called
  from within an object, replace Caller with nil. Text can be supplied,
  but can also directly be put into the Console.log() as additional parameters,
  as done in the "Lg" example above. }

Function Err(
                  E : Exception;
       Const Caller : String;
     Const AtAction : String = '';
    Const UICaption : String = '';
    Const UIMessage : String = '';
    Const UIButtons : Array of String = [];
          UIOnClick : TDialogResultProc = Nil) : String; overload;
{ Returns a formatted string to be output to the Console carrying a full
  error report for the provided exception in E. E represent bot, a regular
  Delphi exception object or a JS error object encapsulated in a Delphi
  Exception object. The rational behind this is that when try-excepting an
  error, the actual error object caught can be of both types. Using just the
  typical Delphi way of catching all errors using "Except on E:Exception do"
  will not work for native JS errors. This is a shortcuming of the pas2js
  transpiler. In order to really catch all types of errors, first thing to do
  in the Except section is to typecast the always existing JS error object
  named "$e" into an Exception object and pass this to Err(), like this

   Var e : Exception;
   Begin
    Console.log(TrIn('TDM.OnResetDBLogReponse'));
    Try
     Try
      ...
     Except
      ASM e = $e; END;
      Console.error(Err(e,'TDM.OnResetDBLogReponse'));
     End;
    Finally
     Console.log(TrOut('TDM.OnResetDBLogReponse'));
    End;
   End;

  This just logs the error report in red color to the Console without showing
  an error dialog box.

  Parameters:
   - If Self is not available, Caller can be ommitted or set to nil.
   - AtAction is optional and describes the action that was executed when the
     Exception occured.
   - If either UIMessage or UIOnClick is provided, then the function not only
     returns the formatted error report, but also displays a MessageDlgEx() to
     report the error to the user.
   - UICaption is the caption of the dialog window. If ommitted, the default
     caption set in DefaultErrorMessageDialogCaption will be used. If this
     is empty as well, the default operating system caption will be used.
   - UIMessage is the message to display in the dialog window. The message can
     contain the following placeholders:
      @RCLASS - will be replaced by the ClassName of the Exception object
      @RMSG - will be replaced by the error message taken from the Exception
   - UIButtons is an array of caption strings for the buttons to be shownn
     in the dialog window. If ommitted, only an "OK" button will be created.
   - UIOnClick is a callback function with this signature:
      TDialogResultProc = reference to procedure(AValue: TDialogResult);
     If provided, this function will be called after the user clicked a button
     and the dialog was closed. AValue gives the number of the button that
     was clicked, based on the button list provided by UIButtons. If e.g.
     UIButtons was ['Yes','No','Abort'], then Yes returns 0, No returns 1 and
     Abort returns 2.

  More examples:

   ASM e = $e; END;
   Console.error(Err(e,'TDM.OnResetDBLogReponse','','Initialization Error',
    'Error of class "@RCLASS" detected. '+
    'Reason: @RMSG',[],@OnErrorDlgClick));

   This prints a red error report to the Console and shows a dialog with
   caption 'Initialization Error' and a message in the body that contains
   both, the error class and the error message. When the user clicks the
   automatically created OK button, OnErrorDlgClick is called, which can
   either be an object method or a procedure.

   ASM e = $e; END;
   Console.log(Err(e,Self,'','','That didnt work out',
   ['Retry','Cancel'], Procedure (AValue: TDialogResult)
    Begin
     Case AValue of
      0 : Cosole.log('Retry clicked');
      1 : Cosole.log('Cancel clicked');
    End);
   This prints an error report to the Console in normal black color and
   shows a dialog with a standard system caption and the given short message.
   Two buttons are shown: Retry and Cancel. An anonymous procedure handels the
   button clicks.

  Final hint: Even if Err() shows an error dialog box, the function returns
  immediately without waiting for the user to click a button, as all
  dialogs are async an non-modal. }

Function PopLogStack : String;
{ Reads the current content of the logs from FLogStack and clears
  it afterwards }
Procedure PushLogStack(Const Log : String; OutputToConsole: Boolean = False);

Type
 TLoggingHook = Reference to Procedure(Const Log : String);

 TErrorLoggingHook = Procedure(
   Const ErrorClass, ErrorMessage : String;
   Const ThrowFunc : String; ThrowLine, ThrowCol : Integer;
   Const CatchFunc : String; CatchLine, CatchCol : Integer;
   Const StackTrace : String;
   Const UserName : String; UserID : Integer;
   TimeStamp : TDateTime);

Var
 LoggingHook      : TLoggingHook = Nil;
 ErrorLoggingHook : TErrorLoggingHook = Nil;
 { If ErrorLoggingHook is assigned, then whenever Err() or GUIErr() is called
   to report an error, ErrorLoggingHook gets called as well, e.g. to also
   file an error report to disk or to a remote server. }

Var
 DefaultErrorMessageDialogCaption : String = '';

{##############################################################################}

Implementation

Uses JS, WebLib.Forms, JSFuncs, Tools;

Var
 CurrentUserName : String = '';
 CurrentUserID   : Integer = -1;

Var
 FIndentWhiteSpace : String = '';
 FIndentLevel      : Integer = 0;
 FLogStack         : TStringList = Nil;
 FBrowserName      : String;
 FBrowserVersion   : Integer;
 FBrowserType      : TBrowserType;
 FNavigator        : TJSObject;

{---------------------------------------}

Procedure PushLogStack(Const Log : String; OutputToConsole: Boolean = False);
Var
 SL : TStringList;
 I  : Integer;
Begin
 If FLogStack=Nil then FLogStack := TStringList.Create;
 FLogStack.Add(Log);
 If assigned(LoggingHook) then
  Begin
   LoggingHook(FLogStack.Text);
   FLogStack.Clear;
  End
 Else if FLogStack.Count>600 then
  Begin
   SL := TStringList.Create;
   For I := 100 to FLogStack.Count-1 do
    SL.Add(FLogStack[I]);
   FLogStack.Free;
   FLogStack := SL;
  End;
 If OutputToConsole then
  Console.Log(Log);
End;

Function PopLogStack : String;
Begin
 Result := FLogStack.Text;
 FLogStack.Clear;
End;

{---------------------------------------}

Procedure SetCurrentUser(Const UserName : String; UserID : Integer);
Begin
 CurrentUserName := UserName;
 CurrentUserID   := UserID;
End;

{---------------------------------------}

Function Err(
                  E : Exception;
       Const Caller : String;
     Const AtAction : String = '';
    Const UICaption : String = '';
    Const UIMessage : String = '';
    Const UIButtons : Array of String = [];
          UIOnClick : TDialogResultProc = Nil) : String; overload;
Var
 ThrowLine,ThrowCol : Integer;
 ThrowFunc,RMsg,
 StackTrace,UIdStr,
 RClass,UIMsg,UICap  : String;
Begin
 Try
  {$IFNDEF WIN32}
  ASM
   {if (!pas.SysUtils.Exception.isPrototypeOf(E)) {
    RMsg = E.message;
    RClass = "EJSException";
   } else {
    RMsg = E.fMessage;
    RClass = E.$classname;
   }}

   {if (E.FJSError===undefined) {
    if (E.stack===undefined) {
     StackTrace = "No stack info";
    } else {
     StackTrace = E.stack;
    }
   } else {
    StackTrace = E.FJSError.stack;
   }}
  END;
  {$ENDIF}

  If E is EAbort then
   Begin
    Result := Format('%s %s%s():%s',[
                FormatDateTime('yyyy-mm-dd hh:nn:ss:zzz',Now),
                FIndentWhiteSpace,
                Caller,
                E.Message]);
    Exit;
   End;

  GetCallStackFrame(ThrowFunc,ThrowLine,ThrowCol,E,1);

  If CurrentUserID<0 then UIdStr := ''
  Else UIdStr := IntToStr(CurrentUserID);

  If FBrowserType in [btFirefox,btEdge] then
   Result :=
    'Exception caught'+#13#10+
    '   Date'#9#9#9': '+DateTimeToStr(Now)+#13#10+
    '   User name'#9#9': '+CurrentUserName+#13#10+
    '   User ID'#9#9': '+UIdStr+#13#10+
    '   Exception class'#9': '+RClass+#13#10+
    '   Error message'#9': '+RMsg+#13#10+
    Format('   Throw position'#9': %s(%d:%d)',[ThrowFunc,ThrowLine,ThrowCol])+#13#10+
    Format('   Catch function'#9': %s',[Caller])+#13#10+
    '   At action'#9#9': '+AtAction+#13#10+
    'JavaScript stack trace'+#13#10+
    String(StackTrace)+#13#10
  Else
   Result :=
    'Exception caught'+#13#10+
    '   Date'#9#9#9#9': '+DateTimeToStr(Now)+#13#10+
    '   User name'#9#9': '+CurrentUserName+#13#10+
    '   User ID'#9#9#9': '+UIdStr+#13#10+
    '   Exception class'#9': '+RClass+#13#10+
    '   Error message'#9': '+RMsg+#13#10+
    Format('   Throw position'#9': %s(%d:%d)',[ThrowFunc,ThrowLine,ThrowCol])+#13#10+
    Format('   Catch function'#9': %s',[Caller])+#13#10+
    '   At action'#9#9': '+AtAction+#13#10+
    'JavaScript stack trace'+#13#10+
    String(StackTrace)+#13#10;

  PushLogStack(Result);

  If assigned(UIOnClick) or (UIMessage<>'') then
   Begin
    UIMsg := UIMessage;
    UIMsg := StringReplace(UIMsg,'@RCLASS',RClass,[rfReplaceAll]);
    UIMsg := StringReplace(UIMsg,'@RMSG',RMsg,[rfReplaceAll]);
    UIMsg := UIMsg + '  ';
    If UICaption<>'' then UICap := UICaption
    Else if DefaultErrorMessageDialogCaption<>'' then UICap := DefaultErrorMessageDialogCaption
    Else UICap := '';
    MessageDlgEx(UICap,UIMsg,mtError,UIButtons,UIOnClick).Show;
   End;

 Except
  Result := 'Error during Err()';
 End;
End;

{---------------------------------------}

Function TrIn(Const Caller : String; Const Parms : Array of JSValue = []) : String;
Var
 S : String;
 J : JSValue;
Begin
 Try
  S := '';
  If Length(Parms)>0 then
   For J in Parms do S := S + String(J);

  Result := Format('%s %s>>>> %s(): %s'#13#10,[
             FormatDateTime('yyyy-mm-dd hh:nn:ss:zzz',Now),
             FIndentWhiteSpace,
             Caller,
             S]);
  PushLogStack(Result);
  Inc(FIndentLevel);
  FIndentWhiteSpace := StringOfChar('.',FIndentLevel*5);
 Except
  Result := 'TrIn-Exception';
 End;
End;

{---------------------------------------}

Function TrOut(Const Caller : String; Const Parms : Array of JSValue = []) : String;
Var
 S : String;
 J : JSValue;
Begin
 Try
  S := '';
  If Length(Parms)>0 then
   For J in Parms do S := S + String(J);

  Dec(FIndentLevel);
  If FIndentLevel<0 then FIndentLevel := 0;
  FIndentWhiteSpace := StringOfChar('.',FIndentLevel*5);

  Result := Format('%s %s<<<< %s(): %s'#13#10,[
             FormatDateTime('yyyy-mm-dd hh:nn:ss:zzz',Now),
             FIndentWhiteSpace,
             Caller,
             S]);
  PushLogStack(Result);
 Except
  Result := 'TrOut-Exception';
 End;
End;

{---------------------------------------}

Function Lg(Const Caller : String; Const Parms: Array of JSValue) : String;
Var
 S : String;
 J : JSValue;
Begin
 Try
  S := '';
  If Length(Parms)>0 then
   For J in Parms do S := S + String(J);

  Result := Format('%s %s%s():%s',[
              FormatDateTime('yyyy-mm-dd hh:nn:ss:zzz',Now),
              FIndentWhiteSpace,
              Caller,
              S]);
  PushLogStack(Result);
 Except
  Result := 'Lg-Exception';
 End;
End;

{---------------------------------------}

Initialization
 GetBrowserSpecs(FBrowserName,FBrowserVersion,FBrowserType,FNavigator);

End.
