Unit JSFuncs;

Interface

Uses JSDelphiSystem, JS, SysUtils, WEBLib.Controls, WEBLib.Forms, WEBLib.JSON;

Type
 { In case an uncaught exception is finally caught by an Application.OnError
   handler and this handler uses TApplicationErrorToException() to convert
   the "raw" TApplicationError into an Exception, then if the raw
   TApplicationError does not inherit from Exception but instead is a
   JS error object, then the Exception object returned by
   TApplicationErrorToException() is of this class. }
 EJSException = Class(Exception);

 // Set of known browsers
 TBrowserType = (
  btUnknown,btOpera,btChrome,btSafari,btFirefox,
  btInternetExplorer,btTrident,btEdge);

// Conversion functions for TBrowserType
Function TBrowserTypeToStr(Const BrowserType : TBrowserType; UpperCase : Boolean = False) : String;
Function StrToTBrowserType(Const S : String) : TBrowserType;

Procedure GetBrowserSpecs(Var BrowserName : String;
                       Var BrowserVersion : Integer;
                          Var BrowserType : TBrowserType;
                            Var Navigator : TJSObject);
{ This uses the global JS Navigator object to retrieve from this object the
  details of the browser used by the client. The JS Navigator object is
  returned as well. }

Function GetCallStack(Const E : Exception = Nil) : TJSArray;
{ If E is an assigned exception, then GetCallStack retrieves the call stack
  from this object, be it that the Exception is a real Exception class
  instance or in fact E just carries a JS exception object. If E is Nil,
  then the current call stack will be returned (by creating one using
  JS function "new Error()") }

Procedure GetCallStackFrame(Var FunctionName : String;
                              Var LineNumber,
                                ColumnNumber : Integer;
                                     Const E : Exception = Nil;
                                    Position : Integer = 0);
{ Retrieves a callstack using GetCallStack(E) and returns from the stack
  frame, that represents the caller of this funcion, the FunctionName of the
  calling function as well as the LineNumber and the ColumnNumber of the
  calling code position within the man JS file.
  As the format of the stack data has no standard and differs between browsers,
  this function may need review from time to time.
  Typically Position is ommitted such that the most likely frame on the stack
  is used to return the calling code position. If this fails, Position can be
  manually set to retrieve a certain position. A positive Position counts from
  the beginning of the stack and a negative Position from the end.
  Some typical stacks are shown here:

  - Chrome stack trace
     Error: Fehlermeldung
       at Object.Create$1 (http://localhost:8000/AMIMobile/AMIMobile.js:2628:23)
       at Object.c.$create (http://localhost:8000/AMIMobile/AMIMobile.js:295:19)
       at Object.$impl.Proc4 (http://localhost:8000/AMIMobile/AMIMobile.js:41973:35)
       at Object.$impl.Proc3 (http://localhost:8000/AMIMobile/AMIMobile.js:41976:11)

  - Edge stack trace
     Error: Fehlermeldung
      at Create$1 (http://localhost:8000/AMIMobile/AMIMobile.js:2628:7)
      at c.$create (http://localhost:8000/AMIMobile/AMIMobile.js:295:13)
      at $impl.Proc4 (http://localhost:8000/AMIMobile/AMIMobile.js:41993:5)
      at $impl.Proc3 (http://localhost:8000/AMIMobile/AMIMobile.js:41996:5)

  - Firefox stack trace
     this.Create$1@http://localhost:8000/AMIMobile/AMIMobile.js:2628:23
     createClass/c.$create@http://localhost:8000/AMIMobile/AMIMobile.js:295:19
     $impl.Proc4@http://localhost:8000/AMIMobile/AMIMobile.js:41973:35
     $impl.Proc3@http://localhost:8000/AMIMobile/AMIMobile.js:41976:11 }

Function TApplicationErrorToException(AError : TApplicationError) : Exception;
{ Converts a JS error object that in Delphi is wrapped in a TAppplicationError
  class into a "regular" Delphi exception class object }

Function GetWebControlInnerHTML(WebControl : TCustomControl) : String;

Function SanitizeUserInput(UserInput : String) : String;

{#############################################################################}

Implementation

Uses Web, WebLib.Dialogs,Logger;

Type
 TBrowserName = Record
  Name   : String;
  UpName : String
 End;

 TBrowserNameList = Array[TBrowserType] of TBrowserName;

Const
 BrowserNameList : TBrowserNameList = (
  (
   Name   : 'Unknown';
   UpName : 'UNKNOWN'),
  (
   Name   : 'Opera';
   UpName : 'OPERA'),
  (
   Name   : 'Chrome';
   UpName : 'CHROME'),
  (
   Name   : 'Safari';
   UpName : 'SAFARI'),
  (
   Name   : 'Firefox';
   UpName : 'FIREFOX'),
  (
   Name   : 'InternetExplorer';
   UpName : 'INTERNETEXPLORER'),
  (
   Name   : 'Trident';
   UpName : 'TRIDENT'),
  (
   Name   : 'Edge';
   UpName : 'EDGE'));

Var
 FBrowserName      : String;
 FBrowserVersion   : Integer;
 FBrowserType      : TBrowserType;
 FNavigator        : TJSObject;

{--------------------------------------------------}

Function TBrowserTypeToStr(Const BrowserType : TBrowserType; UpperCase : Boolean = False) : String;
Begin
 If UpperCase then Result := BrowserNameList[BrowserType].UpName
 Else Result := BrowserNameList[BrowserType].Name;
End;

{--------------------------------------------------}

Function StrToTBrowserType(Const S : String) : TBrowserType;
Var
 BT  : TBrowserType;
 UpS : String;
Begin
 Result := btUnknown;
 UpS := UpperCase(S);
 For BT in TBrowserType do
  If UpS=BrowserNameList[BT].UpName then
   Begin
    Result := BT;
    Exit;
   End;
End;

{--------------------------------------------------}

Function GetCallStack(Const E : Exception = Nil) : TJSArray;
Var stack : JSValue;
Begin
 If assigned(E) then
  Begin
   {$IFNDEF WIN32}
   ASM {
    if (E.FJSError===undefined)
     {
      if (E.stack===undefined) {
      stack = new Error().stack.toString().split(/\r\n|\n/);
      } else {
      stack = E.stack.toString().split(/\r\n|\n/);
      }
    } else {
      stack = E.FJSError.stack.toString().split(/\r\n|\n/);
    }
   }
   END
   {$ENDIF}
  End
 Else
  Begin
   {$IFNDEF WIN32}
    ASM
     {
      stack = new Error().stack.toString().split(/\r\n|\n/);
    }
    END;
   {$ENDIF}
  End;
 Result := TJSArray(stack);
End;

{--------------------------------------------------}

Procedure GetCallStackFrame(Var FunctionName : String;
                              Var LineNumber,
                                ColumnNumber : Integer;
                                     Const E : Exception = Nil;
                                    Position : Integer = 0);
Var
 S,Tok : String;
 P,N   : Integer;
 Stack : TJSArray;
Begin
 FunctionName := '<?>';
 LineNumber   := 0;
 ColumnNumber := 0;

 Try
  Stack := GetCallStack(E);

  If Position>=0 then
   Begin
    If FBrowserType in [btOpera,btChrome,btEdge,btSafari] then Inc(Position,4)
    Else Inc(Position,3);
    If assigned(E) then
     Begin
      {$IFNDEF WIN32}
      ASM
       {if (E.FJSError===undefined) {
        Position = Position - 3;
       } else {
        Position = Position - 1;
       }}
      END
     {$ENDIF}
     End;
    S := String(Stack[Position]);
   End
  Else
   S := String(Stack[Stack.length+Position]);

  If FBrowserType in [btOpera,btChrome,btEdge,btSafari] then P := Pos(' (http',S)
  Else P := Pos('@http',S);

  If P>0 then
   Begin
    N := Pos(' [as',S);
    If N>0 then Tok := Copy(S,1,N-1)
    Else Tok := Copy(S,1,P-1);
    P := LastDelimiter('. ',Tok);
    Tok := Copy(Tok,P+1,100);
    FunctionName := Tok;
   End;

  If FunctionName<>'' then
   Begin
    P := LastDelimiter(':',S);
    If P>0 then
     Begin
      N := StrToInt(Copy(S,P+1,100));
      ColumnNumber := N;
      SetLength(S,P-1);
      P := LastDelimiter(':',S);
      If P>0 then
       Begin
        N := StrToInt(Copy(S,P+1,100));
        LineNumber := N;
       End
      Else
       Begin
        LineNumber := 0;
        ColumnNumber := 0;
       End;
     End;
   End;
 Except
  FunctionName := '<?>';
  LineNumber := 0;
  ColumnNumber := 0;
 End;
End;

{--------------------------------------------------}

Function TApplicationErrorToException(AError : TApplicationError) : Exception;
Begin
 Result := Nil;
 {$IFNDEF WIN32}
 ASM
  {if (AError.AError.FJSError!==undefined) {
   Result = AError.AError;
  }}
 END;
 {$ENDIF}
 If assigned(Result) then exit;

 Result := EJSException.Create(AError.AMessage);
 Result.JSError := TJSError(AError.AError);
End;

{--------------------------------------------------}

Procedure GetBrowserSpecs(Var BrowserName : String;
                       Var BrowserVersion : Integer;
                          Var BrowserType : TBrowserType;
                            Var Navigator : TJSObject);
Var
 N : String;
 V : Integer;
 J : JSValue;
Begin
 Try
  {$IFNDEF WIN32}
  ASM
   {
   navigator.browserSpecs = (function(){
       var ua = navigator.userAgent, tem,
           M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
       if(/trident/i.test(M[1])){
           tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
           return {name:'IE',version:(tem[1] || '')};
       }
       {if(M[1]=== 'Chrome'){
           tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
           if(tem != null) return {name:tem[1].replace('OPR', 'Opera'),version:tem[2]};
       }}
       M = M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
       {if((tem = ua.match(/version\/(\d+)/i))!= null)
           M.splice(1, 1, tem[1]); }
       return {name:M[0], version:M[1]};
   })();
   }
   var Specs = navigator.browserSpecs;
   N = Specs.name;
   V = Specs.version;
   J = navigator;
  END;
  {$ENDIF}
  BrowserName := N;
  BrowserVersion := V;
  BrowserType := StrToTBrowserType(N);
  Navigator := TJSObject(J);
 Except
  BrowserName := '<err>';
  BrowserVersion := 0;
  BrowserType := btUnknown;
  Navigator := Nil;
 End;
End;

{--------------------------------------------------}

Procedure CheckSysUtilsPatchApplied;
Var
 E : Exception;
 Patched : Boolean;
Begin
 E := Exception.Create('');
 {$IFNDEF WIN32}
 ASM
  Patched = (E.FJSError!==undefined);
 END;
 {$ENDIF}
 E.Free;
 If Patched then exit;
 Console.error('The patch to SysUtils to fix Exception class is missing');
 ShowMessage('The patch to SysUtils.pas to fix the Exception class is missing');
End;

{--------------------------------------------------}

Function GetWebControlInnerHTML(WebControl : TCustomControl) : String;
Var
 S  : String;
 JV : JSValue;
Begin
 JV := WebControl;
 S := '';
 {$IFNDEF WIN32}
 ASM
  if (JV.GetElementHandle() != null) {
    S = JV.GetElementHandle().innerHTML;
  }
 END;
 {$ENDIF}
 If JV=Nil then; // Keep compiler happy
 Result := S;
End;

{--------------------------------------------------}

Function SanitizeUserInput(UserInput : String) : String;
Var str,Res : String;
Begin
 str := UserInput;
 {$IFNDEF WIN32}
 ASM
  Res = str.replace(/javascript:/gi, '').replace(/[^\w-_. ]/gi, function (c) {return `&#${c.charCodeAt(0)};`;});
 END;
 {$ENDIF}
 Result := Res;
 If str='' then; // Keep compiler happy
End;

{--------------------------------------------------}

Initialization
 CheckSysUtilsPatchApplied;
 GetBrowserSpecs(FBrowserName,FBrowserVersion,FBrowserType,FNavigator);

End.
