Unit FormDataProt;

Interface

Uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DMMain, WEBLib.StdCtrls, 
WEBLib.ExtCtrls, WEBLib.WebCtrls, Tools;

Type
  TDataProtForm = class(TForm)
    BtnBackFromDataProt: TButton;
    BtnBackFromDataProt2: TButton;
    procedure BtnBackFromDataProtClick(Sender: TObject);
   Private
   Public
    PreviousForm : TForm;
  protected procedure LoadDFMValues; override; End;

Var
 DataProtForm : TDataProtForm;

{#############################################################################}

Implementation

{$R *.dfm}

Uses DateUtils, JSFuncs, WEBLib.JSON, JSON, Logger, FormMain;

Procedure TDataProtForm.BtnBackFromDataProtClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TDataProtForm.BtnBackFromDataProtClick'));
 Try
  Try
   SwitchToForm(PreviousForm);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDataProtForm.BtnBackFromDataProtClick'));
  End;
 Finally
  Console.log(TrOut('TDataProtForm.BtnBackFromDataProtClick'));
 End;
End;

{---------------------------------------}


procedure TDataProtForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BtnBackFromDataProt := TButton.Create('BtnBackFromDataProt1');
  BtnBackFromDataProt2 := TButton.Create('BtnBackFromDataProt2');

  BtnBackFromDataProt.BeforeLoadDFMValues;
  BtnBackFromDataProt2.BeforeLoadDFMValues;
  try
    Name := 'DataProtForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'Foo';
    Visible := True;
    BtnBackFromDataProt.SetParentComponent(Self);
    BtnBackFromDataProt.Name := 'BtnBackFromDataProt';
    BtnBackFromDataProt.Left := 16;
    BtnBackFromDataProt.Top := 25;
    BtnBackFromDataProt.Width := 130;
    BtnBackFromDataProt.Height := 25;
    BtnBackFromDataProt.Caption := 'BtnBackFromDataProt';
    BtnBackFromDataProt.ChildOrder := 2;
    BtnBackFromDataProt.ElementFont := efCSS;
    BtnBackFromDataProt.ElementPosition := epIgnore;
    BtnBackFromDataProt.HeightPercent := 100.000000000000000000;
    BtnBackFromDataProt.WidthPercent := 100.000000000000000000;
    SetEvent(BtnBackFromDataProt, Self, 'OnClick', 'BtnBackFromDataProtClick');
    BtnBackFromDataProt2.SetParentComponent(Self);
    BtnBackFromDataProt2.Name := 'BtnBackFromDataProt2';
    BtnBackFromDataProt2.Left := 16;
    BtnBackFromDataProt2.Top := 56;
    BtnBackFromDataProt2.Width := 130;
    BtnBackFromDataProt2.Height := 25;
    BtnBackFromDataProt2.Caption := 'BtnBackFromDataProt';
    BtnBackFromDataProt2.ChildOrder := 2;
    BtnBackFromDataProt2.ElementFont := efCSS;
    BtnBackFromDataProt2.ElementPosition := epIgnore;
    BtnBackFromDataProt2.HeightPercent := 100.000000000000000000;
    BtnBackFromDataProt2.WidthPercent := 100.000000000000000000;
    SetEvent(BtnBackFromDataProt2, Self, 'OnClick', 'BtnBackFromDataProtClick');
  finally
    BtnBackFromDataProt.AfterLoadDFMValues;
    BtnBackFromDataProt2.AfterLoadDFMValues;
  end;
end;

End.
