Unit FormDoclist;

Interface

Uses
  JSDelphiSystem, SysUtils, Classes, JS, Web, WEBLib.Graphics,
  WEBLib.Controls, WEBLib.Forms, WEBLib.Dialogs, DMMain, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, WEBLib.WebCtrls, Tools;

Type
  TDocItemControl = Record
   LabelA,
   LabelB : TLabel;
   BtnA,
   BtnB   : TButton;
  End;

  TDoclistForm = class(TForm)
    BtnYear1: TButton;
    BtnYear2: TButton;
    BtnYear3: TButton;
    BtnYear4: TButton;
    CbxSalesAreas: TComboBox;
    EdSearch: TEdit;
    BtnSearchDocs: TButton;
    BtnUploadFile: TButton;
    BtnMenu: TButton;
    BtnCamera: TButton;
    LabNoFilesFound: TLabel;
    Procedure BtnYearClick(Sender: TObject);
    Procedure WebFormCreate(Sender: TObject);
    Procedure WebFormDestroy(Sender: TObject);
    procedure BtnSearchDocsClick(Sender: TObject);
    procedure BtnUploadFileClick(Sender: TObject);
    procedure BtnMenuClick(Sender: TObject);
    procedure CbxSalesAreasChange(Sender: TObject);
    procedure BtnCameraClick(Sender: TObject);
   Private
    FItemCont     : THTMLDiv;
    FItemList     : Array of TDocItemControl;
    FNrItems      : Integer;
    FAfterItemAdd : TThenProc;
    procedure AfterDocsLoaded(JV: JSValue);
    procedure LoadAndShowDocs;
    procedure ShowStoredDocs;
    procedure CreateListItems(NrItems: Integer; AndThen : TThenProc);
    procedure DocItemButtonAClick(Sender: TObject);
    procedure DocItemButtonBClick(Sender: TObject);
    procedure ContinueAddingListItems(JV: JSValue);
    procedure FillDocItems(JV: JSValue);
    procedure WhenReadyToDownload(JS: JSValue);
    procedure ShowPDFSecondTime(JS: JSValue);
   Public
    FFilterYear   : Integer;
    FFilterText   : String;
    FFilterSubID  : Integer;
    Procedure ShowDocs;
    Procedure FormInit;
    Procedure SetActiveButton;
    Procedure UpdateDocList;
  protected procedure LoadDFMValues; override; End;

Var
 DoclistForm : TDoclistForm;

{#############################################################################}

Implementation

{$R *.dfm}

Uses
 DateUtils, JSFuncs, WEBLib.JSON, JSON, Logger, FormMain, FormLogin,
 FormUpload, FormCamera, FormShowPDF, FormMenu;

{---------------------------------------}

Procedure TDoclistForm.WebFormCreate(Sender: TObject);

 Procedure PrepareBtn(Btn : TButton; Year : Integer);
 Begin
  Btn.Caption := IntToStr(Year);
  Btn.Tag := Year;
 End;

Var Yr,Mo,Dy : Word;
Begin
 Console.log(TrIn('TDoclistForm.WebFormCreate'));
 Try
  FItemCont := THTMLDiv.Create('DokArea');

  DecodeDate(Now,Yr,Mo,Dy);
  PrepareBtn(BtnYear1,Yr-3);
  PrepareBtn(BtnYear2,Yr-2);
  PrepareBtn(BtnYear3,Yr-1);
  PrepareBtn(BtnYear4,Yr);

  If not (LDat.PropExists('DocFilterYear') and
          LDat.PropExists('DocFilterText') and
          LDat.PropExists('DocFilterSubID')) then
   Begin
    LDat.DocFilterYear  := Yr;
    LDat.DocFilterText  := '';
    LDat.DocFilterSubID := -1;
   End;

  FFilterYear  := LDat.DocFilterYear;
  FFilterText  := LDat.DocFilterText;
  FFilterSubID := LDat.DocFilterSubID;
 Finally
  Console.log(TrOut('TDoclistForm.WebFormCreate'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.WebFormDestroy(Sender: TObject);
Begin
 Console.log(TrIn('TDoclistForm.WebFormDestroy'));
 Try
 Finally
  Console.log(TrOut('TDoclistForm.WebFormDestroy'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.FormInit;
Var Ind : Integer;
Begin
 Console.log(TrIn('TDoclistForm.FormInit'));
 Try
  SetActiveButton;
  EdSearch.Text := FFilterText;
  If FFilterSubID=-1 then Ind := 0
  Else Ind := FFilterSubID;
  If CbxSalesAreas.Items.Count>0 then
   CbxSalesAreas.ItemIndex := Ind;
  ShowDocs;
 Finally
  Console.log(TrOut('TDoclistForm.FormInit'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.SetActiveButton;

 Procedure SetButton(Btn : TButton);
 Begin
  If Btn.Tag=FFilterYear then
   Begin
    Btn.ElementClassName := 'ButtonYearActive';
    Console.Log(Lg('TDocListForm.SetActiveButton.SetButton',['New active button:',Btn.Tag]));
   End
  Else if Btn.ElementClassName<>'ButtonYear' then
   Btn.ElementClassName := 'ButtonYear'
 End;

Begin
 Console.log(TrIn('TDoclistForm.SetActiveButton'));
 Try
  SetButton(BtnYear1);
  SetButton(BtnYear2);
  SetButton(BtnYear3);
  SetButton(BtnYear4);
 Finally
  Console.log(TrOut('TDoclistForm.SetActiveButton'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.BtnYearClick(Sender: TObject);
Var ClickedYear : Integer;
Begin
 Console.log(TrIn('TDoclistForm.BtnYearClick'));
 Try
  ClickedYear := (Sender as TButton).Tag;
  FFilterYear := ClickedYear;
  SetActiveButton;

  UploadForm.FFilterYear := FFilterYear;
  UploadForm.SetActiveButton;

  CameraForm.FFilterYear := FFilterYear;
  CameraForm.SetActiveButton;

  UpdateDocList;
 Finally
  Console.log(TrOut('TDoclistForm.BtnYearClick'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.ShowDocs;
// If we have docs in local data, show them, else load them and then show them
Var e : Exception;
Begin
 Console.log(TrIn('TDoclistForm.ShowDocs'));
 Try
  Try
   // Do we have docs in memory already?
   If LDat.PropExists('DocList') then ShowStoredDocs
   Else LoadAndShowDocs;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDoclistForm.ShowDocs','','','Anzeige fehlgeschlagen',[],nil));
   Abort;
  End;
 Finally
  Console.log(TrOut('TDoclistForm.ShowDocs'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.ShowStoredDocs;
// Show docs stored in local data
Var e : Exception;
Begin
 Console.log(TrIn('TDoclistForm.ShowStoredDocs'));
 Try
  Try
   // Show data in LDat
   // Step 1: Create the HTML elements and link to Delphi controls
   // Step 2: Fill controls with received data
   CreateListItems(LDat.DocList.Length,@FillDocItems);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDoclistForm.ShowStoredDocs','','','Anzeige fehlgeschlagen',[],nil));
   Abort;
  End;
 Finally
  Console.log(TrOut('TDoclistForm.ShowStoredDocs'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.AfterDocsLoaded(JV : JSValue);
// Loading docs from server completed. Show docs received.
Begin
 Console.log(TrIn('TDoclistForm.AfterDocsLoaded'));
 Try
  ShowStoredDocs;
  If ActiveForm<>Self then
   SwitchToForm(Self);
 Finally
  Console.log(TrOut('TDoclistForm.AfterDocsLoaded'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.LoadAndShowDocs;
// Load docs from server and then show them (via AfterDocsLoaded)
Var
 e : Exception;
 Params : TJSObject;
Begin
 Console.log(TrIn('TDoclistForm.LoadAndShowDocs'));
 Try
  Try
   Params := TJSObject.new;
   Params['FilterYear'] := Self.FFilterYear;
   Params['FilterText'] := Self.FFilterText;
   Params['FilterSubjAreaID'] := Self.FFilterSubID;
   DM.RESTLoadDocs(Params,@AfterDocsLoaded);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDoclistForm.LoadAndShowDocs','','','Herunterladen der Dokumentenliste fehlgeschlagen',[],nil));
   Abort;
  End;
 Finally
  Console.log(TrOut('TDoclistForm.LoadAndShowDocs'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.CreateListItems(NrItems : Integer; AndThen : TThenProc);
Begin
 FNrItems := NrItems;
 FAfterItemAdd := AndThen;
 If FNrItems>20 then
  Begin
   MainForm.ShowThrobber(0,@ContinueAddingListItems)
  End
 Else
  ContinueAddingListItems(Nil);
End;

{---------------------------------------}

Const
 PanHTML =
  '<div id="DokBoxMobile">'+
  ' <span id="DocItemLabelA@" class="DocItemLabelA"></span><br>'+
  ' <span id="DocItemLabelB@" class="DocItemLabelB"></span><br>'+
  '</div>'+
  '<div id="DocItemButtonA@" class="DocItemButtonA"><i class="fas fa-eye"></i></div>'+
  '<div id="DocItemButtonB@" class="DocItemButtonB"><i class="fas fa-trash"></i></div>'+
  '<div style="clear:both;"></div>';

Procedure TDoclistForm.ContinueAddingListItems(JV: JSValue);
Var I  : Integer;
Begin
 For I := 0 to Length(FItemList)-1 do
  With FItemList[I] do
   Begin
    FreeAndNil(LabelA);
    FreeAndNil(LabelB);
    FreeAndNil(BtnA);
    FreeAndNil(BtnB);
   End;
 SetLength(FItemList,FNrItems);

 // Buggy as of 2021-01-23. Need to add something.
 // See https://support.tmssoftware.com/c/web-core/tms-web-core/25
 FItemCont.HTML.Clear;
 If FNrItems<1 then FItemCont.HTML.Text := ' '; // Temp. bug fix

 For I := 1 to FNrItems do
   FItemCont.HTML.Add(StringReplace(PanHTML,'@',IntToStr(I),[rfReplaceAll]));
 For I := 1 to FNrItems do
  Begin
   With FItemList[I-1] do
    Begin
     LabelA := TLabel.Create('DocItemLabelA'+IntToStr(I));
     LabelB := TLabel.Create('DocItemLabelB'+IntToStr(I));
     BtnA   := TButton.Create('DocItemButtonA'+IntToStr(I));
     BtnB   := TButton.Create('DocItemButtonB'+IntToStr(I));
     BtnA.Tag := I;
     BtnA.OnClick := DocItemButtonAClick;
     BtnB.Tag := I;
     BtnB.OnClick := DocItemButtonBClick;
     // LabelA.Caption := DateTimeToStr(Now);
     // LabelB.Caption := LabelB.ElementID;
    End;
  End;
 MainForm.HideThrobber;
 If assigned(FAfterItemAdd) then
  FAfterItemAdd(Nil);
End;

{---------------------------------------}

Procedure TDoclistForm.FillDocItems(JV : JSValue = Nil);
Var
 e   : Exception;
 I   : Integer;
 Doc : TJSObject;
Begin
 Console.log(TrIn('TDoclistForm.FillDocItems'));
 Try
  Try
   For I := 0 to LDat.DocList.Length-1 do
    Begin
     Doc := TJSObject(LDat.DocList[I]);
     FItemList[I].LabelA.Caption := 'Upload: '+ String(Doc['Datum']);
     FItemList[I].LabelB.Caption := String(Doc['Titel']);
    End;
   // If LDat.DocList.Length<1 then
    // MessageDlgEx(AppCaption,'Keine Dokumente gefunden',mtInformation).Show;
    LabNoFilesFound.Visible := LDat.DocList.Length<1;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDoclistForm.FillDocItems'));
  End;
 Finally
  Console.log(TrOut('TDoclistForm.FillDocItems'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.ShowPDFSecondTime(JS : JSValue);
Begin
 Console.log(TrIn('TDoclistForm.ShowPDFSecondTime'));
 Try
  ShowPDFForm.ShowPDF(String(JS));
 Finally
  Console.log(TrOut('TDoclistForm.ShowPDFSecondTime'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.WhenReadyToDownload(JS : JSValue);
Var
 JO    : TJSObject;
 FN    : String;
 IsNew : Boolean;
Begin
 Console.log(TrIn('TDoclistForm.WhenReadyToDownload'));
 Try
  JO := TJSObject(JS);
  FN := 'File_' + String(JO['FileID']) + '.pdf';
  IsNew := not Boolean(JO['Existed']);

  ShowPDFForm.ShowPDF(FN);

  If IsNew and Application.IsMobile and (BrowserType=btSafari) then
   Begin
    Console.Log(Lg('TDocListForm.WhenReadyToDownload',[
     'Probably running on iPhone, opening PDF a second time']));
    DM.ContinueWith(@ShowPDFSecondTime,FN,500);
   End;
 Finally
  Console.log(TrOut('TDoclistForm.WhenReadyToDownload'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.DocItemButtonAClick(Sender: TObject);
Var
 e         : Exception;
 ItemNo,
 TmpFileID : Integer;
 Doc       : TJSObject;
Begin
 Console.log(TrIn('TDoclistForm.DocItemButtonAClick'));
 Try
  Try
   ItemNo := (Sender as TButton).Tag;
   Console.Log(Lg('TDocListForm.DocItemButtonAClick',['Clicked Item Nr: ',ItemNo]));
   Doc := TJSObject(LDat.DocList[ItemNo-1]);
   TmpFileID := Integer(Doc['MandFilesTempID']);
   Console.Log(Lg('TDocListForm.DocItemButtonAClick',['TmpFileID: ',TmpFileID]));

   DM.RESTRequestPDFDownload(TmpFileID,
    Procedure(JV : JSValue)
     Begin
      DM.ContinueWith(@WhenReadyToDownload,JV);
     End);

  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDoclistForm.DocItemButtonAClick'));
  End;
 Finally
  Console.log(TrOut('TDoclistForm.DocItemButtonAClick'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.DocItemButtonBClick(Sender: TObject);
Var
 e         : Exception;
 ItemNo,
 TmpFileID : Integer;
 Doc       : TJSObject;
Begin
 Console.log(TrIn('TDoclistForm.DocItemButtonBClick'));
 Try
  Try
   ItemNo := (Sender as TButton).Tag;
   Console.Log(Lg('TDocListForm.DocItemButtonBClick',['Clicked Item Nr: ',ItemNo]));
   Doc := TJSObject(LDat.DocList[ItemNo-1]);
   TmpFileID := Integer(Doc['MandFilesTempID']);
   Console.Log(Lg('TDocListForm.DocItemButtonBClick',['TmpFileID: ',TmpFileID]));

   MessageDlgEx(AppCaption,
    'Beleg "'+String(Doc['Titel'])+'" löschen?',
    mtConfirmation,['Ja','Nein'],
     Procedure (AValue: TModalResult)
       Begin
        If AValue=0 then
         Begin
          DM.RESTDeleteTempFile(TmpFileID,
           Procedure(JV : JSValue)
            Begin
             MessageDlgEx(AppCaption,'Beleg gelöscht',mtInformation).Show;
             UpdateDocList;
            End);
         End;
       End).Show;

  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDoclistForm.DocItemButtonBClick'));
  End;
 Finally
  Console.log(TrOut('TDoclistForm.DocItemButtonBClick'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.BtnSearchDocsClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TDoclistForm.BtnSearchDocsClick'));
 Try
  Try
   UpdateDocList;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDoclistForm.BtnSearchDocsClick'));
  End;
 Finally
  Console.log(TrOut('TDoclistForm.BtnSearchDocsClick'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.BtnCameraClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TDoclistForm.BtnCameraClick'));
 Try
  Try
   SwitchToForm(CameraForm);
   CameraForm.CbxSalesAreas.ItemIndex := Self.CbxSalesAreas.ItemIndex;
   CameraForm.Enter;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDoclistForm.BtnCameraClick'));
   Abort;
  End;
 Finally
  Console.log(TrOut('TDoclistForm.BtnCameraClick'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.BtnUploadFileClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TDoclistForm.BtnUploadFileClick'));
 Try
  Try
   SwitchToForm(UploadForm);
   UploadForm.CbxSalesAreas.ItemIndex := Self.CbxSalesAreas.ItemIndex;
   UploadForm.Enter;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDoclistForm.BtnUploadFileClick'));
   Abort;
  End;
 Finally
  Console.log(TrOut('TDoclistForm.BtnUploadFileClick'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.BtnMenuClick(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TDoclistForm.BtnMenuClick'));
 Try
  Try
   MenuForm.PreviousForm := ActiveForm;
   SwitchToForm(MenuForm);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDoclistForm.BtnMenuClick','','','Menüauswahl fehlgeschlagen'));
   Abort;
  End;
 Finally
  Console.log(TrOut('TDoclistForm.BtnMenuClick'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.UpdateDocList;
Var
 e      : Exception;
 Ind,
 SubID  : Integer;
 Sub    : String;
 OID    : TObject;
 Params : TJSObject;
Begin
 Console.log(TrIn('TDoclistForm.UpdateDocList'));
 Try
  Try
   Ind := CbxSalesAreas.ItemIndex;
   If Ind>=0 then
    Begin
     Sub := CbxSalesAreas.Items[Ind];
     If Ind>0 then
      Begin
       OID := CbxSalesAreas.Items.Objects[Ind];
       {$IFNDEF WIN32}
       ASM
        SubID = OID;
       END;
       {$ENDIF}
       If OID=Nil then;
      End
     Else
      SubID := -1;
    End
   Else Sub := '';
   FFilterSubID := SubID;

   FFilterText := EdSearch.Text;
   Console.Log(Lg('TDocListForm.UpdateDocList',['Filter - Year: ',FFilterYear,
    ', Subj: ','"'+Sub+'"',', SubjID: ',SubID,', Filter: ','"'+FFilterText+'"']));

   LDat.DocFilterYear  := FFilterYear;
   LDat.DocFilterText  := FFilterText;
   LDat.DocFilterSubID := FFilterSubID;

   Params := TJSObject.new;
   Params['FilterYear'] := Self.FFilterYear;
   Params['FilterText'] := Self.FFilterText;
   Params['FilterSubjAreaID'] := Self.FFilterSubID;
   DM.RESTLoadDocs(Params,@AfterDocsLoaded);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDoclistForm.UpdateDocList','','','Suche fehlgeschlagen'));
   Abort;
  End;
 Finally
  Console.log(TrOut('TDoclistForm.UpdateDocList'));
 End;
End;

{---------------------------------------}

Procedure TDoclistForm.CbxSalesAreasChange(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TDoclistForm.CbxSalesAreasChange'));
 Try
  Try
   UpdateDocList;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TDoclistForm.CbxSalesAreasChange'));
  End;
 Finally
  Console.log(TrOut('TDoclistForm.CbxSalesAreasChange'));
 End;
End;

{---------------------------------------}

procedure TDoclistForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  LabNoFilesFound := TLabel.Create('LabNoFilesFound');
  BtnYear1 := TButton.Create('BtnButtonYear1');
  BtnYear2 := TButton.Create('BtnButtonYear2');
  BtnYear3 := TButton.Create('BtnButtonYear3');
  BtnYear4 := TButton.Create('BtnButtonYear4');
  CbxSalesAreas := TComboBox.Create('CbxSalesAreas');
  EdSearch := TEdit.Create('EdSearch');
  BtnSearchDocs := TButton.Create('BtnSearchDocs');
  BtnUploadFile := TButton.Create('BtnUploadFile');
  BtnMenu := TButton.Create('BtnMenu');
  BtnCamera := TButton.Create('BtnCamera');

  LabNoFilesFound.BeforeLoadDFMValues;
  BtnYear1.BeforeLoadDFMValues;
  BtnYear2.BeforeLoadDFMValues;
  BtnYear3.BeforeLoadDFMValues;
  BtnYear4.BeforeLoadDFMValues;
  CbxSalesAreas.BeforeLoadDFMValues;
  EdSearch.BeforeLoadDFMValues;
  BtnSearchDocs.BeforeLoadDFMValues;
  BtnUploadFile.BeforeLoadDFMValues;
  BtnMenu.BeforeLoadDFMValues;
  BtnCamera.BeforeLoadDFMValues;
  try
    Name := 'DoclistForm';
    Width := 640;
    Height := 480;
    ElementClassName := 'Foo';
    Visible := True;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    LabNoFilesFound.SetParentComponent(Self);
    LabNoFilesFound.Name := 'LabNoFilesFound';
    LabNoFilesFound.Left := 35;
    LabNoFilesFound.Top := 215;
    LabNoFilesFound.Width := 132;
    LabNoFilesFound.Height := 13;
    LabNoFilesFound.Caption := 'Keine Dokumente gefunden';
    LabNoFilesFound.ElementPosition := epIgnore;
    LabNoFilesFound.HeightPercent := 100.000000000000000000;
    LabNoFilesFound.Visible := False;
    LabNoFilesFound.WidthPercent := 100.000000000000000000;
    BtnYear1.SetParentComponent(Self);
    BtnYear1.Name := 'BtnYear1';
    BtnYear1.Left := 35;
    BtnYear1.Top := 65;
    BtnYear1.Width := 96;
    BtnYear1.Height := 25;
    BtnYear1.Caption := '2018';
    BtnYear1.HeightPercent := 100.000000000000000000;
    BtnYear1.WidthPercent := 100.000000000000000000;
    SetEvent(BtnYear1, Self, 'OnClick', 'BtnYearClick');
    BtnYear2.SetParentComponent(Self);
    BtnYear2.Name := 'BtnYear2';
    BtnYear2.Left := 145;
    BtnYear2.Top := 65;
    BtnYear2.Width := 96;
    BtnYear2.Height := 25;
    BtnYear2.Caption := '2019';
    BtnYear2.ChildOrder := 1;
    BtnYear2.HeightPercent := 100.000000000000000000;
    BtnYear2.WidthPercent := 100.000000000000000000;
    SetEvent(BtnYear2, Self, 'OnClick', 'BtnYearClick');
    BtnYear3.SetParentComponent(Self);
    BtnYear3.Name := 'BtnYear3';
    BtnYear3.Left := 253;
    BtnYear3.Top := 65;
    BtnYear3.Width := 96;
    BtnYear3.Height := 25;
    BtnYear3.Caption := '2020';
    BtnYear3.ChildOrder := 2;
    BtnYear3.HeightPercent := 100.000000000000000000;
    BtnYear3.WidthPercent := 100.000000000000000000;
    SetEvent(BtnYear3, Self, 'OnClick', 'BtnYearClick');
    BtnYear4.SetParentComponent(Self);
    BtnYear4.Name := 'BtnYear4';
    BtnYear4.Left := 365;
    BtnYear4.Top := 65;
    BtnYear4.Width := 96;
    BtnYear4.Height := 25;
    BtnYear4.Caption := '2021';
    BtnYear4.ChildOrder := 3;
    BtnYear4.HeightPercent := 100.000000000000000000;
    BtnYear4.WidthPercent := 100.000000000000000000;
    SetEvent(BtnYear4, Self, 'OnClick', 'BtnYearClick');
    CbxSalesAreas.SetParentComponent(Self);
    CbxSalesAreas.Name := 'CbxSalesAreas';
    CbxSalesAreas.Left := 35;
    CbxSalesAreas.Top := 115;
    CbxSalesAreas.Width := 426;
    CbxSalesAreas.Height := 21;
    CbxSalesAreas.ElementPosition := epIgnore;
    CbxSalesAreas.HeightStyle := ssAuto;
    CbxSalesAreas.HeightPercent := 100.000000000000000000;
    CbxSalesAreas.WidthStyle := ssPercent;
    CbxSalesAreas.WidthPercent := 50.000000000000000000;
    SetEvent(CbxSalesAreas, Self, 'OnChange', 'CbxSalesAreasChange');
    CbxSalesAreas.ItemIndex := -1;
    EdSearch.SetParentComponent(Self);
    EdSearch.Name := 'EdSearch';
    EdSearch.Left := 35;
    EdSearch.Top := 165;
    EdSearch.Width := 314;
    EdSearch.Height := 22;
    EdSearch.ChildOrder := 5;
    EdSearch.ElementFont := efCSS;
    EdSearch.ElementPosition := epIgnore;
    EdSearch.HeightPercent := 100.000000000000000000;
    EdSearch.WidthPercent := 100.000000000000000000;
    BtnSearchDocs.SetParentComponent(Self);
    BtnSearchDocs.Name := 'BtnSearchDocs';
    BtnSearchDocs.Left := 365;
    BtnSearchDocs.Top := 163;
    BtnSearchDocs.Width := 96;
    BtnSearchDocs.Height := 25;
    BtnSearchDocs.Caption := 'BtnSearchDocs';
    BtnSearchDocs.ChildOrder := 6;
    BtnSearchDocs.ElementFont := efCSS;
    BtnSearchDocs.ElementPosition := epIgnore;
    BtnSearchDocs.HeightPercent := 100.000000000000000000;
    BtnSearchDocs.WidthPercent := 100.000000000000000000;
    SetEvent(BtnSearchDocs, Self, 'OnClick', 'BtnSearchDocsClick');
    BtnUploadFile.SetParentComponent(Self);
    BtnUploadFile.Name := 'BtnUploadFile';
    BtnUploadFile.Left := 355;
    BtnUploadFile.Top := 15;
    BtnUploadFile.Width := 50;
    BtnUploadFile.Height := 25;
    BtnUploadFile.Caption := 'BtnUploadFile';
    BtnUploadFile.ChildOrder := 6;
    BtnUploadFile.ElementFont := efCSS;
    BtnUploadFile.ElementPosition := epIgnore;
    BtnUploadFile.HeightPercent := 100.000000000000000000;
    BtnUploadFile.WidthPercent := 100.000000000000000000;
    SetEvent(BtnUploadFile, Self, 'OnClick', 'BtnUploadFileClick');
    BtnMenu.SetParentComponent(Self);
    BtnMenu.Name := 'BtnMenu';
    BtnMenu.Left := 411;
    BtnMenu.Top := 15;
    BtnMenu.Width := 50;
    BtnMenu.Height := 25;
    BtnMenu.Caption := 'BtnMenu';
    BtnMenu.ChildOrder := 6;
    BtnMenu.ElementFont := efCSS;
    BtnMenu.ElementPosition := epIgnore;
    BtnMenu.HeightPercent := 100.000000000000000000;
    BtnMenu.WidthPercent := 100.000000000000000000;
    SetEvent(BtnMenu, Self, 'OnClick', 'BtnMenuClick');
    BtnCamera.SetParentComponent(Self);
    BtnCamera.Name := 'BtnCamera';
    BtnCamera.Left := 299;
    BtnCamera.Top := 15;
    BtnCamera.Width := 50;
    BtnCamera.Height := 25;
    BtnCamera.Caption := 'BtnCamera';
    BtnCamera.ChildOrder := 6;
    BtnCamera.ElementFont := efCSS;
    BtnCamera.ElementPosition := epIgnore;
    BtnCamera.Enabled := False;
    BtnCamera.HeightPercent := 100.000000000000000000;
    BtnCamera.Visible := False;
    BtnCamera.WidthPercent := 100.000000000000000000;
    SetEvent(BtnCamera, Self, 'OnClick', 'BtnCameraClick');
  finally
    LabNoFilesFound.AfterLoadDFMValues;
    BtnYear1.AfterLoadDFMValues;
    BtnYear2.AfterLoadDFMValues;
    BtnYear3.AfterLoadDFMValues;
    BtnYear4.AfterLoadDFMValues;
    CbxSalesAreas.AfterLoadDFMValues;
    EdSearch.AfterLoadDFMValues;
    BtnSearchDocs.AfterLoadDFMValues;
    BtnUploadFile.AfterLoadDFMValues;
    BtnMenu.AfterLoadDFMValues;
    BtnCamera.AfterLoadDFMValues;
  end;
end;

End.
