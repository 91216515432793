Unit FormMain;

{ How to sync with VillaBeethoven server:
   - Open Visual Studio Code workspace
       C:\Work\Proj\MiesenUndPartner\Deubner\Deubner.code-workspace

   - To sync Delphi client stuff, right click on subfolder
       ...PWA\TMSWeb\VillaB
     and click "Sync local -> remote"

   - To sync REST PHP server stuff, right click on subfolder
       ...WWYPHP
     and click "Sync local -> remote"
     CAUTION: Files like .htaccess and LocalConfig.php will not be synced!

   - To sync PHP Libs, right click on subfolder
       ...LibPHP
     and click "Sync local -> remote"

   - To edit sync settings, edit file
       ...vscode\sftp.json
}

{
  List of available icons from FontAwsome
  https://fontawesome.com/v4.7.0/icons/
}

Interface

Uses
  JSDelphiSystem, SysUtils, Classes, Web, WEBLib.ExtCtrls,
  WEBLib.Imaging.GIFImg, WEBLib.Controls, WEBLib.Dialogs,
  WEBLib.Forms, Tools, WEBLib.Utils, WEBLib.Graphics, DMMain, WEBLib.ComCtrls,
  WEBLib.StdCtrls;

Const
 CompanyName        = 'Miesen&Partner';
 AppName            = 'Steuercheckliste';
 AppCaption         = {CompanyName+' | '+}AppName+' '+CurrentProgramVersion;
 DefaultIcon        = 'res/logo_mobile.png';
 MaxIconHeight      = 73;
 SuperUserID        = 441;
 MaxUploadFileSize  = 10*1024*1024;

Type
 TAppErrorHandlerDummyClass = Class
  Procedure OnAppError(Sender: TObject; AError: TApplicationError; var Handled: boolean);
 End;

Const
 DefaultThrobberTimerInterval = 1500;

Type
  TMainForm = class(TForm)
    Throbber: TWaitMessage;
    TmrThrobber: TTimer;
    BtnImprint: TButton;
    BtnTerms: TButton;
    BtnDataProt: TButton;
    ImgClientLogo: TImageControl;
    procedure WebFormShow(Sender: TObject);
    procedure TmrThrobberTimer(Sender: TObject);
    procedure BtnImprintClick(Sender: TObject);
    procedure BtnTermsClick(Sender: TObject);
    procedure BtnDataProtClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure ThrobberShow(Sender: TObject);
   Private
    FThrobberData : JSValue;
    FThrobberThenProc : TThenProc;
    Procedure Start(JV : JSValue = Nil);
    Procedure AppInit;
    Procedure AppShutdown;
    procedure CreateSubform(FormNo: Integer; Visible : Boolean; AfterAllFormsCreated : TThenProc);
    procedure AfterAllFormsCreated(JV: JSValue = Nil);
    procedure LoadSalesAreaDropdown(JV: JSValue);
    procedure SetCustomerLogo;
    procedure Start2(JV: JSValue);
   Public
    Procedure ResetApp;
    Procedure GetLoginCredentials(AndThen : TThenProc);
    Procedure ShowThrobber(DelayMillis : Integer;
                               AndThen : TThenProc = Nil;
                                  Data : JSValue = Nil);
    Procedure HideThrobber;
  protected procedure LoadDFMValues; override; End;

Procedure SwitchToForm(NextForm : TForm);

Var
 MainForm      : TMainForm;
 ActiveForm    : TForm = Nil;
 AppErrorDummy : TAppErrorHandlerDummyClass;
 FormList      : Array of TForm;
 DBLoggingOn   : Boolean = False;
 IsSuperUser   : Boolean = False;

{#############################################################################}

Implementation

{$R *.dfm}

Uses
 DateUtils, JS, JSFuncs, WEBLib.JSON, WEBLib.WebTools, JSON, FormLogin,
 FormDoclist, FormImprint, FormUpload, FormTerms, FormDataProt, FormShowPDF,
 FormCamera, FormMenu, Logger, LocalStorage;

Procedure SwitchToForm(NextForm : TForm);
Begin
 If ActiveForm=NextForm then exit;

 If assigned(ActiveForm) then ActiveForm.Visible := False;
 ActiveForm := NextForm;

 If NextForm=LoginForm then MainForm.ImgClientLogo.URL := DefaultIcon
 Else if LDat.MandantHasIcon then MainForm.SetCustomerLogo;

 ActiveForm.Visible := True;
End;

{---------------------------------------}

procedure TMainForm.WebFormCreate(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TMainForm.WebFormCreate'));
 Try
  Try
   TmrThrobber.Interval := DefaultThrobberTimerInterval;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMainForm.WebFormCreate'));
  End;
 Finally
  Console.log(TrOut('TMainForm.WebFormCreate'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.WebFormShow(Sender: TObject);
Var e : Exception;
Begin
 Console.log(TrIn('TMainForm.WebFormShow'));
 Try
  Try
   DM.ContinueWith(@Self.Start);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMainForm.WebFormShow'));
  End;
 Finally
  Console.log(TrOut('TMainForm.WebFormShow'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.SetCustomerLogo;
Var
 e   : Exception;
 Src : String;
Begin
 Console.log(TrIn('TMainForm.SetCustomerLogo'));
 Try
  Try
   Src := REST_API_CLIENTICONS_OUTPUT_PATH + LDat.IconFileName;
   If MainForm.ImgClientLogo.URL <> Src then
     MainForm.ImgClientLogo.URL := Src;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMainForm.SetCustomerLogo'));
  End;
 Finally
  Console.log(TrOut('TMainForm.SetCustomerLogo'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.GetLoginCredentials(AndThen : TThenProc);
Begin
 Console.log(TrIn('TMainForm.GetLoginCredentials'));
 Try
  SwitchToForm(LoginForm);
  LoginForm.OnAfterLogin := AndThen;
 Finally
  Console.log(TrOut('TMainForm.GetLoginCredentials'));
 End;
End;


{---------------------------------------}

Procedure TMainForm.TmrThrobberTimer(Sender: TObject);
Begin
 Console.log(TrIn('TMainForm.TmrThrobberTimer'));
 Try
  TmrThrobber.Enabled := False;
  Throbber.Show;
 Finally
  Console.log(TrOut('TMainForm.TmrThrobberTimer'));
 End;
End;

{---------------------------------------}

{ TAppErrorHandlerDummyClass }

Procedure TAppErrorHandlerDummyClass.OnAppError(Sender: TObject;
  AError: TApplicationError; var Handled: boolean);
Var E : Exception;
Begin
 Handled := True;
 E := TApplicationErrorToException(AError);
 If not (E is EAbort) then
  Console.error(Err(E,'OnAppError'));
End;

{---------------------------------------}

Procedure TMainForm.AppInit;
Var e : Exception;

  Procedure OnErrorDlgClick(AValue: TDialogResult);
  Begin
   Console.log(TrIn('TMainForm.AppInit.OnErrorDlgClick'));
   Try
    ResetApp;
   Finally
    Console.log(TrOut('TMainForm.AppInit.OnErrorDlgClick'));
   End;
  End;

Begin
 Console.log(TrIn('TMainForm.AppInit'));
 Try
  Try
   SetLength(FormList,0);
   DM.Init;
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMainForm.AppInit','','',
    'Initialisierungsfehler. Bitte versuchen Sie es später noch einmal!',
    [],@OnErrorDlgClick));
   Try AppShutdown except end;
   Abort;
  End;
 Finally
  Console.log(TrOut('TMainForm.AppInit'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.AppShutdown;
Var e : Exception;
Begin
 Console.log(TrIn('TMainForm.AppShutdown'));
 Try
  Try
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMainForm.AppShutdown'));
  End;
 Finally
  Console.log(TrOut('TMainForm.AppShutdown'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.ResetApp;
Var
 e : Exception;
 I : Integer;
Begin
 Console.log(TrIn('TMainForm.ResetApp'));
 Try
  Try
   HideThrobber;
   LDat.Clear;
   For I := Length(FormList)-1 downto 0 do
    FormList[I].Free;
   SetLength(FormList,0);
   ActiveForm := Nil;
   CreateSubform(1,False,@AfterAllFormsCreated);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMainForm.ResetApp'));
  End;
 Finally
  Console.log(TrOut('TMainForm.ResetApp'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.Start(JV : JSValue = Nil);
Var e : Exception;
Begin
 Console.log(TrIn('TMainForm.Start'));
 Try
  Try
   LoadLocalData(@Self.Start2);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMainForm.Start'));
  End;
 Finally
  Console.log(TrOut('TMainForm.Start'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.Start2(JV : JSValue = Nil);
Var e : Exception;
Begin
 Console.log(TrIn('TMainForm.Start2'));
 Try
  Try
   AppInit;
   // This recursively creates all subforms
   CreateSubform(1,False,@AfterAllFormsCreated);
  Except
   {$IFNDEF WIN32} ASM e = $e; END; {$ENDIF}
   Console.error(Err(e,'TMainForm.Start2'));
  End;
 Finally
  Console.log(TrOut('TMainForm.Start2'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.CreateSubform(FormNo : Integer; Visible : Boolean; AfterAllFormsCreated : TThenProc);

  Procedure OnFormCreated(AForm: TObject);
  Var NewForm : TForm;
  Begin
   NewForm := AForm as TForm;
   Console.Log(Lg('TMainForm.CreateSubform.OnFormCreated',['Form created: ',NewForm.Name]));
   NewForm.Visible := Visible;
   If Visible then ActiveForm := NewForm;
   CreateSubform(FormNo+1,False,AfterAllFormsCreated);
  End;

  Procedure DoFormInitializations;
  Begin
   // This gets called after all forms were successfully created

  End;

Begin
 // Caution: Recursive
 Case FormNo of
  1 : DoclistForm := TDoclistForm.CreateNew('ContentForm1',@OnFormCreated);
  2 : LoginForm := TLoginForm.CreateNew('ContentForm2',@OnFormCreated);
  3 : ImprintForm := TImprintForm.CreateNew('ContentForm3',@OnFormCreated);
  4 : UploadForm := TUploadForm.CreateNew('ContentForm4',@OnFormCreated);
  5 : TermsForm := TTermsForm.CreateNew('ContentForm5',@OnFormCreated);
  6 : DataProtForm := TDataProtForm.CreateNew('ContentForm6',@OnFormCreated);
  7 : ShowPDFForm := TShowPDFForm.CreateNew('ContentForm7',@OnFormCreated);
  8 : CameraForm := TCameraForm.CreateNew('ContentForm8',@OnFormCreated);
  9 : MenuForm := TMenuForm.CreateNew('ContentForm9',@OnFormCreated);
  10: DM.ContinueWith(AfterAllFormsCreated);
  Else DoFormInitializations;
 End;
End;

{---------------------------------------}

Procedure TMainForm.AfterAllFormsCreated(JV : JSValue = Nil);
Begin
 Console.log(TrIn('TMainForm.AfterAllFormsCreated'));
 Try
  If LDat.PropExists('SubjAreasList') then LoadSalesAreaDropdown(Nil)
  Else DM.RESTLoadSubjectAreas(@LoadSalesAreaDropdown);
 Finally
  Console.log(TrOut('TMainForm.AfterAllFormsCreated'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.LoadSalesAreaDropdown(JV : JSValue = Nil);
Var
 SA    : TJSObject;
 I,ID  : Integer;
 SATxt : String;
 OID   : TObject;
Begin
 Console.log(TrIn('TMainForm.LoadSalesAreaDropdown'));
 Try
  DoclistForm.CbxSalesAreas.BeginUpdate;
  UploadForm .CbxSalesAreas.BeginUpdate;
  CameraForm .CbxSalesAreas.BeginUpdate;
  Try
   DoclistForm.CbxSalesAreas.Clear;
   UploadForm.CbxSalesAreas.Clear;
   CameraForm.CbxSalesAreas.Clear;
   DoclistForm.CbxSalesAreas.AddItem('-- Alle Sachgebiete --',nil);
   UploadForm.CbxSalesAreas.AddItem('-- Alle Sachgebiete --',nil);
   CameraForm.CbxSalesAreas.AddItem('-- Alle Sachgebiete --',nil);
   For I := 0 to LDat.SubjAreasList.Length- 1 do
    Begin
     SA := TJSObject(LDat.SubjAreasList[I]);
     SATxt := String(SA['SGBez']);
     ID := Integer(SA['ID']);
     {$IFNDEF WIN32}
     ASM
      OID = ID;
     END;
     {$ENDIF}
     If ID=0 then; // Keep compiler happy
     DoclistForm.CbxSalesAreas.AddItem(SATxt,OID);
     UploadForm.CbxSalesAreas.AddItem(SATxt,OID);
     CameraForm.CbxSalesAreas.AddItem(SATxt,OID);
    End;
  Finally
   DoclistForm.CbxSalesAreas.EndUpdate;
   UploadForm .CbxSalesAreas.EndUpdate;
   CameraForm .CbxSalesAreas.EndUpdate;
  End;

  UploadForm.FFilterYear  := DoclistForm.FFilterYear;
  UploadForm.FFilterSubID := DoclistForm.FFilterSubID;
  UploadForm.FormInit;

  CameraForm.FFilterYear  := DoclistForm.FFilterYear;
  CameraForm.FFilterSubID := DoclistForm.FFilterSubID;
  CameraForm.FormInit;

  SwitchToForm(DoclistForm);
  DoclistForm.FormInit;

 Finally
  Console.log(TrOut('TMainForm.LoadSalesAreaDropdown'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.BtnImprintClick(Sender: TObject);
Begin
 Console.log(TrIn('TMainForm.BtnImprintClick'));
 Try
  ImprintForm.PreviousForm := ActiveForm;
  SwitchToForm(ImprintForm);
 Finally
  Console.log(TrOut('TMainForm.BtnImprintClick'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.BtnTermsClick(Sender: TObject);
Begin
 Console.log(TrIn('TMainForm.BtnTermsClick'));
 Try
  TermsForm.PreviousForm := ActiveForm;
  SwitchToForm(TermsForm);
 Finally
  Console.log(TrOut('TMainForm.BtnTermsClick'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.BtnDataProtClick(Sender: TObject);
Begin
 Console.log(TrIn('TMainForm.BtnDataProtClick'));
 Try
  DataProtForm.PreviousForm := ActiveForm;
  SwitchToForm(DataProtForm);
 Finally
  Console.log(TrOut('TMainForm.BtnDataProtClick'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.ShowThrobber(DelayMillis : Integer;
                                     AndThen : TThenProc = Nil;
                                        Data : JSValue = Nil);
Begin
 Console.log(TrIn('TMainForm.ShowThrobber',['DelayMillis: ',DelayMillis]));
 Try
  FThrobberData := Data;
  FThrobberThenProc := AndThen;
  Throbber.OnShow := Self.ThrobberShow;
  If DelayMillis>0 then
   Begin
    TmrThrobber.Interval := DelayMillis;
    TmrThrobber.Enabled := True;
   End
  Else
   Throbber.Show;
 Finally
  Console.log(TrOut('TMainForm.ShowThrobber'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.ThrobberShow(Sender: TObject);
Begin
 Console.log(TrIn('TMainForm.ThrobberShow'));
 Try
  TmrThrobber.Enabled := False;
  If assigned(FThrobberThenProc) then
   FThrobberThenProc(FThrobberData);
 Finally
  Console.log(TrOut('TMainForm.ThrobberShow'));
 End;
End;

{---------------------------------------}

Procedure TMainForm.HideThrobber;
Begin
 Console.log(TrIn('TMainForm.HideThrobber'));
 Try
  TmrThrobber.Enabled := False;
  If Throbber.Visible then Throbber.Hide;
  FThrobberThenProc := Nil;
  FThrobberData := Nil;
  TmrThrobber.Interval := DefaultThrobberTimerInterval;
 Finally
  Console.log(TrOut('TMainForm.HideThrobber'));
 End;
End;

{---------------------------------------}

procedure TMainForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ImgClientLogo := TImageControl.Create('LogoMobileApp');
  Throbber := TWaitMessage.Create(Self);
  BtnImprint := TButton.Create('BtnImprint');
  BtnTerms := TButton.Create('BtnTerms');
  BtnDataProt := TButton.Create('BtnDataProt');
  TmrThrobber := TTimer.Create(Self);

  ImgClientLogo.BeforeLoadDFMValues;
  Throbber.BeforeLoadDFMValues;
  BtnImprint.BeforeLoadDFMValues;
  BtnTerms.BeforeLoadDFMValues;
  BtnDataProt.BeforeLoadDFMValues;
  TmrThrobber.BeforeLoadDFMValues;
  try
    Name := 'MainForm';
    Width := 456;
    Height := 203;
    Caption := 'Steuercheckliste';
    ElementClassName := 'Foo';
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    ImgClientLogo.SetParentComponent(Self);
    ImgClientLogo.Name := 'ImgClientLogo';
    ImgClientLogo.Left := 145;
    ImgClientLogo.Top := 21;
    ImgClientLogo.Width := 156;
    ImgClientLogo.Height := 50;
    ImgClientLogo.HeightStyle := ssAuto;
    ImgClientLogo.WidthStyle := ssAuto;
    ImgClientLogo.HeightPercent := 100.000000000000000000;
    ImgClientLogo.WidthPercent := 100.000000000000000000;
    ImgClientLogo.ChildOrder := 4;
    ImgClientLogo.ElementFont := efCSS;
    ImgClientLogo.ElementPosition := epIgnore;
    ImgClientLogo.URL := 'res/logo_mobile.png';
    Throbber.SetParentComponent(Self);
    Throbber.Name := 'Throbber';
    Throbber.Left := 97;
    Throbber.Top := 163;
    Throbber.Width := 24;
    Throbber.Height := 24;
    Throbber.HeightPercent := 100.000000000000000000;
    Throbber.WidthPercent := 100.000000000000000000;
    Throbber.Opacity := 0.200000000000000000;
    Throbber.Picture.LoadFromFile('FormMain.Throbber.Picture.gif');
    BtnImprint.SetParentComponent(Self);
    BtnImprint.Name := 'BtnImprint';
    BtnImprint.Left := 25;
    BtnImprint.Top := 95;
    BtnImprint.Width := 96;
    BtnImprint.Height := 25;
    BtnImprint.ChildOrder := 1;
    BtnImprint.ElementFont := efCSS;
    BtnImprint.ElementPosition := epIgnore;
    BtnImprint.HeightStyle := ssAuto;
    BtnImprint.HeightPercent := 100.000000000000000000;
    BtnImprint.WidthStyle := ssAuto;
    BtnImprint.WidthPercent := 100.000000000000000000;
    SetEvent(BtnImprint, Self, 'OnClick', 'BtnImprintClick');
    BtnTerms.SetParentComponent(Self);
    BtnTerms.Name := 'BtnTerms';
    BtnTerms.Left := 127;
    BtnTerms.Top := 95;
    BtnTerms.Width := 96;
    BtnTerms.Height := 25;
    BtnTerms.ChildOrder := 1;
    BtnTerms.ElementFont := efCSS;
    BtnTerms.ElementPosition := epIgnore;
    BtnTerms.HeightStyle := ssAuto;
    BtnTerms.HeightPercent := 100.000000000000000000;
    BtnTerms.WidthStyle := ssAuto;
    BtnTerms.WidthPercent := 100.000000000000000000;
    SetEvent(BtnTerms, Self, 'OnClick', 'BtnTermsClick');
    BtnDataProt.SetParentComponent(Self);
    BtnDataProt.Name := 'BtnDataProt';
    BtnDataProt.Left := 229;
    BtnDataProt.Top := 95;
    BtnDataProt.Width := 96;
    BtnDataProt.Height := 25;
    BtnDataProt.ChildOrder := 1;
    BtnDataProt.ElementFont := efCSS;
    BtnDataProt.ElementPosition := epIgnore;
    BtnDataProt.HeightStyle := ssAuto;
    BtnDataProt.HeightPercent := 100.000000000000000000;
    BtnDataProt.WidthStyle := ssAuto;
    BtnDataProt.WidthPercent := 100.000000000000000000;
    SetEvent(BtnDataProt, Self, 'OnClick', 'BtnDataProtClick');
    TmrThrobber.SetParentComponent(Self);
    TmrThrobber.Name := 'TmrThrobber';
    TmrThrobber.Enabled := False;
    TmrThrobber.Interval := 1500;
    SetEvent(TmrThrobber, Self, 'OnTimer', 'TmrThrobberTimer');
    TmrThrobber.Left := 35;
    TmrThrobber.Top := 158;
  finally
    ImgClientLogo.AfterLoadDFMValues;
    Throbber.AfterLoadDFMValues;
    BtnImprint.AfterLoadDFMValues;
    BtnTerms.AfterLoadDFMValues;
    BtnDataProt.AfterLoadDFMValues;
    TmrThrobber.AfterLoadDFMValues;
  end;
end;

Initialization
 AppErrorDummy := TAppErrorHandlerDummyClass.Create;
 DefaultErrorMessageDialogCaption := AppCaption;

End.
